import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import SafexTrademark from "../images/20. Trademark/Safex-Trademark.webp";
import SafexLogomark from "../images/20. Trademark/Safex-Logomark.webp";
import Arrow from "../images/arrow.svg";


function SafexTrademarkPolicy() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Safex Trademark Policy | safex.org</title>
        <meta name="description" content="The SAFEX™ brand represents and stands for an inclusive, world-leading, decentralized marketplace uniting a community of merchants and patrons." />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-trademarkpolicy"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-text">
                <h1>Trademark Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-0">Welcome</h4>
              <p>The SAFEX™ brand represents and stands for an inclusive, world-leading, decentralized marketplace uniting a community of merchants and patrons. We believe in the power of the SAFEX™ brand to transform the world’s understanding of the virtual marketplace. Therefore, it is important for Safex to protect the SAFEX™ trademark and other intellectual property owned by and licensed to our company. This document, the "Policy," identifies the trademarks and brand names owned by Safex Foundation, Inc. and/or its affiliates (collectively, “Safex", also “we,” “our”) and the rules and guidelines relating to the use of certain Safex trademarks in connection with the SAFEX™ Marketplace. This Policy is intended to help you use our trademarks in an authorized manner with integrity and consistency, and to help us to create a clear, unified, global brand.</p>
              
              <h4 className="mb-0">SAFEX Trademarks</h4>
              <p>A trademark is a type of intellectual property consisting of a word, phrase, symbol, or design (or a combination of those things) that identifies and distinguishes the source of the goods or services of one party from those of others. For example, SAFEX™ is the trademark owned by Safex Foundation used to identify and distinguish the SAFEX™ blockchain, marketplace, e-commerce engine, and token from those types of goods and services provided by other third parties.</p>
              <p>Our trademarks are valuable intellectual property assets, and Safex takes all appropriate measures to protect and preserve the strength and goodwill of its trademarks. Safex retains all rights and ownership in its trademarks.</p>
              <p>The Safex trademarks include those listed below (the “Safex Trademarks” or “Trademarks”). This list is not exhaustive and, from time to time, may be updated by Safex. Safex owns the Trademarks and, has the exclusive right to use and control the use of the Trademarks. Therefore, the Trademarks are reserved for Safex’s exclusive use.</p>
              <div className="trademark-images">
                <img src={SafexTrademark} className="img-fluid" alt=""/>
                <img src={SafexLogomark} className="img-fluid" alt=""/>
              </div>
              <p>Safex expressly prohibits any use, registration, or attempted registration of any Trademarks owned and/or used by Safex at any time. Safex may oppose, cancel, or otherwise challenge or object to any party’s efforts to apply for or register any of these Trademarks, or any resulting registrations for these Trademarks.</p>
              
              <p className="mb-0">In addition,</p>
              <ul>
                <li>You may not use the Trademarks as part of any business name, trade name, company name, or fictitious business name/dba.</li>
                <li>You may not use or register, in whole or in part, the Trademarks as part of your own trademark, product name, or service name.</li>
                <li>You may not use the Trademarks as part of any domain name, including but not limited to any sub- or second-level domain.</li>
                <li>Trademark law does not allow your use of names or trademarks that are similar to ours. You therefore may not use an obvious variation of any of the
                  Trademarks or any phonetic equivalent, foreign language equivalent, or abbreviation for a similar or compatible product or service.</li>
              </ul>


              <p>If you learn of any use or misuse of the Safex Trademarks that might cause confusion in the market or that is in violation of this Policy, please <Link to="/contact-us">contact us</Link> and please provide more information regarding the use or misuse of the Trademark(s).</p>
              <p>[[As set out in this Policy, however, Safex has established specific trademarks as “Marketplace Marks,” to which Safex grants a limited, non-exclusive, revocable, non-transferrable, non-sublicensable, royalty-free license to the SAFEX™ Marketplace community. If you are a member of the SAFEX™ Marketplace community (the “Community”), please see the Marketplace Marks section of this Policy below for more information regarding limited, permissible uses of any trademarks designated by Safex as Marketplace Marks.</p>

              

              <h4 className="mb-0">Marketplace Marks</h4>
              <p>Marketplace Marks are specific designations or trademarks in which Safex grants a limited, non-exclusive, revocable, non-transferrable, non-sublicensable, royalty-free license to the SAFEX™ Marketplace Community to use the Marketplace Marks solely in the limited scope described in this section and as set out in the in Guidelines and Permitted Uses below (the “License”). Safex does not grant any rights in any other intellectual property owned by Safex or owned by third parties.</p>


              <p className="mb-0">Safex reserves the right, at any time in its sole discretion, to change or terminate the Marketplace Marks License as to any or all Marketplace Marks. Any such change, update, or termination of this Policy or License will take effect immediately after its publication or our notification to you. Your continued use of the Marketplace Marks after the publication of the updated Policy and/or License or our notification to you constitutes your acceptance of the updated Policy and/or License. You must cease using the Marketplace Marks immediately if the Policy or License is terminated, or if you do not agree to the updated Policy and/or License. Please check this Policy and License regularly.</p>
              <p>The Marketplace Marks include only those listed below. Safex reserves the right to modify the designated Marketpace Marks at any time in its sole discretion. Any Trademarks listed below are not Marketplace Marks, and are not licensed to the Community in any capacity. </p>



              <p>By your use of the Marketplace Marks in accordance with this Policy and License, you expressly acknowledge and agree that Safex is the exclusive owner of the Marketplace Marks and the Trademarks, that you do not and will not acquire any rights in the Marketplace Marks or the Trademarks, and that any goodwill generated by your use of the Marketplace Marks inures solely to the benefit of Safex. If you acquire any rights in the Marketplace Marks or Trademarks, by operation of law or otherwise, such rights and corresponding goodwill hereby are irrevocably assigned to Safex without further action by any party.</p>

              <p>AS PERMITTED BY LAW, SAFEX DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTY, INCLUDING ANY WARRANTY AGAINST NON-INFRINGEMENT OF THIRD-PARTY INTELLECTUAL PROPERTY RIGHTS, REGARDING YOUR USE OF THE MARKETPLACE MARKS.</p>
              
              <h4 className="mb-0">Limited Permitted Use of Marketplace Marks</h4>
              <p>Pursuant to the limited license rights granted herein, Safex considers the following to be licensed or permitted uses of the Marketplace Marks in the SAFEX™ Marketplace Community (the “Permitted Use”):</p>

              <ol className="ps-4">
                <li>To accurately state that you are a SAFEX™ Merchant or Marketplace member, including on your Marketplace homepage, on your website, online group, or blog.</li>
                <li>To create merchandise or “swag” that identifies you as a SAFEX™ Merchant or Marketplace member.</li>
                <li>Other Uses</li>
              </ol>


              <p>Any uses of the Marketplace Marks that do not comport with the Permitted Use outlined herein requires the prior written consent of Safex. <strong>If you are unsure if your intended use of a Marketplace Mark is permitted under this License, you should consult an attorney.</strong></p>
              
              <h4 className="mb-0">Quality Control: Guidelines for the Use of Marketplace Marks</h4>
              <p>Use of the Marketplace Marks must follow these guidelines (the “Guidelines”).</p>

              <ol className="ps-4">
                <li>Whenever possible, use of the Marketplace Marks should be accompanied by a notice that clearly indicates that the Marketplace Marks are owned by Safex.</li>
                <li>Always use the Marketplace Marks in their exact form with the correct spelling, and never abbreviated, hyphenated, or combined with any other word or words.</li>
                <li>Do not change any Marketplace Mark except to scale it to size. You may not add any new design elements, alter the proportions, or combine it with other logos or designs.</li>
                <li>Always set apart the Marketplace Marks from surrounding text with an initial capital letter or in all capital letters.</li>
                <li>Do not use the Marketplace Marks in any manner that suggests a common, descriptive, or generic meaning.</li>
                <li>Do not copy or imitate any trade dress used or owned by Safex. You may not copy or imitate the overall look and feel of any Safex website, social media, typography, or advertising or marketing materials.</li>
              </ol>

              
              <p>Notwithstanding anything in this Policy or License, Safex may terminate the License with immediate effect if (i) in Safex’ sole discretion, you are doing or failing to do anything that might cause irreparable injury to Safex, the Marketplace Marks, the Trademarks, or the goodwill or reputation of any; (ii) you challenge the validity or Safex’s exclusive ownership of the Marketplace Marks or the Trademarks; or (iii) for any other reason solely determined by Safex. Upon termination of the License, (i) all of the rights granted under this limited License shall terminate and immediately revert to Safex; and (ii) you shall immediately discontinue all use of the Marketplace Marks at your own expense.</p>



              <h4 className="mb-0">Prohibited Use of Marketplace Marks</h4>
              <p>Any use of any Marketplace Marks outside of the SAFEX™ Marketplace and the limited uses described in this Policy is prohibited and may subject you to trademark infringement, unfair competition, or other legal claims or remedies from Safex or third parties. Safex reserves all rights in relation to the use of the Marketplace Marks or Trademarks not granted herein. Your use of the Marketplace Marks does not imply an endorsement of your business, organization, application, or service by Safex, nor does it constitute a partnership or any direct or indirect business relationship between you and Safex.</p>

              <ol className="ps-4">
                <li>You must not use the Marketplace Marks in any manner unrelated to the SAFEX™ Marketplace.</li>
                <li>You must not use the Marketplace Marks in any manner that can be reasonably interpreted to suggest that you are an agent or affiliate of Safex, endorsed by Safex, or otherwise authorized to act on behalf of Safex.</li>
                <li>You must not use or display the Marketplace Marks in any manner that can be reasonably interpreted to suggest editorial content has been authored by or represents the views or opinions of Safex or Safex personnel.</li>
                <li>You must not file an application to register any trademark, business name, domain name, or other designation containing or comprised of, in whole or in part, the Marketplace Marks in any jurisdiction throughout the world.</li>
                <li>You must not file an application to register any trademark, business name, domain name, or other designation containing or comprised of, in whole or in part, the Marketplace Marks in any jurisdiction throughout the world.</li>
                <li>You must not use the Marketplace Marks in any manner that violates any law or regulation, or in false or misleading advertising or in any objectionable material. Such material may include, but is not limited to, any illegal, defamatory, scandalous, pornographic, or other material determined to be objectionable by Safex in its sole discretion.</li>
                <li>You must not use the Marketplace Marks to disparage Safex or any third parties. Without discouraging legitimate opinion or commentary, you must not use the Marketplace Marks in any manner that, in Safex’s judgement, may diminish or damage Safex’s reputation or the goodwill of the Marketplace Marks.]]</li>
              </ol>

              <h4 className="mb-0">Contact Information</h4>
              <p>If you have any questions about this Policy, would like to speak with Safex about use of the Marketplace Marks in a manner not described in this License, or if you see any abuse of our Trademarks, please contact:</p>


              <h4 className="mb-3">[INSERT PREFERRED CONTACT INFO.] </h4>
              <p>These guidelines are based in limited part on the Model Trademark Guidelines, available <a href="http://modeltrademarkguidelines.org/index.php/Home:_Model_Trademark_Guidelines" target="_blank" rel="noreferrer">here</a>, used under a Creative Commons Attribution 3.0 Unported <a href="https://creativecommons.org/licenses/by/3.0/deed.en_US" target="_blank" rel="noreferrer">licence</a>.</p>


              <Link to="/" className="btn-arrow mt-5">
                Go Home!
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SafexTrademarkPolicy;