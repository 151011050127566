import { Link } from "react-router-dom";
import '../App.css';

import Safex from "../images/Safex.svg";
import Github from "../images/github.svg";
import Forum from "../images/forum.svg";
import Twitter from "../images/twitter.svg";
import Youtube from "../images/youtube.svg";
import Discord from "../images/discord.svg";
import Reddit from "../images/reddit.svg";

import GithubMobile from "../images/github-2.svg";
import TwitterMobile from "../images/twitter-2.svg";
import YoutubeMobile from "../images/youtube-2.svg";
import ForumMobile from "../images/forum-2.svg";
import DiscordMobile from "../images/discord-2.svg";
import RedditMobile from "../images/reddit-2.svg";


function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="social-media">
          <a href="https://twitter.com/safex" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="" />
          </a>
          <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">
            <img src={Forum} alt="" height="27" />
          </a>
          <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer">
            <img src={Youtube} alt="" />
          </a>
          <a href="https://github.com/safex" target="_blank" rel="noreferrer">
            <img src={Github} alt="" />
          </a>
          <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer">
            <img src={Discord} alt="" />
          </a>
          <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer">
            <img src={Reddit} alt="" />
          </a>
        </div>
        <div className="social-media-mobile">
          <a to="https://twitter.com/safex" target="_blank" rel="noreferrer">
            <img src={TwitterMobile} alt="" />
          </a>
          <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">
            <img src={ForumMobile} alt="" />
          </a>
          <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer">
            <img src={YoutubeMobile} alt="" />
          </a>
          <a href="https://github.com/safex" target="_blank" rel="noreferrer">
            <img src={GithubMobile} alt="" />
          </a>
          <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer">
            <img src={DiscordMobile} alt="" />
          </a>
          <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer">
            <img src={RedditMobile} alt="" />
          </a>
        </div>
        <div className="footer-links-wrap">
          <div className="footer-links">
            <h1>Use Safex</h1>
            <Link to="/wallet">Safex TWM Wallet</Link>
            <Link to="/buy-safex-coins">Get Safex Coins</Link>
            <Link to="/buy-safex-cash">Buy Safex Cash</Link>
            <Link to="/buy-safex-token">Buy Safex Token</Link>
            <Link to="/shopping-on-safex-ecommerce-market">Shopping on Safex Market</Link>
            <Link to="/mining-safex-cash">Mine Safex Cash</Link>
            <Link to="/staking-safex-tokens">Stake Safex Tokens</Link>
            <Link to="/run-safex-node">Run Safex Node</Link>
          </div>
          <div className="footer-links">
            <h1>Learn</h1>
            <Link to="/what-is-safex">What is Safex?</Link>
            <Link to="/how-safex-works">How Safex Works?</Link>
            <Link to="/safex-privacy-blockchain">Privacy Features</Link>
            <Link to="/crypto-safety-tips">Crypto Safety Tips</Link>
            <Link to="/safex-knowledge-base">Safex Knowledge Base</Link>
          </div>
          <div className="footer-links">
            <h1>Ecosystem</h1>
            <Link to="/safex-ecosystem">Safex Ecosystem</Link>
            <Link to="/mission-and-vision">Mission &amp; Vision</Link>
            <Link to="/safex-foundation">Safex Foundation</Link>
            <Link to="/safex-community">Community Links</Link>
          </div>
          <div className="footer-links">
            <h1>Contribute</h1>
            <Link to="/coding-on-safex">Develop on Safex</Link>
            <Link to="/promote-safex">Promote Safex</Link>
            <Link to="/safex-assets">Safex Assets</Link>
            <Link to="/donation">Donate</Link>
          </div>
          <div className="footer-links">
            <h1>About</h1>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/cookie">Cookie Policy</Link>
            <Link to="/safex-trademark-policy">Trademark Policy</Link>
            <Link to="/contact-us">Contact</Link>
          </div>
        </div>
        <div className="footer-bottom">
          <img src={Safex} className="img-fluid" alt="" />
          <div className="footer-mobile-links">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/cookie">Cookie Policy</Link>
          </div>
          <p>© 2024 SAFEX | All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
