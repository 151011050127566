import '../App.css';
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Run-Safex-Node.png";
import RunSafexNodeImage from "../images/26. Run Safex Node/Run-Safex-Node.webp";
import Arrow from "../images/arrow.svg";


function RunSafexNode() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Run Safex Node and Support Safex | safex.org</title>
        <meta name="description" content="Run Safex Node and support the Safex Blockchain Network and contribute to its decentralization and maintain the highest level of security and privacy." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/run-safex-node"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Run Safex Node <span>And Support Safex Ecosystem</span></h1>
                <p className="mb-0">Operating your own safex node has many advantages:</p>
                <ul>
                  <li>You are supporting the Safex Blockchain Network and contributing to its decentralization,</li>
                  <li>It gives you the ability to independently verify transactions, send/receive SFT/SFX, and make purchases on Safex Market, all without relying on someone else’s node,</li>
                  <li>Allows you to maintain the highest level of security and privacy.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={RunSafexNodeImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray mb-0 links-to-existing-section">
          <div className="container">
              <div className="row">
                  <div className="col-md-9">
                    <h1 className="fw-500">Links to existing articles for running Safex Nodes:</h1>
                    <p className="mb-0">
                      1. Node [aka daemon] software (safexd) is released for Windows, Mac and Linux. Any modern PC can run it, and the regular PC user can easily get their node online quickly:
                      <ul className="mb-0">
                        <li><a href="https://safex.org/blog/setting-up-a-node-for-windows/" target="_blank" rel="noreferrer">Windows Node Setup</a></li>
                        <li><a href="https://safex.org/blog/setting-up-a-node-for-linux/" target="_blank" rel="noreferrer">Linux Node Setup</a></li>
                        <li>Link to Mac Node Setup - coming soon</li>
                      </ul>
                    </p>
                    <p className="mb-0">2. <a href="https://safex.ninja/docker" target="_blank" rel="noreferrer">Use Docker software to Run your Node</a> (Ideal for PC novices)</p>
                    <p className="mb-0">3. <a href="https://safex.org/blog/building-a-raspberrypi-safex-node-using-safexninja-docker/" target="_blank" rel="noreferrer">Building an RPi4 Node</a> (Great for tech hobbyists)</p>
                    <p className="mb-0">4. <a href="https://safexemporium.com/community-projects" target="_blank" rel="noreferrer">Safex “Blue Box” Node Community Project</a> (Perfect for the 3D-printing enthusiast)</p>
                  </div>
              </div>
          </div>
      </section>
      <section className="what-need-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h2 className="fw-normal">What do you need?</h2>
              <p className="mb-0">All you need for your node is a relatively modern Always On PC and a reliable internet connection. Running a Safex Node benefits not only you, as a Safex blockchain user, but also the network itself.</p>
    	        <p className="mb-0">However, you need to keep up-to-date with safex development - the node only works properly on the latest version, so you need to keep the software updated.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="what-is-node-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h2 className="fw-normal">What exactly is a node?</h2>
              <p>Simply explained, a node is a software program that processes blockchain command requests, authenticates new blockchain transactions, allows for the Proof-of-Work (PoW) creation of new blocks within the blockchain network, and then relays all that information to other Safex nodes around the world.</p>
    	        <p>The Safex node (aka daemon) software is the ‘distributed brain’ of the blockchain.  All functions of the blockchain are managed by the node:</p>
              <ol className="ps-4">
                <li>It communicates with its peers - other nodes it has a direct link to - to synchronize the blockchain.</li>
                <li>It accepts new transaction data from wallets and adds them to the transaction pool. It shares this new transaction information to all its peers.</li>
                <li>It relays new transaction pool data received from any other peer to all peers it has a link with, thus ensuring unprocessed transaction data dispurses throughout the entire network.</li>
                <li>Acts as a ‘delegate’ of the consensus mechanism when new blocks are found.</li>
                <li>If the node is receiving PoW mining hashes and successfully finds a winning hash, it creates the new block, processes transactions from the pool and writes the new block to the ledger.</li>
                <li>Although quite rare, there are times when the same new block is found by two different nodes at almost the same time. At such times, the nodes themselves determine, via the consensus mechanism of the nodes communicating with each other, which of the found blocks becomes the actual block written to the blockchain ledger. The losing block is known as an Orphaned Block.</li>
                <li>It is possible to mine directly from within the safexd node software (although most people use more efficient dedicated mining software).</li>
                <li>Certain blockchain data can be accessed through the use of command requests, which are sent to the node by users.</li>
              </ol>
              <a href="https://safex.org/blog/safex-nodes/" target="_blank" rel="noreferrer" className="btn-arrow mt-5">
                Find more information on our blog 
                <img src={Arrow} alt=""/>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on our <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Official Safex Forum.</a> <br/>Enjoy entering the Safex Ecosystem.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RunSafexNode;
