import '../App.css';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


import ogImg from "../images/thumbnails/How-Safex-Works.png";
import HowSafexTechnologyWorks from "../images/7. How Safex Works/How-Safex-Technology-Works.webp";
import HowSafexBlockchainWorks from "../images/7. How Safex Works/How-Safex-Blockchain-Works.webp";
import EverettRoger_sDiffusionofInnovationModeltechnologyadoptioncurve from "../images/7. How Safex Works/Everett-Roger_s-Diffusion-of-Innovation-Model-technology-adoption-curve.webp";
import MonetaryBaseCComparisonBitcoinLitecoinMoneroSafexCash from "../images/7. How Safex Works/Monetary-BaseC-Comparison-Bitcoin-Litecoin-Monero-Safex-Cash.webp";
import SFXSafexCashemissioncurveandmonetarybase from "../images/7. How Safex Works/SFX-Safex-Cash-emission-curve-and-monetary-base.webp";
import SFXSafexCashemissionquantity from "../images/7. How Safex Works/SFX-Safex-Cash-emission-quantity.webp";
import SafexCashPaymentMethod from "../images/7. How Safex Works/Safex-Cash-Payment-Method.webp";
import SafexTokenExplained from "../images/7. How Safex Works/Safex-Token-Explained.webp";
import SafexTWMWalletappExplained from "../images/7. How Safex Works/Safex-TWM-Wallet-app-Explained.webp";
import SafexeCommerceMarketplacePlatform from "../images/7. How Safex Works/Safex-eCommerce-Marketplace-Platform.webp";

import arrowUp from "../images/arrow-up-2.svg";
import ArrowDown from "../images/arrow-down-2.svg";
import Arrow from "../images/arrow.svg";


function HowSafexWorks() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>How The Safex Blockchain Works | safex.org</title>
        <meta name="description" content="Safex is open-source software built by and for the community to enable free and private transactions worldwide." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/how-safex-works" />
      </Helmet>
      <section className="hero-2-cols d-flex flex-column how-safex-works-hero">
        <div className="container order-2 order-md-1">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>How Safex works? <span>Blockchain Technology For A Free World</span></h1>
                <p>The Safex open-source software establishes a network and supports a protocol that enables sending and receiving the Safex Coins (SFX & SFT), staking Safex Token (SFT) and receving passive income from purchases on Safex Market, buying and selling products on Safex Market, as well as earning by mining Safex Cash (SFX).</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
            </div>
          </div>
        </div>
        <div className="order-1 order-md-2 how-safex-works-hero-image">
          <img src={HowSafexTechnologyWorks} className="img-fluid" alt=""/>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-fluid collapse-card">
                <div className="accordion-top HowSafexBlockchainWorks">
                  <div className="card-fluid-body">
                    <h2>SAFEX BLOCKCHAIN</h2>
                    <ul>
                      <li>Enables a worldwide eCommerce protocol</li>
                      <li>Privacy for security focused</li>
                      <li>Uses CryptoNote protocol</li>
                      <li>Twin-coin  ecosystem: Safex Token (SFT) and Safex Cash (SFX)</li>
                      <li>Proof of Work mining algorithm that home computers can use</li>
                      <li>Coin emission curve based on theory of technological adoption</li>
                      <li>Quick Block time: targeting a 120-second (2 minute) average</li>
                      <li>Dynamic Block size to reduce congestion for seamless usage</li>
                    </ul>
                    <div className="collapse-btn">
                      <div className="btn-arrow collapsed" data-bs-toggle="collapse" data-bs-target="#card1" aria-expanded="false" aria-controls="card1">
                        Show more
                        <img src={ArrowDown} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="card-fluid-image">
                    <img src={HowSafexBlockchainWorks} className="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="collapse collapse-card-inner" id="card1">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr className="collapse-border"/>
                        <div className="collapse-text">
                          <p>The Safex Blockchain is a cryptocurrency protocol specifically designed to facilitate peer-to-peer eCommerce for both goods and services, worldwide.</p>
                          <p>Safex’s Proof of Work (PoW) mining algorithm, RandomSFX, is an adaptation of the RandomX mining algorithm, and is intentionally designed for CPU-efficient mining on regular PC hardware.</p>
                          <p>This allows people to easily use their home computers to mine Safex Cash, while also increasing the sovereignty and security of the network through their participation in the mining process.</p>
                        </div>
                        <div className="collapse-chart">
                          <div className="chart-left">
                            <img src={EverettRoger_sDiffusionofInnovationModeltechnologyadoptioncurve} className="img-fluid" alt=""/>
                          </div>
                          <div className="chart-right">
                            <img src={MonetaryBaseCComparisonBitcoinLitecoinMoneroSafexCash} className="img-fluid" alt=""/>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between w-100">
                          <Link to="/mining-safex-cash" className="btn-arrow">
                            Start mining
                            <img src={Arrow} alt=""/>
                          </Link>
                          <div className="collapse-btn collapsed show-less" type="button" data-bs-toggle="collapse" data-bs-target="#card1" aria-expanded="false" aria-controls="card1">
                            <span>Show Less</span>
                            <img src={arrowUp} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid collapse-card">
                <div className="accordion-top SafexCashPaymentMethod">
                  <div className="card-fluid-body">
                    <h2>SAFEX CASH (SFX)</h2>
                    <ul>
                      <li>Cryptocurrency used as a medium of exchange</li>
                      <li>The only currency accepted on the Safex Market Protocol</li>
                      <li>Proof of Work (PoW) mineable cryptocurrency</li>
                      <li>SFX Emission curve based on the Theory of Innovation adoption curve, coins for everyone</li>
                      <li>All blockchain transactions use Safex Cash as the ‘gas’ for fees</li>
                    </ul>
                    <div className="collapse-btn">
                      <div className="btn-arrow collapsed" data-bs-toggle="collapse" data-bs-target="#card2" aria-expanded="false" aria-controls="card2">
                        Show more
                        <img src={ArrowDown} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="card-fluid-image">
                    <img src={SafexCashPaymentMethod} className="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="collapse collapse-card-inner" id="card2">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr className="collapse-border"/>
                        <div className="collapse-text">
                          <h2 className="font-18 mb-0">How can you obtain Safex Cash?</h2>
                          <ul>
                            <li>Buy it on crypto-exchanges,</li>
                            <li>Earn it by mining on your PC,</li>
                            <li>Earn by selling your goods and/or services on Safex Market,</li>
                            <li>Stake SFT and receive revenue payments in Safex Cash.</li>
                          </ul>
                          <h2 className="font-18">Safex Cash, as a medium of exchange, is the only currency of the Safex Marketplace. All goods and services purchased on Safex Marketplace are paid for with Safex Cash.</h2>
                          <ul>
                            <li>Safex Cash is Proof of Work (PoW) mineable.</li>
                            <li>As a PoW consensus blockchain, mining Safex Cash supports the Safex network and rewards miners with Safex Cash for their efforts.</li>
                            <li>Safex Cash Max Supply: 1,000,000,000 SFX over a 20-year emission curve, followed by an ongoing trickle emission of 3 SFX per block thereafter.</li>
                            <li>PoW Mining Algorithm: RandomSFX (CPU efficient, ASIC resistant),</li>
                            <li>Difficulty Retarget: Every Block (LWMA Algorithm), using the last 60 blocks,</li>
                            <li>Block Time Target: 120 seconds (~720 blocks/day),</li>
                            <li>Maximum Block Size: Dynamic,</li>
                            <li>Coin Emission Curve: S-curve, based on the new technology adoption rate curve,</li>
                            <li>Recommended Hardware: Regular Home PC,</li>
                            <li>Recommended Software: xmrig, SFXOS, or 1-click SFX miner,</li>
                            <li>SFX is divisible to 10 decimal places (Smallest unit: 0.0000000001).</li>
                          </ul>
                          <h2 className="font-18">The Egalitarian Emission Curve for Safex Cash</h2>
                          <p>Because the Safex Cash emission curve is S-shaped over a 20-year period, it will give more opportunities for users finding and mining coins in future years, compared to other cryptocurrency models like Bitcoin, Monero, or Litecoin, which pumped out the vast majority of their coins to the early miners. This means early Safex adopters cannot concentrate all the wealth, but will also share it with others who join later.</p>
                          <h2 className="font-18">Paying transaction fees in Safex Cash</h2>
                          <p className="mb-0">Safex Cash is used to pay all transaction fees on the Safex blockchain network, regardless of which of the 2 coins are being transacted:</p>
                          
                          <ul>
                            <li>transfers of Safex Token or Safex Cash,</li>
                            <li>listing/editing offers,</li>
                            <li>creating/editing merchant accounts,</li>
                            <li>creating/updating price pegs,</li>
                            <li>purchasing offers,</li>
                            <li>sending feedback,</li>
                            <li>staking/unstaking Tokens.</li>
                          </ul>
                          <p>The transaction fees are normally only a fraction of a SFX.</p>
                          <h2 className="font-18">Receiving revenue in Safex Cash</h2>
                          <p>A 5% Sellers commission fee gets applied to every item (goods or service) at the time of purchase. 100% of those commission fees go into the Safex Commission Pool, and every 1000-blocks, that pool of SFX gets allocated to all qualifying Staked SFT on a pro-rata basis.</p>

                        </div>
                        <div className="collapse-chart">
                          <div className="chart-left">
                            <img src={SFXSafexCashemissioncurveandmonetarybase} className="img-fluid" alt=""/>
                          </div>
                          <div className="chart-right">
                            <img src={SFXSafexCashemissionquantity} className="img-fluid" alt=""/>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between w-100">
                          <Link to="/buy-safex-cash" className="btn-arrow">
                            Buy Safex Cash
                            <img src={Arrow} alt=""/>
                          </Link>
                          <div className="collapse-btn collapsed show-less" type="button" data-bs-toggle="collapse" data-bs-target="#card2" aria-expanded="false" aria-controls="card2">
                            <span>Show Less</span>
                            <img src={arrowUp} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid collapse-card">
                <div className="accordion-top SafexTokenExplained">
                  <div className="card-fluid-body">
                    <h2>SAFEX TOKEN (SFT)</h2>
                    <ul>
                      <li>All the tokens are already in circulation: 1,885,974,016 SFT</li>
                      <li>Used to create Merchant Accounts for the Safex Market</li>
                      <li>Stakeable cryptocurrency that provides passive income</li>
                    </ul>
                    <div className="collapse-btn">
                      <div className="btn-arrow collapsed" data-bs-toggle="collapse" data-bs-target="#card3" aria-expanded="false" aria-controls="card3">
                        Show more
                        <img src={ArrowDown} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="card-fluid-image">
                    <img src={SafexTokenExplained} className="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="collapse collapse-card-inner" id="card3">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr className="collapse-border"/>
                        <div className="collapse-text">
                          <p>The Safex Token is a utility coin that allows users to create merchant accounts, which allows people to list goods and services for sale on Safex Market. Safex Tokens can also be Staked, allowing holders to participate in revenue sharing.</p>
                          <ul>
                          <li>SFT Max Supply: 1,885,974,016 SFT,</li>
                            <li>Create Merchant Account: 1,000 SFT get locked for 22,000 blocks (~30 Days),</li>
                            <li>Revenue Sharing via Staking SFT (25,000 minimum stake amount),</li>
                            <li>Disbursement Frequency: Every 1,000 Blocks,</li>
                            <li>Minimum Staking time: 8,000 blocks (~10 days),</li>
                            <li>Rev Share Accrual Limit: 500,000 Blocks (~1.9 years),</li>
                            <li>Sellers Commission Fee: 5% of purchase price [in SFX] (100% of the commission fee gets paid to eligible Staked SFT Holders),</li>
                            <li>Cannot be mined.</li>
                          </ul>
                          <h2 className="font-18">Opening Your Store</h2>
                          <p>Safex Tokens (SFT) are required to establish a merchant account on the network, which could then be used to create brand recognition, as well as to maintain a consistent seller identity. The blockchain network requires the user to lock 1,000 SFT for approximately 30 days. Items can be listed and sold almost immediately (after 10 blocks), and the locked Tokens get returned into the user's Safex wallet balance after the lock period expires.</p>
                          <h2 className="font-18">Safex Market Revenue Sharing</h2>
                          <p>Safex Market allows users to buy or sell anything, with anyone, wherever they may be. Every sale on the Market is transacted using Safex Cash (SFX) as the payment method, and a flat 5% commission fee is deducted from the sale price, with the remaining 95% going immediately to the Merchant’s account.</p>
                          <p>100% of the 5% fee is automatically allocated every 1000 blocks amongst all eligible Staked Safex Tokens (SFT). To be eligible for the Rev Share, the user must stake a minimum of 25,000 SFT and keep it staked for a minimum of 10 days. Only those SFT staked for the entire 1000-block interval are eligible. (ie. Every new Staking becomes eligible for rev share at the beginning of the next 1000-block interval.)</p>
                          <p>At some point thereafter, users can collect their rev share “dividends” with an Unstake of their SFT. The maximum time that the rev share can accrue for any Staking event is 500,000 blocks. The SFT can remain Staked, but they become ineligible for additional rev share. This mechanism has been included to prevent lost wallets from becoming an eternal sinkhole for SFX.</p>
                          <p>After an Unstake, the previously staked SFT and the associated rev share SFX return to the wallet available balance after 10 blocks (about 30 minutes) [it will show as Pending Balance during those 10 blocks]. The user can then Stake the SFT again, continue accruing rev share, and spend their newly arrived rev share SFX.</p>
                          <p>Because the SFT are unstaked during the 1000-block interval, they are not eligible for any rev share allocation in that interval. Ideally, the Unstake and subsequent Stake should be performed in the same 1000-block interval, to minimise missed rev share opportunity.</p>
                          <h2 className="font-18">Calculating Dividend Payout</h2>
                          <p>The 5% fee from every item sold during each 1000-block interval is added to the Safex Commission Pool. At the end of the interval, the total fees accumulated in the Commission Pool are allocated to all eligible Staked SFT. And the process starts anew, for the next interval. Although the rev share per interval varies, the rev share accumulates against eligible Staked SFT every interval.</p>
                          <p>Based on yearly Gross Marketplace Volume (GMV), rev share dividends can be thought of as an Annual Percentage Yield (APY) payout. Additionally, users can see estimated $ returns for any GMV by entering their Staked SFT amount into https://safex.tools and adding additional information for percentage of total SFT Staked.</p>
                        </div>
                      
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between w-100">
                          <Link to="/buy-Safex-token" className="btn-arrow">
                            Buy Safex Token
                            <img src={Arrow} alt=""/>
                          </Link>
                          <div className="collapse-btn collapsed show-less" type="button" data-bs-toggle="collapse" data-bs-target="#card3" aria-expanded="false" aria-controls="card3">
                            <span>Show Less</span>
                            <img src={arrowUp} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid collapse-card">
                <div className="accordion-top SafexTWMWalletappExplained">
                  <div className="card-fluid-body">
                    <h2>SAFEX WALLET APP</h2>
                    <ul>
                      <li>Send and Receive Safex Cash (SFX) and Safex Token (SFT)</li>
                      <li>Create/edit merchant accounts</li>
                      <li>Create/edit product/service listings</li>
                      <li>View listed items</li>
                      <li>Purchase items</li>
                      <li>Sending feedback on previous purchases</li>
                      <li>Stake/Unstake Safex Tokens (SFT)</li>
                      <li>Send/Receive Messages to/from Merchants/Buyers (TWM Wallet only)</li>
                      <li>Create/Update price pegs (cli wallet only)</li>
                      <li>Mine Safex Cash (SFX) directly to this Wallet</li>
                    </ul>
                    <div className="collapse-btn">
                      <div className="btn-arrow collapsed" data-bs-toggle="collapse" data-bs-target="#card4" aria-expanded="false" aria-controls="card4">
                        Show more
                        <img src={ArrowDown} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="card-fluid-image">
                    <img src={SafexTWMWalletappExplained} className="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="collapse collapse-card-inner" id="card4">
                  <div>
                  <div className="row">
                      <div className="col-md-12">
                        <hr className="collapse-border"/>
                        <div className="collapse-text">
                          <div className="col-small">
                            <p>The Safex TWM Wallet is a user-friendly interface to send and receive Safex Coins, and interact with the Safex Market, an advanced eCommerce platform on the Safex blockchain. The Safex TWM Wallet is not just a regular crypto wallet - it has additional functionality to stake/unstake Safex Tokens, plus the entire marketplace platform for buying and selling goods and services.</p>
                          </div>
                          <div className="collapse-flex">
                            <div>
                              <h2 className="font-18">The Safex Wallet is capable of displaying:</h2>
                              <ul>
                                <li>Safex coin balances,</li>
                                <li>Goods and services available for purchase,</li>
                                <li>Staked SFT outputs,</li>
                                <li>Complete Transaction History,</li>
                                <li>Feedback/Rating (currently cli wallet only; awaiting TWM v.2 release),</li>
                                <li>Price Peg information (currently cli wallet only; awaiting TWM v.2 release).</li>
                              </ul>
                            </div>
                            <div>
                              <h2 className="font-18">The Safex Wallet can:</h2>
                              <ul>
                                <li>Send/Receive SFT and SFX,</li>
                                <li>Purchase Goods or Services,</li>
                                <li>Stake/Unstake SFT,</li>
                                <li>Create/Edit Safex (Merchant) Account (awaiting TWM v.2 release),</li>
                                <li>List/Edit Goods or Services (awaiting TWM v.2 release),</li>
                                <li>Provide Feedback on a previous Purchase (awaiting TWM v.2 release),</li>
                                <li>Create/Update Price Peg (cli wallet only).</li>
                              </ul>
                            </div>
                          </div>
                          <h2 className="font-18">Safex TWM Wallet interface:</h2>
                          <p className="mb-0">Home:</p>
                          <ul>
                            <li>View SFT and SFX balances</li>
                            <li>Send SFX</li>
                          </ul>
                          <p className="mb-0">Market:</p>
                          <ul>
                            <li>View items listed for sale</li>
                            <li>Purchase a product/service</li>
                            <li>Encrypted Messaging system for communication between buyers and sellers.</li>
                            <li>Send Feedback on a previous purchase (awaiting TWM v.2 release)</li>
                          </ul>
                          <p className="mb-0">Merchant:</p>
                          <ul>
                            <li>Create/Edit/Restore a Merchant Account (awaiting TWM v.2 release)</li>
                            <li>List/Edit a product/service for sale, either directly for a set amount of SFX, or pegged to a price oracle, such as USD (awaiting TWM v.2 release)</li>
                          </ul>
                          <p className="mb-0">Tokens:</p>
                          <ul>
                            <li>View SFT and SFX balances</li>
                            <li>Send SFT</li>
                            <li>Stake/Unstake SFT</li>
                            <li>View accrued SFX Rev Share in the Staking Table</li>
                          </ul>
                        </div>
                      </div>
                     
                    </div>
                  
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between w-100">
                          <Link to="/wallet" className="btn-arrow">
                            Install the Wallet
                            <img src={Arrow} alt=""/>
                          </Link>
                          <div className="collapse-btn collapsed show-less" type="button" data-bs-toggle="collapse" data-bs-target="#card4" aria-expanded="false" aria-controls="card4">
                            <span>Show Less</span>
                            <img src={arrowUp} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid collapse-card">
                <div className="accordion-top SafexeCommerceMarketplacePlatform">
                  <div className="card-fluid-body">
                    <h2>SAFEX  MARKET</h2>
                    <ul>
                      <li>Create/Edit a Merchant Account</li>
                      <li>List/Edit a product/service for sale, either directly for a set amount of SFX, or pegged to a price oracle, such as USD</li>
                      <li>Purchase a product/service</li>
                      <li>Send Feedback about a previous purchase experience</li>
                    </ul>
                    <div className="collapse-btn">
                      <div className="btn-arrow collapsed" data-bs-toggle="collapse" data-bs-target="#card5" aria-expanded="false" aria-controls="card5">
                        Show more
                        <img src={ArrowDown} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="card-fluid-image">
                    <img src={SafexeCommerceMarketplacePlatform} className="img-fluid" alt=""/>
                  </div>
                </div>
                <div class="collapse collapse-card-inner" id="card5">
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr className="collapse-border"/>
                        <div className="collapse-text">
                          <p>Safex Marketplace is The crypto platform for buying and selling high-quality goods and services. It’s a next generation eCommerce engine, with user privacy as the central pillar of the technology.</p>
                          <p>The marketplace is decentralized, meaning anybody can participate in the future of eCommerce. The only requirements to access this marketplace, and interact with the Safex blockchain, is the easy-to-use Safex Wallet (App) and an internet connection.</p>
                          <p>The Safex Blockchain contains a suite of advanced eCommerce functions baked-in to the blockchain protocol. Users perform all necessary actions by using the Safex Wallet to send the required transaction to the blockchain for processing.</p>
                          <p>As the Safex Blockchain uses Ring Signatures and One-Time Addresses, it protects users wallet balances and transactions from public scrutiny. Both the buyer and sellers can complete their mutual transactions without revealing unnecessary information about the contents of their wallet balances, nor any other transactions they may have made.</p>
                          <h2 className="font-18">TWM-API</h2>
                          <p>In addition to the blockchain protocols, a complementary API-layer allows for the curation of approved Merchants and their listed items in the Safex TWM Wallet. The API also facilitates the encrypted Buyer-Merchant Messaging System, based on RSA-4096 encryption. Every purchase creates a new messaging instance between the buyer and seller, and is associated with the purchase of that item in the TWM Wallet.</p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between w-100">
                          <a href="https://safex.ninja/" target="_blank" rel="noreferrer" className="btn-arrow">
                            Visit Safex Catalog
                            <img src={Arrow} alt=""/>
                          </a>
                          <div className="collapse-btn collapsed show-less" type="button" data-bs-toggle="collapse" data-bs-target="#card5" aria-expanded="false" aria-controls="card5">
                            <span>Show Less</span>
                            <img src={arrowUp} alt=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Safex Forum</a> or <a href="https://twitter.com/safex" target="_blank" rel="noreferrer">Twitter</a>. <br/> Enjoy entering the Safex Ecosystem.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HowSafexWorks;
