import '../App.css';
import { Helmet } from "react-helmet";
//import { Link } from "react-router-dom";
import axios from 'axios'
import { useState } from 'react';

import { HashLink } from 'react-router-hash-link';

import ogImg from "../images/thumbnails/Mining-Safex-Cash.png";
import MiningSafexCashSFX from "../images/8. Mining/Mining-Safex-Cash-SFX.webp";
import SafexCashMiningOperatingSystemSFXOS from "../images/8. Mining/Safex-Cash-Mining-Operating-System-SFXOS.webp";
import MiningSafexCashusingXMRIG from "../images/8. Mining/Mining-Safex-Cash-using-XMRIG.webp";
import SoloMiningSafexCashviaaFullNode from "../images/8. Mining/Solo-Mining-Safex-Cash-via-a-Full-Node.webp";
import SafexCashOneClickMiner from "../images/8. Mining/Safex-Cash-One-Click-Miner.webp";
import MineSafexCashSFX from "../images/8. Mining/Mine-Safex-Cash-SFX.webp";
import Arrow from "../images/arrow.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowUp from "../images/arrow-up-3.svg";
import {useEffect} from "react";


function top(e) {
  e.preventDefault();
  var chartPos = document.getElementById("chart-wrap-section");
  window.scroll({
    top: chartPos.offsetTop,
    behavior: 'smooth'
  });
}


function MiningSafexCash() {
  
  window.scrollTo(0, 0);
  const [height, setHeight] = useState("loading..");
  const [hashrate, setHash] = useState("loading..");

  useEffect(async () => {
    let mining_stats = await get_stats();
    setHeight(mining_stats.network.height);
    setHash((mining_stats.network.difficulty / 120 / 1000000).toFixed(2))

    console.log(await get_stats())
  })

  const get_stats = async () => {
    return axios({
      method: `get`,
      url: `https://pool.safex.org/api/live_stats`
    }).then((res) => {
      return res.data;
    })
  }

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mining Crypto Safex Cash SFX | safex.org</title>
        <meta name="description" content="Mine Safex Cash (SFX), support Safex Blockchain, and earn. Earned SFX can be spent on the Safex Market, traded on exchanges, or kept in your wallet." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/mining-safex-cash" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Mine Safex Cash (SFX) <span>Support the Network and Earn Crypto</span></h1>
                <p>By mining Safex Cash you are both supporting Safex Blockchain and earning cryptocurrency. Earned Safex Cash can be spent on the Safex Market, traded on exchanges, or kept in your Wallet.</p>
                <a href="#how-to-mine-safex-cash" className="btn-arrow">
                  Get Started
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={MiningSafexCashSFX} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray d-md-none how-to-mine-cash-section">
        <div className="container">
          <div className="row">
            <div>
                <h1>HOW TO MINE SAFEX CASH (SFX)?</h1>
                <p>There are 4 different ways to mine Safex Cash:</p>
                <ul>
                  <li>You can mine by building a mining rig and installing the SFXOS operating system,</li>
                  <li>using xmrig for Linux, Windows or Mac,</li>
                  <li>solo mining via a Full Node,</li>
                  <li>by running One click miner app. (easiest)</li>
                </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray coins-card-wrap-section">
        <div className="container">
          <div className="row">
            <div className="coins-card-wrap">
              <div className="price-card green">
                <p className="price-text">BLOCKCHAIN HEIGHT</p>
                <p className="tablet-text">BLOCK HEIGHT</p>
                <p className="current-price">{height}</p>
              </div>
              <div className="price-card blue">
                <p className="price-text">BLOCK REWARD</p>
                <p className="tablet-text">BLOCK REWARD</p>
                <p className="current-price">410 SFX</p>
              </div>
              <div className="price-card blue">
                <p className="price-text">NETWORK HASHRATE</p>
                <p className="tablet-text">NET HASHRATE</p>
                <p className="current-price">{hashrate} MH/s</p>
              </div>
              <div className="price-card gray">
                <p className="price-text">MINING ALGORITHM</p>
                <p className="tablet-text">MINING ALGO</p>
                <p className="current-price">randomSFX</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-none d-md-block how-to-mine-cash-section" id="how-to-mine-safex-cash">
        <div className="container">
          <div className="row">
            <div>
              <h1>HOW TO MINE SAFEX CASH (SFX)?</h1>
              <p>There are 4 different ways to mine Safex Cash:</p>
              <ul>
                <li>You can mine by building a mining rig and installing the SFXOS operating system,</li>
                <li>using xmrig for Linux, Windows or Mac,</li>
                <li>solo mining via a Full Node,</li>
                <li>by running the One click miner app.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="card-with-image-wrap four-rows">
              <HashLink smooth to="/mining-safex-cash#one-click-miner-application" className="card-with-image">
                <div className="card-top">
                  <img src={SafexCashOneClickMiner} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>ONE CLICK <span>MINER<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex easy one click miner is now available. Anybody, regardless of PC skill level, can now mine Safex Cash.</p>
                </div>
              </HashLink>
              <HashLink smooth to="/mining-safex-cash#safex-cash-operating-system-SFXOS" className="card-with-image">
                <div className="card-top">
                  <img src={SafexCashMiningOperatingSystemSFXOS} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>SFXOS <span>SYSTEM<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Assemble the components, and run the custom SFXOS operating system to start mining Safex Cash to your preferred mining pool. </p>
                </div>
              </HashLink>
              <HashLink smooth to="/mining-safex-cash#XMRIG-mining" className="card-with-image">
                <div className="card-top">
                  <img src={MiningSafexCashusingXMRIG} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>MINING USING <span>XMRIG<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Start mining using xmrig for your operating system. Mining is done via the Safex Mining Pool of your choice.</p>
                </div>
              </HashLink>
              <HashLink smooth to="/mining-safex-cash#full-node-solo-mining" className="card-with-image">
                <div className="card-top">
                  <img src={SoloMiningSafexCashviaaFullNode} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>FULL <span>NODE<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">It's not common to mine using a Full Node, but it is possible to mine directly from within the safexd node software.</p>
                </div>
              </HashLink>
              
            </div>
          </div>
        </div>
      </section>
      <section className="step-section option-section step-section-first mining-step-section" id="one-click-miner-application">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">1.</p>
              <p className="d-none d-md-block">OPTION 1</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">One-click miner application (the easiest way)</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <div className="step-body-row">
                <div className="col-md-6">
                  <h4 className="mb-4">How to mine using 1-click miner?</h4>
                  <ol>
                    <li>Download the Miner for: <a href="https://github.com/safex/team-1-click-miner/releases/download/v2.0.0/One-Click-Safex-Cash-Miner-v2.0-windows.exe" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Windows, </a>
                      <a href="https://github.com/safex/team-1-click-miner/releases/download/v2.0.0/One-Click-Safex-Cash-Miner-v2.0-applemac.dmg" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Mac, </a>
                       or
                        <a href="https://github.com/safex/team-1-click-miner/releases/download/v2.0.0/One-Click-Safex-Cash-Miner-v2.0-ubuntu.deb" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Ubuntu</a>

                         </li>
                    <li>Launch the app</li>
                    <li>Enter your Safex Wallet Address <a href="/wallet"  target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">(a TWM Wallet is needed)</a></li>
                    <li>Name your mining rig (optional)</li>
                    <li>Select which mining pool you want to connect to using the dropdown menu.</li>
                    <li>Select how much CPU power you want to use (25%,50%,75%,100%)</li>
                    <li>Click the Start Mining button</li>
                    <li>In a web browser, go to your selected pool website and adjust miner payout (optional, but recommended).</li>
                  </ol>
                </div>
                <div className="step-body-right">
                  <h4 className="mb-4">Guides:</h4>
                  <p>One-Click miner is available for:</p>
                  <a href="https://safex.org/blog/installing-and-running-the-safex-one-click-miner-windows/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Windows
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://safex.org/blog/safex-one-click-miner-mac-guide/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Mac
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://safex.org/blog/safex-one-click-miner-mac-guide/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Ubuntu
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
              <h2 className="fw-normal mb-1 mb-2">Follow the video guide:</h2>
              <iframe className="video" src="https://www.youtube.com/embed/TZrzIiT7D1c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section option-section" id="safex-cash-operating-system-SFXOS">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">2.</p>
              <p className="d-none d-md-block">OPTION 2</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Safex Cash Operating System (SFXOS)</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <div className="step-body-row">
                <div className="col-md-4">
                  <h4 className="mb-4">How to mine using SFXOS mining system?</h4>
                  <ol>
                    <li>Order the equipment</li>
                    <li>Obtain, Assemble, and Plug in the components</li>
                    <li>Install Safex Cash Operating System</li>
                    <li>Follow the updates</li>
                  </ol>
                </div>
                <div className="col-md-4">
                  <h4 className="mb-4">What do you need?</h4>
                  <ol>
                    <li>Motherboard</li>
                    <li>Processor [CPU]</li>
                    <li>Memory [RAM]</li>
                    <li>Thermal Paste</li>
                    <li>Power Supply Unit [PSU]</li>
                    <li>Network cable</li>
                    <li>USB 3.0+ Stick</li>
                  </ol>
                </div>
                <div className="step-body-right">
                  <h4 className="mb-4">Guides:</h4>
                  <a href="https://safex.org/blog/plug-and-play-safex-cash-cryptocurrency-mining/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Plug and Play Safex Cash Cryptocurrency Mining
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://safex.org/blog/building-a-safex-cash-mining-rig-with-crypto-girl/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Building a SafeX Cash mining rig with Crypto Girl
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
              <h2 className="fw-normal mb-1 mb-2">Follow the video guide:</h2>
              <iframe className="video" src="https://www.youtube.com/embed/4MnAqoDOTww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section option-section" id="XMRIG-mining">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">3.</p>
              <p className="d-none d-md-block">OPTION 3</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Mining using XMRIG</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <div className="step-body-row">
                <div className="col-md-4">
                  <h4 className="mb-4">Steps:</h4>
                  <ol>
                    <li>Preparing for Installation</li>
                    <li>Installation and Setup</li>
                    <li>Prepare the xmrig configuration file</li>
                    <li>Start Mining</li>
                    <li>Adjust Mining Pool Payouts</li>
                    <li>Housekeeping</li>
                  </ol>
                </div>
                <div className="col-md-4">
                  <h4 className="mb-4">What do you need?</h4>
                  <ol>
                    <li>Dual-core CPU with at least 2Mb of L3 cache</li>
                    <li>At least 2.5Gb of free RAM</li>
                  </ol>
                </div>
                <div className="step-body-right">
                  <h4 className="mb-4">Guides:</h4>
                  <a href="https://safex.org/blog/mining-sfx-using-xmrig-on-a-windows-pc/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Mining SFX Using xmrig on a Windows PC
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://safex.org/blog/mining-sfx-using-xmrig-on-a-mac/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Mining SFX Using xmrig on a Mac
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://safex.org/blog/mining-sfx-using-xmrig-on-a-linux/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Mining SFX Using xmrig on a Linux
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section option-section" id="full-node-solo-mining">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">4.</p>
              <p className="d-none d-md-block">OPTION 4</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Solo Mining via a Full Node</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <div className="step-body-row">
                <div className="col-md-4">
                  <h4 className="mb-4">How to mine using a Node running on your computer system?</h4>
                  <p>If your node is already running, you can start mining using the simple one line command line structure.</p>
                </div>
                <div className="col-md-4">
                  <h4 className="mb-4">What do you need?</h4>
                  <ol>
                    <li>Dual-core CPU with at least 2Mb of L3 cache</li>
                    <li>At least 2.5Gb of free RAM</li>
                  </ol>
                </div>
                <div className="step-body-right">
                  <h4 className="mb-4">Guides:</h4>
                  <a href="https://safex.org/blog/solo-mining-via-a-full-node/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">
                    Solo Mining via a Full Node
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-md-none p-0 mine-cash-image-section">
        <div>
          <img src={MineSafexCashSFX} className="img-fluid" alt=""/>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section mining-question-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on our <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Official Safex Forum.</a></h2>
            </div>
          </div>
        </div>
      </section>
      <section className="decide-section">
        <div className="container">
          <div className="row">
            <div className="decide-section-left col-md-6">
              <h2 className="fw-normal">How to decide what’s the best for you?</h2>
              <p>All the options for mining Safex Cash are straightforward and explained in detail on our blog. Support is available on the <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Safex Forum</a> (preferred, as others can search for answers to previous questions) and <a href="https://twitter.com/safex" target="_blank" rel="noreferrer">Twitter</a>, and you can contact prominent miners for help. Therefore, all available mining options are possible, and it simply comes down to your level of skill, ability to learn, and your motivation, as to which mining option you choose.</p>
              <ul>
                <li>Assembling and running SFXOS takes a bit more skill than the other options, but is designed to allow fast deployment of multiple rigs for a mining farm.</li>
                <li>Mining using xmrig is the option most miners currently use, as it allows for easier fine tuning of the mining rig, to extract maximum hashes.</li>
                <li>It is possible to mine using a full node; however, it isn’t recommended on high end rigs, as the generated hashrate is lower than using xmrig. On low end equipment, the hashrate is very similar to xmrig. Few blockchain supporters use this option.</li>
                <li>The 1-click miner will be the easiest application for novice miners to use.</li>
              </ul>
            </div>
            <div className="decide-section-right">
              <div className="d-flex flex-column h-100">
                <h2 className="fw-normal">Mining profitability and its key parameters</h2>
                <p className="mb-0">The mining profitability describes the profit margin of a miner that is based on his/her mining costs and the market price of the mined cryptocurrency.</p>
                <p>The relevant key parameters that make up the mining profitability are:</p>
                <ul>
                  <li>market price of the cryptocurrency</li>
                  <li>daily emission rate of the cryptocurrency</li>
                  <li>daily transaction fee revenue</li>
                  <li>daily energy consumption of the mining rig</li>
                  <li>generated hashrate by the mining rig</li>
                  <li>total hashrate of the network participants</li>
                  <li>price of electricity used for mining</li>
                  <li>price depreciation of the mining rig.</li>
                </ul>
                <a href="https://safex.org/blog/the-economics-of-mining-calculating-your-cost-basis-and-profit-and-loss/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal mb-3 font-18">
                  Read more
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex flex-column justify-content-center h-100">
                <h2 className="fw-normal">Choose a pool close to you:</h2>
                <ul className="list-blue">
                  <li><a href="https://pool.safex.org/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Safex.org (Poland) <img src={Arrow} alt=""/></a></li>
                  <li><a href="https://safex.ninja/pools" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Safex.ninja (Netherlands, USA, Australia) <img src={Arrow} alt=""/></a></li>
                  <li><a href="https://sf.pool-pay.com/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Pool-pay.com (Germany) <img src={Arrow} alt=""/></a></li>
                  <li><a href="https://fastpool.xyz/sfx/" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Fastpool.xyz (Bulgaria) <img src={Arrow} alt=""/></a></li>
                  <li><a href="https://cpu.skypool.org/#/dashboard" target="_blank" rel="noreferrer" className="btn-arrow fw-normal font-18">Skypool.org (China) <img src={Arrow} alt=""/></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-none d-md-block">
                <img src={MineSafexCashSFX} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MiningSafexCash;
