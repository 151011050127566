import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Promote-Safex.png";
import PromoteSafexImage from "../images/15. Promote Safex/Promote-Safex.webp";
import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function PromoteSafex() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Promote The Safex Ethos | safex.org</title>
        <meta name="description" content="Get involved in expanding Safex reach by joining our online community groups and find new ways to help in the field you know the best, in your spare time." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/promote-safex"/>
      </Helmet>
      <section className="hero-2-cols promote-safex-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-5 order-2 order-md-1">
              <div className="hero-text">
                <h1>Promote <span>The Safex Ethos</span></h1>
                <p>Thank you to all who have already contributed, are active, and supporting the Safex ecosystem!</p>
                <p>Get involved in expanding Safex reach by joining our online community groups and find new ways to help in the field you know the best, in your spare time. Here are some ideas....</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={PromoteSafexImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="share-content-section">
        <div className="container">
          <div className="row">
            <div className="share-content-text-col">
              <h2 className="fw-normal mb-4">Share existing content on social media</h2>
              <p>We create a lot of content in textual and visual form and publish them on blogs, Youtube, social media. We are happy to receive help with spreading the word farther! If you have an account on Twitter, Facebook, Instagram, Reddit, or other tech forums, you can give you perspective and share with your followers.</p> 

    	        <h2 className="fw-normal mt-4 mb-4">Make content</h2>
              <p>Feel free to make textual or visual content and post it on your blog, forum, Twitter, or social media. Post appealing text, images, memes, videos, tag Safex, include hashtags, and let’s spread the word even stronger: <span className="color-blue">#safexmarket #safexcash #safextoken #safex</span></p>
            
    	        <h2 className="fw-normal mt-4 mb-4">Suggest ideas</h2>
              <p>Suggest new ideas and possible improvements so we can make our world more powerful and more visible for future people. Write to us via the form or on the <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Safex forum</a>, or contact the team via a <a href="https://twitter.com/safex" target="_blank" rel="noreferrer">Twitter</a> message.</p>
            
            </div>
            <div className="share-content-form-col">
              <div className="form-wrap form-wrap-no-p">
                <form>
                  <h3 className="text-center"><span className="color-blue">We Want To Hear</span> From You</h3>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="name"
                      className="form-control"
                      id="name"
                      aria-describedby="name"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="email"
                      placeholder="example@yourdomain.com"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Your message</label>
                    <textarea class="form-control" id="message" rows="5" placeholder="Hi there..."></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary m-auto mt-3">
                    Send
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-5 find-your-place-section">
              <h1 className="fw-normal mt-4 mb-4">Find your place in the Safex Community.</h1>
              <Link to="/safex-community" className="btn-arrow">
                Join us!
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PromoteSafex;