import '../App.css';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Privacy-Blockchain.png";
import SafexPrivacyBlockchain from "../images/10. Privacy Blockchain/Safex-Privacy-Blockchain.webp";
import Arrow from "../images/arrow.svg";


function PrivacyBlockchain() {

    window.scrollTo(0, 0);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Safex Privacy Blockchain | safex.org</title>
                <meta name="description"
                      content="Learn how the Safex Blockchain protects the sovereignty of each user, and the technical properties designed to secure your future."/>
                <meta property="og:image" content={ogImg}/>
                <link rel="canonical" href="http://safex.org/privacy-blockchain"/>
            </Helmet>
            <section className="hero-2-cols">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="hero-text">
                                <h1>Safex Privacy Blockchain Features <span>Privacy is necessary for an open society in the electronic age*</span>
                                </h1>
                                <p>Safex is focused on protecting the sovereignty of each user, and the blockchain
                                    properties are designed to enable it. </p>
                                <Link to="/wallet" className="btn-arrow">
                                    Download Safex TWM Wallet
                                    <img src={Arrow} alt=""/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="hero-image">
                                <img src={SafexPrivacyBlockchain} className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blockchain-section">
                <div className="bg-gray pt-4 pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div>
                                    Safex blockchain is designed to protect users’ information:
                                    <ul className="m-0">
                                        <li>Coins balances are not publicly visible,</li>
                                        <li>Transactions are obfuscated,</li>
                                        <li>Connections between senders and receivers are not publicly visible,</li>
                                        <li>No individual Shopper metadata publicly exists, so your cumulative Safex
                                            Marketplace purchases can’t be analysed. You can buy products without fear
                                            of being subjected to middleman or third party analysis, or threat of
                                            discovering your past purchases or balances.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="mt-4">Safex Privacy features built into the blockchain</h2>
                            <p>Safex has implemented the CryptoNote application layer protocol, which embeds privacy
                                features into the blockchain. Additionally, the Safex Team has expanded the capabilities
                                to enable the Safex Marketplace application. These features are automatically
                                implemented when using the Safex Wallet, and you have nothing to do except enjoy your
                                fundamental human rights.</p>

                            <h2 className="mt-4">Privacy Coins:</h2>
                            <p>Both Safex Token and Safex Cash are privacy coins. If you are familiar with privacy
                                crypto coins, you already know something about Safex Coins.</p>

                            <h2 className="mt-4">How is privacy obtained?</h2>
                            <p>Thanks to ring signatures and one-time stealth addresses, safex blockchain provides safe
                                and secure transactions, and keeps your balances and marketplace purchases safe from
                                prying eyes.</p>
                            <p>Ring Signatures are used to obfuscate the true identity of a transaction creator, via an
                                anonymising technique that adds other public signatures to the sender’s true signature
                                during the transaction formation. This technique allows user wallets to determine the
                                real signature, but outside observers are unable to establish which signature belongs to
                                the actual sender.</p>
                            <p>One-Time Stealth Addresses are automatically generated for each transaction created. In
                                this way, a user’s public Safex address is never included in the transaction details,
                                neither for the sender nor the receiver address. A person looking at transaction details
                                on the Safex block explorer is unable to see any identifiable information about the
                                participants of the transaction.</p>
                            <p>The pair of one-time stealth addresses for a transaction are generated from the sender's
                                spend key and the receiver's Safex address (which comprises 2 public keys). Only the two
                                wallet addresses involved in the transaction are able to accurately determine the amount
                                of coins being sent in the transaction, and that the transaction relates to their
                                addresses. Blockchain commands exist that allow the sender to prove they were indeed the
                                sender of a particular transaction, if the need arises.</p>

                            <a href="https://safex.org/blog/online-privacy/" target="_blank" rel="noreferrer" className="btn-arrow">
                                Read more about privacy features of Safex on our blog
                                <img src={Arrow} alt=""/>
                            </a>

                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray-text bg-gray privacy-necessary-section">
                <div className="container">
                    <div className="row">
                        <div className="text">
                            <h3 className="mb-0 fw-normal">*Privacy is necessary for an open society in the electronic
                                age. <br/> Privacy is not secrecy. A private matter is something one doesn't <br/> want
                                the whole world to know, but a secret matter is something one doesn't want anybody to
                                know. <br/> Privacy is the power to selectively reveal oneself to the world. - Eric
                                Hughes</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PrivacyBlockchain;