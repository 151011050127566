import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import React, {Component} from 'react';


class Chart2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {
                type: 'area',
                backgroundColor: 'transparent',
                title: {
                    'text': 'Staked Safex Token %',
                    'font-size': '14px'
                },
                height: "300px",
                width: "100%",
                crosshairX: {
                    lineColor: "#565656",
                    lineStyle: "dashed",
                    lineWidth: 2,
                    alpha: 0.5,
                    plotLabel: {
                        backgroundColor: "#ffffff",
                        borderColor: "#d2d2d2",
                        borderRadius: "5px",
                        bold: true,
                        fontSize: "12px",
                        fontColor: "#111",
                        shadow: true,
                        shadowDistance: 2,
                        shadowAlpha: 0.4,
                        text: "%v"
                    },
                    scaleLabel: {
                        bold: true,
                        backgroundColor: "#787878",
                        borderRadius: 3,
                        fontColor: "#eaeaea",
                        fontSize: "12px",
                        callout: true,
                        paddingTop: 2
                    },
                    marker: {
                        visible: false
                    }
                },
                scaleY: {
                    label: {
                        text: '% --->',
                        offsetX: 925
                    },
                    short: true,
                    lineColor: 'none',
                    tick: {
                        visible: true
                    },
                    guide: {
                        visible: true
                    },
                    item: {
                        visible: true
                    }
                },
                scaleX: {
                    label: {
                        text: 'days --->'
                    },
                    short: true
                },
                series: [{
                    values: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0.54,
                        0.83,
                        0.86,
                        1.12,
                        1.16,
                        1.16,
                        1.19,
                        1.27,
                        1.29,
                        1.37,
                        1.55,
                        1.61,
                        1.61,
                        1.79,
                        2.26,
                        2.26,
                        2.22,
                        2.31,
                        2.31,
                        2.31,
                        2.37,
                        2.47,
                        2.47,
                        2.58,
                        2.58,
                        2.69,
                        2.69,
                        2.79,
                        2.79,
                        2.81,
                        3.02,
                        3.18,
                        3.29,
                        3.29,
                        3.24,
                        3.25,
                        3.31,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.32,
                        3.35,
                        3.35,
                        3.35,
                        3.35,
                        3.35,
                        3.35,
                        3.35,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.36,
                        3.3,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.31,
                        3.37,
                        3.42,
                        3.42,
                        3.42,
                        3.42,
                        3.4,
                        3.4,
                        3.4,
                        3.4,
                        3.35,
                        4.56,
                        10.86,
                        13,
                        16.48,
                        19.31,
                        21.41,
                        22.13,
                        22.51,
                        22.66,
                        22.85,
                        22.9,
                        23.15,
                        23.88,
                        23.98,
                        24.03,
                        24.05,
                        23.67,
                        23.7,
                        23.88,
                        24.01,
                        24.11,
                        24.92,
                        24.95,
                        25,
                        25.38,
                        24.92,
                        27.78,
                        28.46,
                        29.03,
                        29.75,
                        30.22,
                        30.28,
                        30.45,
                        30.56,
                        30.58,
                        31.08,
                        31.15,
                        31.17,
                        30.54,
                        30.55,
                        30.61,
                        30.5,
                        30.67,
                        30.73,
                        30.8,
                        30.97,
                        31.04,
                        31.03,
                        31.17,
                        31.23,
                        32.06,
                        33.8,
                        33.89,
                        33.98,
                        33.99,
                        34.08,
                        34.27,
                        34.16,
                        34.21,
                        34.37,
                        34.57,
                        34.56,
                        34.56,
                        34.57,
                        34.61,
                        34.67,
                        34.69,
                        35.23,
                        35.31,
                        34.68,
                        34.71,
                        34.71,
                        34.83,
                        34.93,
                        34.92,
                        34.93,
                        34.93,
                        34.93,
                        34.91,
                        34.99,
                        35.05,
                        35.19,
                        35.21,
                        35.21,
                        35.21,
                        35.21,
                        35.45,
                        35.45,
                        35.54,
                        35.57,
                        35.57,
                        35.57,
                        35.59,
                        35.58,
                        35.58,
                        35.58,
                        35.58,
                        35.6,
                        35.61,
                        35.61,
                        35.61,
                        35.62,
                        35.62,
                        35.62,
                        35.63,
                        35.64,
                        35.64,
                        35.64,
                        35.67,
                        36.24,
                        36.24,
                        36.25,
                        36.29,
                        36.29,
                        36.3,
                        36.3,
                        36.3,
                        36.33,
                        36.23,
                        36.13,
                        36.06,
                        35.99,
                        36.11,
                        36.12,
                        36.14,
                        36.14,
                        36.14,
                        36.24,
                        36.24,
                        36.24,
                        36.26,
                        36.26,
                        36.27,
                        36.26,
                        36.26,
                        36.26,
                        36.27,
                        36.4,
                        36.4,
                        36.4,
                        36.5,
                        36.5,
                        36.51,
                        36.51,
                        36.52,
                        36.56,
                        36.56,
                        36.83,
                        36.86,
                        36.63,
                        36.63,
                        36.64,
                        36.62,
                        36.65,
                        36.06,
                        36.06,
                        36.04,
                        36.07,
                        36.15,
                        36.15,
                        36.32,
                        36.85,
                        36.85,
                        36.62,
                        36.63,
                        36.64,
                        36.86,
                        36.86,
                        36.86,
                        36.86,
                        36.89,
                        37.32,
                        37.33,
                        36.74,
                        36.92,
                        36.92,
                        36.92,
                        36.93,
                        36.95,
                        36.95,
                        36.95,
                        37.06,
                        37.07,
                        37.07,
                        37.07,
                        37.1,
                        37.08,
                        37.1,
                        37.18,
                        37.23,
                        37.23,
                        37.13,
                        37.17,
                        37.55,
                        37.55,
                        37.6,
                        37.6,
                        37.64,
                        37.7,
                        37.7,
                        37.72,
                        37.72,
                        37.89,
                        37.99,
                        38.09,
                        38.11,
                        37.75,
                        37.75,
                        37.73,
                        38.01,
                        38.08,
                        38.08,
                        38.08,
                        38.11,
                        38.11,
                        38.12,
                        38.11,
                        38.05,
                        38.12,
                        38.2,
                        38.2,
                        38.22,
                        38.23,
                        39.08,
                        39.06,
                        39.12,
                        39.14,
                        39.15,
                        39.16,
                        40.5,
                        40.62,
                        40.62,
                        40.72,
                        40.61,
                        40.62,
                        40.62,
                        40.69,
                        40.69,
                        40.53,
                        40.71,
                        40.78,
                        40.56,
                        40.63,
                        40.71,
                        40.74,
                        40.71,
                        41,
                        41.07,
                        41.16,
                        41.47,
                        41.47,
                        41.5,
                        42.37,
                        42.36,
                        42.35,
                        42.37,
                        42.43,
                        42.73,
                        42.8,
                        42.91,
                        43.17,
                        43.11,
                        43.13,
                        43.24,
                        43.27,
                        43.62,
                        43.62,
                        43.65,
                        43.65,
                        43.46,
                        43.37,
                        43.43,
                        43.78,
                        43.78,
                        43.78,
                        43.84,
                        43.68,
                        43.68,
                        43.68,
                        43.73,
                        44.03,
                        44.03,
                        44.19,
                        44.21,
                        44.21,
                        44.29,
                        44.29,
                        44.29,
                        44.29,
                        44.3,
                        44.3,
                        44.18,
                        44.28,
                        44.28,
                        44.28,
                        44.25,
                        44.25,
                        44.25,
                        44.32,
                        44.49,
                        44.58,
                        44.6,
                        44.62,
                        44.63,
                        44.62,
                        44.49,
                        44.21,
                        44.19,
                        44.21,
                        44.21,
                        44.21,
                        44.21,
                        44.28,
                        44.29,
                        44.17,
                        44.17,
                        44.17,
                        44.16,
                        44.19,
                        44.19,
                        44.18,
                        44.18,
                        44.14,
                        44.14,
                        44.14,
                        43.95,
                        43.95,
                        43.97,
                        43.97,
                        43.97,
                        43.98,
                        43.82,
                        43.82,
                        43.82,
                        43.81,
                        43.79,
                        43.79,
                        43.79,
                        43.79,
                        43.78,
                        43.78,
                        43.79,
                        43.87,
                        43.87,
                        43.91,
                        43.89,
                        43.91,
                        43.92,
                        43.93,
                        43.93,
                        43.93,
                        43.93,
                        43.93,
                        43.93,
                        43.93,
                        43.94,
                        44.04,
                        44.26,
                        44.27,
                        44.26,
                        44.27,
                        44.29,
                        44.35,
                        44.35,
                        44.31,
                        44.31,
                        44.7,
                        44.7,
                        44.83,
                        44.83,
                        44.83,
                        44.87,
                        44.74,
                        44.78,
                        44.82,
                        44.82,
                        44.71,
                        44.71,
                        44.71,
                        44.7,
                        44.71,
                        45.03,
                        45.04,
                        45.14,
                        45.14,
                        45.14,
                        45.14,
                        45.13,
                        45.14,
                        45.14,
                        45.16,
                        45.17,
                        45.17
                    ],
                    backgroundColor: "#02b5a0 #b4f0e9",
                    alphaArea: 0.5,
                    lineColor: "#027366",
                    marker: {
                        backgroundColor: "#027366",
                        borderWidth: 1
                    }
                }],


            }
        }
    }

    render() {
        return (
            <div>
                <ZingChart data={this.state.config}/>
            </div>
        );
    }

}


export default Chart2;