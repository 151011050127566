import '../App.css';
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Donation-to-Safex-Org.png";
import DonationtoSafexorg from "../images/22. Donation to Safex org/Donation-to-Safex-org.webp";
import QRCode1 from "../images/22. Donation to Safex org/Safex Cash and Safex Token Donation.png";
import QRCode2 from "../images/22. Donation to Safex org/Wrapped Safex Cash Binance BSC Donation.png";
import QRCode3 from "../images/22. Donation to Safex org/Bitcoin-Donation.png";
//import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function Donation() {

  window.scrollTo(0, 0);
  
  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Donation - Support our Work | safex.org</title>
        <meta name="description" content="Support our work by sending us a donation of any size for the development of Safex projects and support its outreach. Thank You!" />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/donation" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Donation <span>Support our Work</span></h1>
                <p>Support our work by sending us a donation of any size for the development of Safex projects and support its outreach. Over the past years we have fully contributed to production and innovation based on the contributions from our great community.</p>
                <div className="instructions-mobile-none">
                  <h3 className="mb-3 fw-normal">Instructions:</h3>
                  <p>You can donate to our US based Non Profit organization directly using the following addresses with the corresponding cryptocurrency. For donations of other kinds, or if you require a receipt for tax deduction purposes, please reach out to <strong>donate@safex.org</strong> and we will be grateful to accommodate your contribution and provide you with the documentation that you will need for your generosity. Otherwise, feel free to know your contribution is always going towards the growth of Safex, its software and reach in the world.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={DonationtoSafexorg} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 qr-code-section">
        <div className="container">
          <div className="row instructions-mobile-block">
            <div className="col-md-12">
              <h3 className="mb-3 fw-normal">Instructions:</h3>
              <p>You can donate to our US based Non Profit organization directly using the following addresses with the corresponding cryptocurrency. For donations of other kinds, or if you require a receipt for tax deduction purposes, please reach out to <strong>donate@safex.org</strong> and we will be grateful to accommodate your contribution and provide you with the documentation that you will need for your generosity. Otherwise, feel free to know your contribution is always going towards the growth of Safex, its software and reach in the world.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <h4 className="mb-2">Safex Cash (SFX) and Safex Token (SFT)</h4>
              <div className="QR-code-wrap">
                <img src={QRCode1} className="img-fluid" alt=""/>
                <p>Safex5zzztXNUmcwvAyUQUEcAjpiKKrkAKvTAQf2ioJFVPU8FkjVKzSgqhG5Xn3aNAP81eUY8sBo6AzMhD27VqGYHfCxg48YV355E</p>
              </div>
            </div>
            <div className="col-md-4">
              <h4 className="mb-2">Binance (BSC)</h4>
              <div className="QR-code-wrap">
                <img src={QRCode2} className="img-fluid" alt=""/>
                <p>0x01C18341356B5cBf291cf92B7C214a287A1f9d2a</p>
              </div>
            </div>
            <div className="col-md-4">
              <h4 className="mb-2">Bitcoin (BTC)</h4>
              <div className="QR-code-wrap">
                <img src={QRCode3} className="img-fluid" alt=""/>
                <p>1MX8QaKqBNMox3UCJeKgwbDsmnXh9VL5GG</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mobile-bg-gray">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-0">Thank you for your donation!</h2>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block mt-0">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Donation;