import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import React, {Component} from 'react';


class Chart3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {
                type: 'area',
                backgroundColor: 'transparent',
                title: {
                    'text': 'New Listed Items',
                    'font-size': '14px'
                },
                height: "300px",
                width: "100%",
                crosshairX: {
                    lineColor: "#565656",
                    lineStyle: "dashed",
                    lineWidth: 2,
                    alpha: 0.5,
                    plotLabel: {
                        backgroundColor: "#ffffff",
                        borderColor: "#d2d2d2",
                        borderRadius: "5px",
                        bold: true,
                        fontSize: "12px",
                        fontColor: "#111",
                        shadow: true,
                        shadowDistance: 2,
                        shadowAlpha: 0.4,
                        text: "%v"
                    },
                    scaleLabel: {
                        bold: true,
                        backgroundColor: "#787878",
                        borderRadius: 3,
                        fontColor: "#eaeaea",
                        fontSize: "12px",
                        callout: true,
                        paddingTop: 2
                    },
                    marker: {
                        visible: false
                    }
                },
                scaleY: {
                    label: {
                        text: "New Listed Offers --->",
                        offsetX: 453
                    },
                    short: true,
                    lineColor: 'none',
                    tick: {
                        visible: true
                    },
                    guide: {
                        visible: true
                    },
                    item: {
                        visible: true
                    }
                },
                scaleX: {
                    label: {
                        text: 'days --->',
                    },
                    short: true
                },
                series: [{
                    values: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        4,
                        4,
                        7,
                        10,
                        10,
                        13,
                        13,
                        14,
                        14,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        16,
                        22,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        23,
                        24,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        25,
                        26,
                        26,
                        26,
                        26,
                        26,
                        26,
                        29,
                        30,
                        30,
                        30,
                        30,
                        30,
                        30,
                        30,
                        30,
                        32,
                        32,
                        32,
                        32,
                        34,
                        34,
                        34,
                        34,
                        34,
                        34,
                        34,
                        34,
                        34,
                        34,
                        35,
                        35,
                        36,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        37,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        38,
                        39,
                        39,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        40,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        52,
                        53,
                        53,
                        53,
                        53,
                        53,
                        53,
                        53,
                        53,
                        54,
                        54,
                        62,
                        62,
                        62,
                        62,
                        62,
                        62,
                        62,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        63,
                        64,
                        64,
                        64,
                        64,
                        64,
                        64,
                        64,
                        64,
                        65,
                        65,
                        65,
                        65,
                        65,
                        66,
                        66,
                        66,
                        66,
                        66,
                        66,
                        66,
                        67,
                        67,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        68,
                        69,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        75,
                        78,
                        79,
                        82,
                        83,
                        91,
                        91,
                        91,
                        93,
                        93,
                        93,
                        141,
                        171,
                        175,
                        175,
                        179,
                        207,
                        207,
                        207,
                        208,
                        228,
                        228,
                        228,
                        228,
                        247,
                        247,
                        298,
                        357,
                        357,
                        357,
                        357,
                        357,
                        387,
                        432,
                        432,
                        432,
                        432,
                        432,
                        539,
                        649,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        684,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        686,
                        765,
                        765,
                        765,
                        765,
                        765,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775,
                        775
                    ],
                    backgroundColor: "#02b5a0 #b4f0e9",
                    alphaArea: 0.5,
                    lineColor: "#027366",
                    marker: {
                        backgroundColor: "#027366",
                        borderWidth: 1
                    }
                }],
            }
        }
    }

    render() {
        return (
            <div>
                <ZingChart data={this.state.config}/>
            </div>
        );
    }

}


export default Chart3;