import '../App.css';
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Crypto-Safety-Tips.png";
import CryptoSafetyTipsImage from "../images/25. Tips/Crypto-Safety-Tips.webp";
//import arrowUp from "../images/arrow-up.svg";
import Arrow from "../images/arrow.svg";


function CryptoSafetyTips() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Crypto Safety Tips - In an Online Environment | safex.org</title>
        <meta name="description" content="Keep your data, coins, wallets, and private information safe and secure while using the Internet. Follow our tips, according to your needs, and stay safe." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/crypto-safety-tips" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Crypto Safety Tips <span>Securing Your Coins Online</span></h1>
                <p>Keep your data, coins, wallets, and private information safe and secure while using the Internet. Below are some tips for you to follow, according to your needs.</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={CryptoSafetyTipsImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="level-section level-section-first">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">1 <span>LEVEL</span></p>
              <p className="d-none d-md-block">LEVEL 1</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Basic General suggestions </h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="level-body">
              <ul>
                <li>Set strong passwords on your devices: computer, tablet, phone.</li>
                <li>Use a different password for each online account - don’t reuse passwords.</li>
                <li>Make sure the password is long enough and does not contain phrases that could be related to you such as a username.</li>
                <li>Before sending coins to anyone, make sure the address is correct and valid, and that it is the address to the real service.</li>
                <li>Do not make payments with a bank card on which you keep all your money.</li>
                <li>Don’t use someone else’s WiFi if you don’t have to (If you absolutely need to, then use a VPN to encrypt your outgoing data.)</li>
                <li>Do not log in to accounts using devices that are not yours.</li>
                <li>Do regular software updates.</li>
                <li>Do not download files from suspicious sites, always check twice before opening any file received no matter how urgent it seems.</li>
                <li>Avoid sharing personal details on social media, discern what you show online in public.</li>
                <li>Do not enter your location data when using social networks.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="level-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">2 <span>LEVEL</span></p>
              <p className="d-none d-md-block">LEVEL 2</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Advanced General suggestions </h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="level-body">
              <ul>
                <li>If you have the ability, use a reliable VPN, especially when not on your home network.</li>
                <li>Use Firefox in preference to Chrome or Edge.</li>
                <li>Use Linux rather than Windows.</li>
                <li>Use decentralized online marketplaces rather than Amazon.</li>
                <li>Use a reliable Password Manager.</li>
                <li>Use services that use encryption and are recognized by the community.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="level-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">3 <span>LEVEL</span></p>
              <p className="d-none d-md-block">LEVEL 3</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Cryptocurrency exchange tips</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="level-body">
              <ul>
                <li>Always remove your coins from the exchange if you are not trading.</li>
                <li>Set 2FA and use SMS OTCs, when available.</li>
                <li>Set email confirmation for withdraws.</li>
                <li>Use trustworthy exchanges.</li>
                <li>Use Market buys for fast coin purchases / use Limit Orders for better value purchases.</li>
                <li>Always check your decimal places - you don’t want to become a Fat Finger statistic. (Buy/Sell for multiples above/below the going rate).</li>
                <li>Check the withdrawal security practices and hot wallet policies of the exchange. Not all exchanges are created equal.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="level-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">4. <span>LEVEL</span></p>
              <p className="d-none d-md-block">LEVEL 4</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal"><div className="tablet-level-title">Crypto Tips</div><div className="desktop-level-title">If you have Safex or some other crypto coins, then pay special attention to</div></h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="level-body">
              <ul>
                <li>Keep your backups of private keys and seed phrases in a safe place, ideally in multiple locations that you personally control.</li>
                <li>Encrypt any backups that are stored outside of your personal possession (eg. Bank Security Box).</li>
                <li>Use strong passwords (preferably 21+ characters) always, particularly on crypto exchanges and wallets.</li>
                <li>Don’t leave your coins on the exchanges unnecessarily, if not actively trading them.</li>
                <li>In cryptocurrency, as in life, DYOR - Do Your Own Research.</li>
                <li>Security on Crypto Exchanges - Always use 2-factor authentication (2FA) and SMS One-Time Codes (OTCs), when offered/available, and enable email confirmation for when you’re withdrawing funds to your wallet.</li>
                <li>Keep yourself educated about online scams and scammer techniques, and remain vigilant.</li>
                <li>Always check website addresses are correct, and you haven’t mis-typed and mistakenly gone to a scammers lookalike site.</li>
                <li>Be wary of crypto community members sharing links, asking for private keys or seed phrases, or promoting other projects via private messages - report suspicious community member behavior to project admins.</li>
                <li>Do not click links sent to you by unknown members of the community nor open suspicious emails.</li>
                <li>Never, ever send private keys or seed phrases to anyone, even a crypto exchange or community friend. Not even to a project admin. There is never a need to give that information to another person.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="level-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">5. <span>LEVEL</span></p>
              <p className="d-none d-md-block">LEVEL 5</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">Advanced Crypto Tips </h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="level-body">
              <ul>
                <li>Keeping your backups in a 2-hr Fire Retardant Safe, in case of a house fire.</li>
                <li>Crypto-Steels for indestructible backups.</li>
                <li>Security When Traveling: use Encrypted USB Drives.</li>
                <li>Laptop tweaks for making your crypto data less visible, particularly when traveling.</li>
                <li>CryptoSteganography techniques for hiding your data in plain sight.</li>
                <li>Estate Planning for your crypto - Shamir's Secret Sharing Scheme (SSSS).</li>
              </ul>
              <a href="https://safex.org/blog/crypto-security/" target="_blank" rel="noreferrer" className="btn-arrow">
                Visit our blog and read more about Cyber security
                <img src={Arrow} alt=""/>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CryptoSafetyTips;
