import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Fondation.png";
import SafexFoundationImage from "../images/13. Safex Foundation/Safex-Foundation.webp";
import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function SafexFoundation() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
      <title>Safex Foundation 501c3 | safex.org</title>
        <meta name="description" content="Safex Foundation is a Non-Profit Corporation that supports free commerce, the privacy of information, open-source software, and community-driven projects" />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-foundation"/>
      </Helmet>
      <section className="hero-2-cols foundation-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Safex Foundation <span>Fostering Innovation of Crypto and Blockchain</span></h1>
                <p>Safex Foundation is a Domestic Non-Profit Corporation (Wyoming, File # 2020-000897721) established in January 2020. On 20 July 2020, Safex Foundation announced it had received 501c3 tax exemption status.</p>
                <Link to="/donation" className="btn-arrow">
                  Support the future with your donation
                  <img src={Arrow} alt=""/>
                </Link>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexFoundationImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="philosophy-section">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div>
                <h2 className="mb-3">Our philosophy:</h2>
                <p>We believe in and support free commerce, the privacy of information, open-source software, community-driven projects. We support individuals and groups starting with crypto-commerce and spreading the word about it.</p>
                
                <h2 className="mb-3 mt-4">Our vision and mission:</h2>
                <p>Vision of the Foundation: Connecting the world through cryptocurrency and blockchain technological innovations that improve our ability to communicate, conduct commerce, manage energy to increase human quality of life.</p>
                <p>Mission Statement: Introduce practical examples of the fundamental concepts of blockchain and cryptocurrency technology. Educating the public and institutions about mining, blockchain consensus, wallet management, cryptocurrency commerce, community inclusion and outreach.</p>
                <p>Safex Foundation is supporting the development and growth of the Safex ecosystem.</p>
              
                <h2 className="mb-3 mt-4">The Foundation currently supports:</h2>
                <ul>
                  <li>The Safex Discussion Forum (forum.safex.org),</li>
                  <li>A 0% fee mining pool (pool.safex.org),</li>
                  <li>Safex Blog,</li>
                  <li>Price Peg facilities for the Safex Market,</li>
                  <li>Educational events and videos,</li>
                  <li>New companies onboarding the Safex technologies.</li>
                </ul>

                <div className="d-none d-md-block">
                  <h2 className="mb-3 mt-4">The Foundation will also be supporting:</h2>
                  <ul>
                    <li>A Safex Account Faucet Program where future merchants can apply and obtain a free merchant account.</li>
                    <li>Community members can suggest new features to be implemented. They can also donate funds to accelerate development</li>
                  </ul>
                </div>

                <h2 className="mb-3 mt-4">Donations:</h2>
                <p>Funds can be donated to Safex Foundation. <br/> You can make a donation in Safex Cash, Safex Token, Wrapped Safex Cash and Bitcoin.</p>

                <Link to="/donation" className="btn-arrow">
                  Make a donation
                  <img src={Arrow} alt=""/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="foundation-section">
        <div className="container">
          <div className="row">
            <div className="form-wrap">
              <form>
                <h3 className="text-center"><span className="color-blue">We Want To Hear</span> From You</h3>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="name"
                    className="form-control"
                    id="name"
                    aria-describedby="name"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="email"
                    placeholder="example@yourdomain.com"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Your message</label>
                  <textarea class="form-control" id="message" rows="5" placeholder="Hi there..."></textarea>
                </div>
                <button type="submit" className="btn btn-primary m-auto mt-3">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SafexFoundation;