import '../App.css';
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Coding-on-Safex.png";
import CodingonSafexImage from "../images/21. Coding on Safex/Coding-on-Safex.webp";
import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function CodingonSafex() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coding on Safex - Contributing to the Open Source | safex.org</title>
        <meta name="description" content="Safex covers many themes in software engineering: application development, SDKs, peer-to-peer networks, cryptography, among other high-tech themes." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/coding-on-safex" />
      </Helmet>
      <section className="hero-2-cols coding-on-safex-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 order-2 order-md-1">
              <div className="hero-text">
                <h1>Coding on Safex <span>Contributing to the Open Source</span></h1>
                <p>Safex is an open-source software that is hosted on github. It covers many themes in software engineering: application development, SDKs, peer-to-peer networks, cryptography, among other high-tech themes.</p>
                <p>Below are helpful links to help you get started with contributing to the core source code and documentation to start working with the APIs and SDKs to plug you into The World Marketplace application.</p>
                <div className="coding-on-safex-links">
                  <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-2">
                    Safex Github
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://github.com/safex/safexcore" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-2">
                    Safexcore github
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://github.com/safex/safexcore" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-2">
                    Safexcore docs
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://github.com/safex/safexcore/wiki/wallet-rpc" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-2">
                    Wallet api
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://github.com/safex/safexcore/wiki/daemon-rpc" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-2">
                    Daemon api
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href="https://github.com/safex/archive/blob/master/SafexBluePaperJan3.pdf" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-0">
                    Blue Paper
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
                <h3 className="mb-3">Work for a business in Safex</h3>
                <p>You can apply for the open positions at the Safex Market company that is innovating by using all of the open source software to bring the world online to the Safex blockchain and get involved in a new way to conduct eCommerce:</p>
                <a href="https://safex.market/jobs" target="_blank" rel="noreferrer" className="btn-arrow mt-0 mb-4">
                  Safex Market Jobs
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={CodingonSafexImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CodingonSafex;
