import '../App.css';
import { Link } from "react-router-dom";

import {HashLink} from "react-router-hash-link";

import logo from "../images/Safex-Logo.png";

import SearchBar from "./Search";
import BookData from "./Data.json";

const Nav = () =>{

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="" />
        </Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <svg
            width={26}
            height={23}
            viewBox="0 0 26 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.64286C0 1.20714 0.171205 0.789278 0.475952 0.481182C0.780698 0.173087 1.19402 0 1.625 0H24.375C24.806 0 25.2193 0.173087 25.524 0.481182C25.8288 0.789278 26 1.20714 26 1.64286C26 2.07857 25.8288 2.49644 25.524 2.80453C25.2193 3.11263 24.806 3.28571 24.375 3.28571H1.625C1.19402 3.28571 0.780698 3.11263 0.475952 2.80453C0.171205 2.49644 0 2.07857 0 1.64286Z"
              fill="#13D3FD"
            />
            <path
              d="M0 21.3567C0 20.921 0.171205 20.5031 0.475952 20.195C0.780698 19.887 1.19402 19.7139 1.625 19.7139H24.375C24.806 19.7139 25.2193 19.887 25.524 20.195C25.8288 20.5031 26 20.921 26 21.3567C26 21.7924 25.8288 22.2103 25.524 22.5184C25.2193 22.8265 24.806 22.9996 24.375 22.9996H1.625C1.19402 22.9996 0.780698 22.8265 0.475952 22.5184C0.171205 22.2103 0 21.7924 0 21.3567Z"
              fill="#13D3FD"
            />
            <path
              d="M11.375 9.85742C10.944 9.85742 10.5307 10.0305 10.226 10.3386C9.92121 10.6467 9.75 11.0646 9.75 11.5003C9.75 11.936 9.92121 12.3539 10.226 12.662C10.5307 12.97 10.944 13.1431 11.375 13.1431H24.375C24.806 13.1431 25.2193 12.97 25.524 12.662C25.8288 12.3539 26 11.936 26 11.5003C26 11.0646 25.8288 10.6467 25.524 10.3386C25.2193 10.0305 24.806 9.85742 24.375 9.85742H11.375Z"
              fill="#13D3FD"
            />
          </svg>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <Link to="/" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Use Safex</Link>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/wallet" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex TWM Wallet</div></Link>
                </li>
                <li>
                  <Link to="/buy-safex-coins" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Buy Safex Coins</div></Link>
                </li>
                <li>
                  <Link to="/buy-safex-cash" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Buy Safex Cash</div></Link>
                </li>
                <li>
                  <Link to="/buy-safex-token" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Buy Safex Token</div></Link>
                </li>
                <li>
                  <Link to="/shopping-on-safex-ecommerce-market" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Shopping on Safex Market</div></Link>
                </li>
                <li>
                  <Link to="/mining-safex-cash" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mine Safex Cash</div></Link>
                </li>
                <li>
                  <Link to="/staking-safex-tokens" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Stake Safex Token</div></Link>
                </li>
                <li>
                  <Link to="/run-safex-node" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Run Safex Node</div></Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link to="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Learn</Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/what-is-safex" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">What is Safex?</div></Link>
                </li>
                <li>
                  <Link to="/how-safex-works" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">How Safex Works?</div></Link>
                </li>
                <li>
                  <Link to="/safex-privacy-blockchain" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Privacy Features</div></Link>
                </li>
                <li>
                  <Link to="/crypto-safety-tips" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Crypto Safety Tips</div></Link>
                </li>
                <li>
                  <Link to="/safex-ecosystem" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Ecosystem</div></Link>
                </li>
                <li>
                  <Link to="/safex-community" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Community Links</div></Link>
                </li>
                <li>
                  <Link to="/coding-on-safex" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Develop on Safex</div></Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link to="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Blog</Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/safex-knowledge-base" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Knowledge Base</div></Link>
                </li>
                <li>
                  <a href="https://safex.org/blog/getting-started/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Getting Started</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/tech-guidebook/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Tech Guidebook</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/ecommerce/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Ecommerce Articles</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/privacy-blockchain/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Privacy</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/blockchain-and-cryptocurrency-basics/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Blockchain & Crypto</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/safex-infographics/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Infographics</div></a>
                </li>
                <li>
                  <a href="https://safex.org/blog/" target="_blank" rel="noreferrer" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Blog</div></a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link to="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Downloads</Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                  <Link to="/wallet" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex TWM Wallet</div></Link>
                </li>
                <li>
                  <HashLink to={{
                    pathname: "/mining-safex-cash",
                    hash: "#one-click-miner-application"
                  }} className="dropdown-item"><div
                   data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Easy Safex Cash Miner</div></HashLink>
                </li>
                <li>
                  <Link to="/coding-on-safex" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Documents</div></Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link to="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">About</Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link to="/mission-and-vision" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Mission and Vision</div></Link>
                </li>
                <li>
                  <Link to="/safex-foundation" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Foundation</div></Link>
                </li>
                <li>
                  <Link to="/promote-safex" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Promote Safex</div></Link>
                </li>
                <li>
                  <Link to="/safex-assets" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Safex Assets</div></Link>
                </li>
                <li>
                  <Link to="/donation" className="dropdown-item"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Donation</div></Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/contact-us" className="nav-link"><div>Contact</div></Link>
            </li>
          </ul>
          <form className="d-flex" onSubmit="return false;">
            <SearchBar data={BookData}/>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
