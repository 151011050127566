import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import NoMatchImage from "../images/18. 404 Page/404.webp";


function NoMatch() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>404 | safex.org</title>
        <meta name="description" content="The page you are looking for could not be found" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-align-center ">
                <h1 className="font-45 d-none d-md-block">The page you are looking for <span className="font-40">could not be found</span></h1>
                <h1 className="font-23 d-md-none">Тhe page you are looking for could not be found</h1>
                <Link to="/" class="btn btn-primary">
                  Go Home
                </Link>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={NoMatchImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NoMatch;