import '../App.css';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import ogImg from "../images/thumbnails/Buy-Safex-Cash.png";
import BuySafexCoinsImage from "../images/5. Buy Safex Coins/Buy-Safex-Coins.webp";
import BuySafexCashSFXonCryptoExchanges from "../images/5. Buy Safex Coins/Buy-Safex-Cash-SFX-on-Crypto-Exchanges.webp";
import BuySafexTokensSFTonXcalibraCryptoCurrencyExchange from "../images/5. Buy Safex Coins/Buy-Safex-Tokens-SFT-on-Xcalibra-CryptoCurrency-Exchange.webp";
//import BuyWrappedSafexCashWSFXonPancakeSwap from "../images/5. Buy Safex Coins/Buy-Wrapped-Safex-Cash-WSFX-on-PancakeSwap.webp";
import BuySafexCoinsSafexWorldMap from "../images/5. Buy Safex Coins/Safex-World-Map.webp";
import arrowUp from "../images/arrow-up.svg";
import Arrow from "../images/arrow.svg";
import {useEffect, useState} from "react";
import axios from "axios";


function BuySafexCoins() {

  window.scrollTo(0, 0);
  const [sft_price, setSftPrice] = useState("0.00");
  const [sfx_price, setSfxPrice] = useState("0.00");
  const get_prices = async () => {
    return axios({
      method: 'get',
      url: 'https://iapi.safex.org/prices/'
    }).then((res) => {
      return res.data;
    })
  }

  useEffect(async () => {
    try {
      let prices = await get_prices();
      setSftPrice(prices.sft.toString());
      setSfxPrice(prices.sfx.toString());
    } catch(err) {
      setSftPrice(0.00.toString());
      setSfxPrice(0.00.toString());
    }
    setInterval(async () => {
      try {
        let prices = await get_prices();
        setSftPrice(prices.sft.toString());
        setSfxPrice(prices.sfx.toString());
      } catch(err) {
        setSftPrice(0.00.toString());
        setSfxPrice(0.00.toString());
      }
    }, 60000 * 1)
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buying Safex Coins Guide $SAFEX | safex.org</title>
        <meta name="description" content="Buy Safex Coins: Safex Tokens (SFT) and Safex Cash (SFX) on cryptocurrency exchanges." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/buy-safex-coins" />
      </Helmet>
      <section className="hero-2-cols buy-safex-cash-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Buy Safex Coins <span>On Cryptocurrency Exchanges</span></h1>
                <p>Safex is a privacy and two coin system consisting of Safex Tokens (SFT) and Safex Cash (SFX). These coins allow you to participate in global decentralized eCommerce.</p>
                <p>Find the coins on <a href="https://xcalibra.com/" target="_blank" rel="noreferrer">Xcalibra.com</a> and <a href="https://www.bitrue.com/home/" target="_blank" rel="noreferrer">Bitrue.com</a>.</p>              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={BuySafexCoinsImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray coins-card-wrap-section">
        <div className="container">
          <div className="row">
            <div className="coins-card-wrap">
              <div className="price-card green">
                <p className="price-text">SAFEX CASH CURRENT PRICE</p>
                <p className="tablet-text">SFX PRICE</p>
                <p className="current-price">${sfx_price}</p>
              </div>
              <div className="price-card blue">
                <p className="price-text">SAFEX TOKEN CURRENT PRICE</p>
                <p className="tablet-text">SFT PRICE</p>
                <p className="current-price">${sft_price}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="card-fluid-wrap-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card-fluid green-linear BuySafexCashSFXonCryptoExchanges">
                <div className="card-fluid-body">
                  <h2>BUY SAFEX CASH (SFX)</h2>
                  <p className="card-fluid-main-text font-18">A mineable Proof-of-Work privacy coin used as the medium of exchange on Safex Market. It has an emission curve that follows the rate of adoption of new technologies. <br/> <Link to="/mining-safex-cash">Learn more about mining Safex Cash.</Link></p>
                  <Link to="/buy-safex-cash" className="btn-arrow">
                    Buy Safex Cash
                    <img src={Arrow} alt=""/>
                  </Link>
                </div>
                <div className="card-fluid-image">
                  <img src={BuySafexCashSFXonCryptoExchanges} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid blue-linear BuySafexTokensSFTonXcalibraCryptoCurrencyExchange">
                <div className="card-fluid-body">
                  <h2>BUY SAFEX TOKENS (SFT)</h2>
                  <p className="card-fluid-main-text font-18">Utility coin for Safex Market that allows users to create merchant accounts, and participate, in revenue sharing by staking the Tokens. <br/> <Link to="/staking-safex-tokens">Learn more about staking Safex Tokens.</Link></p>
                  <Link to="/buy-safex-token" className="btn-arrow">
                    Buy Safex Tokens
                    <img src={Arrow} alt=""/>
                  </Link>
                </div>
                <div className="card-fluid-image">
                  <img src={BuySafexTokensSFTonXcalibraCryptoCurrencyExchange} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="buy-safex-image-section">
        <div>
          <div className="bg-image-fluid bg-image-fluid-m-nomral">
            <img src={BuySafexCoinsSafexWorldMap} className="img-fluid" alt=""/>
          </div>
        </div>
      </section>
      <section className="keep-coins-safe-section">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h2 className="mb-3">KEEP YOUR SAFEX COINS SAFE</h2>
              <p>If you are just about to make an account on a cryptocurrency exchange - set a reliable password and 2FA. Remember to write down the password in a safe place, and don’t share it with anyone.</p>
              <p>Set an email confirmation for withdrawing coins.</p>
              <p>Keep in mind that exchanges do not guarantee the safety of your coins. Therefore, it’s recommended to withdraw your coins to your Safex TWM Wallet. Always download the Wallet from the official website and set a strong password.</p>
              <p>Make sure to save the Private Keys and Mnemonic Seed Phrase of your Wallet so you can restore it, in the case of a corrupted file, your PC dies, or you lose/forget your password and/or .keys file. Backup your private keys and seed, and keep them safe.</p>
              <Link to="/crypto-safety-tips" className="btn-arrow">
                More safety tips
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on our <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Official Safex Forum.</a> <br/>Enjoy entering the Safex Ecosystem.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BuySafexCoins;