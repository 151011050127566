import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import React, {Component} from 'react';

class Chart1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {
                type: 'area',
                backgroundColor: 'transparent',
                title: {
                    'text': 'Cumulative USD Sales',
                    'font-size': '14px'
                },
                height: "300px",
                width: "100%",
                crosshairX: {
                    lineColor: "#565656",
                    lineStyle: "dashed",
                    lineWidth: 2,
                    alpha: 0.5,
                    plotLabel: {
                        backgroundColor: "#ffffff",
                        borderColor: "#d2d2d2",
                        borderRadius: "5px",
                        bold: true,
                        fontSize: "12px",
                        fontColor: "#111",
                        shadow: true,
                        shadowDistance: 2,
                        shadowAlpha: 0.4,
                        text: "%v"
                    },
                    scaleLabel: {
                        bold: true,
                        backgroundColor: "#787878",
                        borderRadius: 3,
                        fontColor: "#eaeaea",
                        fontSize: "12px",
                        callout: true,
                        paddingTop: 2
                    },
                    marker: {
                        visible: false
                    }
                },
                scaleY: {
                    label: {
                        text: "amount --->",
                        offset: 453
                    },
                    short: true,
                    lineColor: 'none',
                    tick: {
                        visible: true
                    },
                    guide: {
                        visible: true
                    },
                    item: {
                        visible: true
                    }
                },
                scaleX: {
                    label: {
                        text: "days  --->"
                    },
                    short: true
                },
                series: [{
                    values: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        22.71,
                        22.71,
                        22.71,
                        22.71,
                        22.71,
                        22.71,
                        31.29,
                        32.12,
                        32.12,
                        32.12,
                        32.12,
                        32.12,
                        32.12,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        79.28,
                        182.46,
                        182.46,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        198.8,
                        379.91,
                        379.91,
                        415.97,
                        415.97,
                        415.97,
                        415.97,
                        743.46,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        780.44,
                        1285.8000000000002,
                        1407.7800000000002,
                        1407.7800000000002,
                        1407.7800000000002,
                        1407.7800000000002,
                        1407.7800000000002,
                        1407.7800000000002,
                        1437.13,
                        1549.71,
                        1791.01,
                        2118.8,
                        2118.8,
                        2118.8,
                        2118.8,
                        2118.8,
                        2118.8,
                        2523.3900000000003,
                        2523.3900000000003,
                        2523.3900000000003,
                        2523.3900000000003,
                        2562.4400000000005,
                        2689.4900000000007,
                        2689.4900000000007,
                        2689.4900000000007,
                        2689.4900000000007,
                        2689.4900000000007,
                        2689.4900000000007,
                        2689.4900000000007,
                        3030.9000000000005,
                        3079.9500000000007,
                        3808.2400000000007,
                        4275.860000000001,
                        4555.2300000000005,
                        4691.51,
                        4691.51,
                        4913.62,
                        4913.62,
                        4913.62,
                        4913.62,
                        4913.62,
                        4913.62,
                        4913.62,
                        4931.36,
                        4931.36,
                        4948.12,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5532.63,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        5804.52,
                        6136.790000000001,
                        6136.790000000001,
                        6136.790000000001,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        6529.02,
                        7472.820000000001,
                        7472.820000000001,
                        7472.820000000001,
                        7472.820000000001,
                        7472.820000000001,
                        7472.820000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        8232.230000000001,
                        9172.12,
                        9172.12,
                        9522.890000000001,
                        9522.890000000001,
                        9522.890000000001,
                        9522.890000000001,
                        9522.890000000001,
                        9522.890000000001,
                        9522.890000000001,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        9832.94,
                        10740.130000000001,
                        10740.130000000001,
                        10740.130000000001,
                        11474.35,
                        12350.79,
                        12350.79,
                        12350.79,
                        12854.970000000001,
                        12854.970000000001,
                        14801.830000000002,
                        16023.680000000002,
                        18245.320000000003,
                        18245.320000000003,
                        18741.500000000004,
                        18741.500000000004,
                        18741.500000000004,
                        18741.500000000004,
                        18741.500000000004,
                        19706.180000000004,
                        19706.180000000004,
                        19706.200000000004,
                        19987.580000000005,
                        19987.600000000006,
                        19987.600000000006,
                        19987.620000000006,
                        19987.660000000007,
                        19987.660000000007,
                        19987.680000000008,
                        19987.680000000008,
                        19987.680000000008,
                        19987.680000000008,
                        19987.680000000008,
                        20040.690000000006,
                        20171.000000000007,
                        20649.90000000001,
                        20716.53000000001,
                        20716.53000000001,
                        21087.67000000001,
                        21490.32000000001,
                        21490.32000000001,
                        21543.82000000001,
                        21978.44000000001,
                        21978.44000000001,
                        22314.63000000001,
                        22414.19000000001,
                        22521.950000000008,
                        22521.950000000008,
                        22566.110000000008,
                        22566.110000000008,
                        24017.360000000008,
                        24052.680000000008,
                        24052.680000000008,
                        24052.690000000006,
                        24141.300000000007,
                        24141.300000000007,
                        24453.000000000007,
                        24453.000000000007,
                        24453.000000000007,
                        24774.74000000001,
                        24774.74000000001,
                        25051.23000000001,
                        25150.02000000001,
                        25550.330000000013,
                        25627.330000000013,
                        25661.70000000001,
                        25737.09000000001,
                        25737.09000000001,
                        25737.09000000001,
                        25944.470000000012,
                        26001.800000000014,
                        26307.770000000015,
                        26307.770000000015,
                        26439.350000000017,
                        26523.080000000016,
                        27032.480000000018,
                        27361.830000000016,
                        27572.520000000015,
                        27572.520000000015,
                        28738.400000000016,
                        28798.160000000014,
                        28798.160000000014,
                        28900.720000000016,
                        28992.790000000015,
                        29065.090000000015,
                        29065.090000000015,
                        29124.140000000014,
                        29124.140000000014,
                        29211.490000000013,
                        29316.02000000001,
                        29346.16000000001,
                        29610.36000000001,
                        29610.36000000001,
                        29610.36000000001,
                        29662.53000000001,
                        29821.69000000001,
                        29861.43000000001,
                        29861.43000000001,
                        29861.43000000001,
                        29907.220000000012,
                        29907.220000000012,
                        29907.220000000012,
                        30609.68000000001,
                        30609.68000000001,
                        30670.46000000001,
                        30797.66000000001,
                        30797.66000000001,
                        30797.66000000001,
                        30923.98000000001,
                        31005.35000000001,
                        31005.35000000001,
                        31005.35000000001,
                        31196.28000000001,
                        31196.28000000001,
                        31196.28000000001,
                        31196.28000000001,
                        31196.28000000001,
                        31291.42000000001,
                        31291.42000000001,
                        31291.42000000001,
                        31291.42000000001,
                        31291.42000000001,
                        31291.42000000001,
                        31355.37000000001,
                        31355.37000000001,
                        31355.37000000001,
                        31355.37000000001,
                        31355.37000000001,
                        31355.37000000001,
                        31382.46000000001,
                        31382.46000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31499.33000000001,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31545.040000000008,
                        31688.22000000001,
                        31688.22000000001,
                        31688.22000000001,
                        31688.22000000001,
                        31688.22000000001,
                        31688.22000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31852.37000000001,
                        31880.56000000001,
                        31880.56000000001,
                        31880.56000000001,
                        31880.56000000001,
                        31880.56000000001,
                        31880.56000000001,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32009.110000000008,
                        32785.04000000001,
                        33540.810000000005,
                        33540.810000000005,
                        33815.00000000001,
                        33840.23000000001,
                        34234.43000000001,
                        34584.23000000001,
                        34633.24000000001,
                        35022.20000000001,
                        35022.20000000001,
                        35091.81000000001,
                        35091.81000000001,
                        35091.81000000001,
                        35091.81000000001,
                        35104.91000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35118.25000000001,
                        35438.280000000006,
                        35438.280000000006,
                        35438.280000000006,
                        35779.350000000006,
                        35779.350000000006,
                        35779.350000000006,
                        35858.68000000001,
                        35858.68000000001,
                        35871.18000000001
                    ], backgroundColor: "#cc0685 #f78fd2",
                    alphaArea: 0.5,
                    lineColor: "#b50088",
                    marker: {
                        backgroundColor: "#b50088",
                        borderWidth: 1
                    }
                }]
            }
        }
    }

    async componentDidMount() {
        let the_data = await this.get_volume_usd();
        console.log(the_data);
        this.setState({config: the_data});
    }

    get_volume_usd = async () => {
        var headers = {};
        return fetch(`https://safex.ninja/charts/light/vol_volume_usd-all.json`, {
            method: "GET",
            mode: 'cors',
            headers: headers
        });
        /* return axios({
           method: `get`,
           url: `https://safex.ninja/charts/light/vol_volume_usd-all.json`,
           headers: {"Access-Control-Allow-Origin": "*"},
           withCredentials: false
         }).then((res) => {
           return res.data;
         })*/
    }


    render() {
        return (
            <div>
                <ZingChart data={this.state.config}/>
            </div>
        );
    }

}


export default Chart1;