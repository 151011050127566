import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Mission-and-Vision.png";
import SafexMissionandVision from "../images/12. Mission and vision/Safex-Mission-and-Vision-.webp";
import MissionandVisionSellItemsonSafexMarket from "../images/12. Mission and vision/Become-a-Merchant.webp";
import MissionandVisionBuyItemsonSafexMarket from "../images/12. Mission and vision/Shopping-on-Safex-Marketplace.webp";
import Arrow from "../images/arrow.svg";


function MissionAndVision() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mission and Vision | safex.org</title>
        <meta name="description" content="Safex is dedicated and committed to connecting the world through cryptocurrency and blockchain technology." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/mission-and-vision" />
      </Helmet>
      <section className="hero-2-cols mission-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Mission and Vision <span>Of Safex and Safex Foundation</span></h1>
                <p>Safex is an open-source software project. <br/> Safex is also a peer to peer network. <br/> Safex is like Amazon, except it's decentralized, and crypto. <br/> Like Bitcoin, except Safex is private, inclusive, and useful.  <br/> The Safex Foundation is here to further the Safex Project. <br/></p>
                <Link to="/donation" className="btn-arrow">
                  Support the future with your donation
                  <img src={Arrow} alt=""/>
                </Link>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexMissionandVision} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="vision-section">
        <div className="container">
          <div className="row">
            <div className="vision-col">
              <div className="card-mobile">
                <h2 className="mb-0 fw-normal">Vision</h2>
                <p className="font-16">CONNECTING THE WORLD THROUGH CRYPTOCURRENCY AND BLOCKCHAIN TECHNOLOGY</p>
                <p>The eCommerce industry is constantly growing and will continue to do so in the future. <br/> The popularity of cryptocurrencies is growing exponentially. <br/> Secure, private, reliable, and decentralized solutions are needed, now, more than ever.</p>
                <p>The Safex Foundation sees the future of commerce and culture being propelled by cryptocurrency and blockchain technological innovations. We believe that they are improving our ability to communicate, conduct trade, and manage energy that increases human quality of life.</p>
              </div>
              <div className="card-mobile">
                <h2 className="mb-0 fw-normal">Mission</h2>
                <p className="font-16">DEDICATED AND COMMITTED</p>
                <p>We pursue practical examples of the fundamental concepts of blockchain and cryptocurrency technology. We educate the public and institutions about mining, blockchain consensus, wallet management, cryptocurrency commerce, community inclusion and outreach.</p>
                <p>As Safex Market is constantly developing, we expect future software updates and improvements to continue to enhancing the human experience.</p>
              </div>
            </div>
            <div className="roadmap-col">
              <h2 className="mb-0 fw-normal">Road Map</h2>
              <ul className="road-map">
                  <li className="check">Safex formation (2015)</li>
                  <li className="check">Planning and organizing</li>
                  <li className="check">Blue Paper published (development begins 2017)</li>
                  <li className="check">Safex Blockchain: Safex Cash mining begins (September 2018)</li>
                  <li className="check">Marketplace testing (2019 - 2020)</li>
                  <li className="check">Safex Marketplace Launch (Dec 23, 2020)</li>
                  <li className="check">TWM Wallet Published (April 20, 2021)</li>
                  <li className="check">Scaling and Growth ($5m in products Dec 2021)</li>
                  <li>Scaling onboarding v2 TWM Wallet</li>
                  <li>Scaling onboarding 3rd party platforms</li>
                  <li>Innovation, Developing new Safex Applications (2023-Onward)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="opportunity-section">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="card-on-mobile">
                <h2 className="mb-0 fw-normal">Opportunity</h2>
                <p className="font-16">AN OPEN NETWORK ESTABLISHING THE WORLD’S CATALOG</p>
                <p>Safex is an open source downloadable software that establishes a network and an openly obtainable cryptocurrency through mining. The Safex ecosystem has always been developed through an open online community from contributions by many around the globe. The Safex Foundation is here to be an entity fostering open and decentralized networks to help move the world forward.</p>
                <p>The Safex Market is one of the initial important applications within the Safex ecosystem, and it establishes a catalog based on the input of all people who use it. Privacy is there to protect people, their businesses, and their finances while on the internet. Privacy in crypto is the same as SSL with emails, it is vital to normal everyday usage. Safex Market is inclusive, and enables even the most distant tribes to come online and start interacting in culture and trade with others around the globe. All you need is the Safex TWM Wallet and an internet connection.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="card-with-image-section">
        <div className="container">
          <div className="row">
            <div className="card-with-image-wrap space-around">
              <Link to="/coding-on-safex" className="card-with-image">
                <div className="card-top DevelopingonSafex">
                  <img src={MissionandVisionBuyItemsonSafexMarket} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>DEVELOPING ON <span>SAFEX<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex Market is the first app on Safex. Explore the possibilities of developing further on Safex.</p>
                </div>
              </Link>
              <a href="https://safex.market/" target="_blank" rel="noreferrer" className="card-with-image SellProductsonSafexMarket">
                <div className="card-top">
                  <img src={MissionandVisionSellItemsonSafexMarket} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>SELLING ON SAFEX <span>MARKET<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Learn about earning cryptocurrency, Safex Cash by selling products on Safex Market.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MissionAndVision;