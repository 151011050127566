import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Buy-Safex-Tokens-SFT.png";
import BuySafexTokenSFT from "../images/4. Buy Safex Token/Buy-Safex-Token-SFT.webp";
import BuySafexTokenSFTMonument from "../images/4. Buy Safex Token/Buy-Safex-Token-SFT-Monument.webp";
import xcalibra from "../images/xcalibra.svg";
import arrowUp from "../images/arrow-up.svg";
import Arrow from "../images/arrow.svg";
import {useEffect, useState} from "react";
import axios from "axios";

//import SafexokenPrice from "../components/Safex-token-price"


function BuySafexTokens() {

  window.scrollTo(0, 0);
  const [sft_price, setSftPrice] = useState("0.00");
  const get_sft= async () => {
    return axios({
      method: 'get',
      url: 'https://iapi.safex.org/prices/'
    }).then((res) => {
      return res.data.sft;
    })
  }

  useEffect(async () => {
    try {
      let the_sft = await get_sft();
      setSftPrice(the_sft.toString());
    } catch(err) {
      console.log(err);
      setSftPrice(0.00.toString());
    }
    setInterval(async () => {
      try {
        let the_sft = await get_sft();
        setSftPrice(the_sft.toString());
      } catch(err) {
        setSftPrice(0.00);
      }
    }, 60000 * 1)
  }, []);
  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy Safex Token (SFT) | safex.org</title>
        <meta name="description" content="Get Safex Tokens on exchanges, stake them and earn passive income from sales on Safex Market." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/buy-safex-token" />
      </Helmet>
      <section className="hero-2-cols buy-safex-cash-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Buy Safex Token (SFT) <span>On Cryptocurrency Exchange</span></h1>
                <p>Get Safex Token (SFT) on Xcalibra.com crypto exchange.</p>
                <p>Xcalibra is the right choice for buying Safex Token. Here is the list of states and 150+ countries where Xcalibra is available:</p>
                <a href="https://xcalibra.com/countries-list" target="_blank" rel="noreferrer" className="btn-arrow">
                  Xcalibra.com List
                  <img src={Arrow} alt=""/>
                </a>
                <div>
                </div>
                <div className="price-card blue">
                  <p className="price-text">SAFEX TOKEN CURRENT PRICE</p>
                  <p className="current-price">${sft_price}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={BuySafexTokenSFT} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section step-section-first">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">1. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 1</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">INSTALL SAFEX WALLET</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Get prepared for Token purchases by installing Safex TWM Wallet. <br/> Get the Wallet and follow the <a href="https://www.youtube.com/watch?v=GFLY7K84ii4" target="_blank" rel="noreferrer">video guide.</a></p>
              <Link to="/wallet" target="_blank" rel="noreferrer" className="btn-arrow">
                Download the Wallet
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">2. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 2</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">BUY SAFEX TOKENS ON XCALIBRA.COM</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <h2 className="fw-normal">How to buy Safex Tokens on Xcalibra exchange?</h2>
              <ol>
                <li>Make an account on Xcalibra or log in</li>
                <li>Buy Bitcoin with a card (if needed)</li>
                <li>Exchange Bitcoin for Safex Tokens</li>
                <li>Withdraw Safex Tokens to the Safex TWM Wallet</li>
              </ol>
              <a href="https://trade.xcalibra.com/exchange/SFT_BTC" target="_blank" rel="noreferrer" class="btn btn-primary">
                <img src={xcalibra} alt=""/>
                Visit Xcalibra
              </a>
              <iframe className="video" src="https://www.youtube.com/embed/HpjvdBg7bMI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">3. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 3</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">STAKE YOUR SAFEX TOKENS</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Now, when you have your Safex Tokens, you can stake them and receive passive income. The Safex Blockchain distributes the 5% fee on sales to eligible Staked Safex Tokens each 1,000 block interval. Although the revenue share per interval varies, the payout increases the longer the user keeps their Tokens staked. Follow a video guide.</p>
              <Link to="/staking-safex-tokens" className="btn-arrow">
                Stake Tokens
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">4. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 4</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">SHARE YOUR EXPERIENCE WITH FRIENDS</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Share your experience on your social media accounts or forums. <br/> Make a video of your purchase, and we will be pleased to reshare it. <br/> Thank you for being part of the Safex Ecosystem!</p>
              <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="btn-arrow">
                Twitter
                <img src={Arrow} alt=""/>
              </a>
              <div className="step-image image-mb-full">
                <img src={BuySafexTokenSFTMonument} className="img-fluid" alt=""/>
              </div>
              <div className="row step-end">
                <h2>Choose an exchange for you based on where you are from.</h2>
                <div className="col-md-6">
                  <div className="step-end-text-left">
                    <p>Xcalibra is available in 150+ countries and territories and supports 15 national currencies. See if your country is on the list and trade at Xcalibra exchange.</p>
                    <p>National currency list: <br/>United States dollar, Euro, British pound, Danish krone, Czech koruna, Hungarian forint, Russian ruble, Australian dollar, Japanese yen, Norwegian krone, Polish złoty, New Zealand dollar,  Canadian dollar, Swiss franc, Swedish krona, Turkish lira, South African rand, Israeli new shekel.</p>
                    <a href="https://xcalibra.com/countries-list" target="_blank" rel="noreferrer" className="btn-arrow">
                      Find a full list here
                      <img src={Arrow} alt=""/>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on our <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Official Safex Forum.</a> <br/>Enjoy entering the Safex Ecosystem.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BuySafexTokens;
