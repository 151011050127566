import React, { useState } from "react";
import { Link } from "react-router-dom";

function SearchBar({ placeholder, data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className="search">
      <div className="searchInputs">
        <div className="input-group search-input">
            <input
                type="text"
                className="form-control"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={wordEntered}
                onChange={handleFilter}
            />
            <button className="btn" type="button" id="button-addon2">
              <svg
              width={12}
              height={14}
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              >
              <rect y="0.0341797" width={12} height={13} fill="url(#pattern0)" />
              <defs>
                  <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width={1}
                  height={1}
                  >
                  <use
                      xlinkHref="#image0_3425_272"
                      transform="translate(-0.00154321) scale(0.0200617 0.0185185)"
                  />
                  </pattern>
                  <image
                  id="image0_3425_272"
                  width={50}
                  height={54}
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAA2CAYAAACFrsqnAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAe9JREFUeNrsWO1xhCAQJanADkIJdnCmglwJlmA6sAPTASU4V4G5CkwHmgq8Di44sz8cR0BwF9HwZt6P3ETg7RfLMhYRFl4cv0skM8mLZKr4n7vkNzA4jIevJZ8WHCSFJA9FQGMpYIkCvLkLSgQBcw9dfeZIAhY0bTrmQC/5C39f4NvU8N2n5Be1F8aDtBqrjmGWG8JkzIlCstOsU1ELEYqNO8gXWxQQUktr5lQiCqJETTVeTrFFcIXlBHHItj5CqibIv4EyxLiiVFLU/UyRfyiofCaiokPIMBbuqCykSX70csz3qPELie9svNeJdea4eRByWzAouhAf7fePohA4C5mj99SQPrAW2lsIoxaSnEVIelQh/U5ispUFYLUQtOphiY+F3NxcAAbqjnTFJYzSZQuq3sdiP5S3fOrjnaDZa8DcoPHQc6keVyX1OwG7nVfNAzi22yvFRgWCJ3RTypbiIm41YyAXy10NYyEyMaa5llhxYSYQkrajVicxpkljYzjwAy7T++S3N/DaltI9rvmO2R2b4pqSJDmjmxTaHm53MQnU+s5BQDMp4XkIYqb3TQUHHBQzqho8uVTlghKzFVFMFBPFRDH/U0zJAoaNmI4FDhsx7AxiWnYQ5EfOkbViBDsg+KRRFczPJDQi4jTtyJ8AAwDk7RbGv8Y7kAAAAABJRU5ErkJggg=="
                  />
              </defs>
              </svg>
            </button>
        </div>
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <div />
          ) : (
            <div id="clearBtn" onClick={clearInput}/>
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <Link className="dataItem" onClick={clearInput} to={value.link}>
                {value.title}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchBar;