import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ThankYouImage from "../images/17. Thank You/Thank-you.webp";


function ThankYou() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Thank you | safex.org</title>
        <meta name="description" content="Do you have a proposal, idea, or thought you would like to share with us? Send us a message. Let’s keep in touch!" />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/thank-you"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-align-center">
                <h1 className="font-45 d-none d-md-block">Thank you <span className="font-40">for your trust!</span></h1>
                <h1 className="font-45 d-md-none">Keep in touch <span className="font-40">we want to hear from you</span></h1>
                <Link to="/" class="btn btn-primary">
                  Go Home
                </Link>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={ThankYouImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ThankYou;