import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Community.png";
import SafexCommunityimage from "../images/14. Safex Community/Safex-Community.webp";
import JoinSafexCommunity from "../images/14. Safex Community/Safex-Community-Links.webp";
import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function SafexCommunity() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Join the Safex Community | safex.org</title>
        <meta name="description" content="The Safex Community is the driving force of the Safex Market. We gather merchants, shoppers, crypto enthusiasts, miners, traders from all over the world. Join us!" />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-community"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Safex Community <span>Welcome to the Wolf Pack</span></h1>
                <p>The Safex Community is the engine room of the Safex Market. <br/> We gather merchants, shoppers, crypto enthusiasts, miners, traders from all over the world.</p>
                <a href="#community" className="btn-arrow">
                  Join our online communities
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexCommunityimage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="community-link-section" id="community">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="mb-4">ONLINE COMMUNITIES</h1>

              <h2 className="mb-0">Websites:</h2>
              <p className="mb-0"><a href="https://safex.org/" className="link-dark" target="_blank" rel="noreferrer">Safex.org</a> - The Official Safex website (you are here)</p>
              <p className="mb-0"><a href="https://safex.market/" className="link-dark" target="_blank" rel="noreferrer">Safex Market</a> - TWM Inc Official</p>
              <p className="mb-0"><a href="https://safex.ninja/" className="link-dark" target="_blank" rel="noreferrer">Safex Ninja</a> - Safex Market explorer</p>
              <p className="mb-0"><a href="https://safex-offers.herokuapp.com/safex/offers" className="link-dark" target="_blank" rel="noreferrer">Safex Offers</a> - Safex Market explorer</p>
              <p className="mb-0"><a href="https://pool.safex.org/" className="link-dark" target="_blank" rel="noreferrer">Pool Safex</a> - Official Safex Mining Pool</p>
              <p className="mb-0"><a href="https://www.safex.pics/" className="link-dark" target="_blank" rel="noreferrer">Safex Pics</a> - Free online image and text hosting</p>
              <p className="mb-0"><a href="https://www.safexprotocol.com/" className="link-dark" target="_blank" rel="noreferrer">Safex Protocol</a> - Information and links</p>
              <p className="mb-0"><a href="https://safex.tools/" className="link-dark" target="_blank" rel="noreferrer">Safex Tools</a> - Online free calculator</p>
              <p className="mb-0"><a href="https://github.com/BlueDragon-Safex" className="link-dark" target="_blank" rel="noreferrer">Blue Dragon Safex</a> - Interacting with Safex Blockchain</p>              <p className="mb-0"><a href="https://safexemporium.com/" className="link-dark" target="_blank" rel="noreferrer">Safex Emporium</a> - Information and support</p>
              <p><a href="https://belgradecrypto.com/safex/" className="link-dark" target="_blank" rel="noreferrer">Belgrade Crypto</a> - European community</p>

              <h2 className="mb-0 mt-4">Forums:</h2>
              <p className="mb-0"><a href="https://forum.safex.org/" className="link-dark" target="_blank" rel="noreferrer">Forum.Safex.org</a> - The official forum</p>
              <p><a href="https://www.reddit.com/r/safex/" className="link-dark" target="_blank" rel="noreferrer">Safex Subreddit</a> - The official subreddit</p>

              <h2 className="mb-0">Chat Rooms:</h2>
              <p className="mb-0">Discord early investors - Restricted access</p>
              <p><a href="https://t.co/gbVqdl2hst" className="link-dark" target="_blank" rel="noreferrer">Safex Wolf Pack</a> - Community group </p>

              <h2 className="mb-0">Youtube and Twitter:</h2>
              <p className="mb-0"><a href="https://www.youtube.com/c/SafexMarketplace" className="link-dark" target="_blank" rel="noreferrer">Safex</a> - The official Channel</p>
              <p className="mb-0"><a href="https://www.youtube.com/channel/UCsLDCq5Te4rll4Mkrzfwzqw" className="link-dark" target="_blank" rel="noreferrer">Safex International</a></p>
              <p className="mb-0"><a href="https://www.youtube.com/channel/UChvpclbJ5tXSjunUYN3dPJg/videos" className="link-dark" target="_blank" rel="noreferrer">Safex NL</a></p>
              <p><a href="https://www.youtube.com/channel/UCAYxLBrdDUFRTt2F8C3j9CA" className="link-dark" target="_blank" rel="noreferrer">Safex Emporium</a></p>
              
              <p className="mb-0"><a href="https://twitter.com/safex" className="link-dark" target="_blank" rel="noreferrer">Safex</a> - The official Account</p>
              <p className="mb-0"><a href="https://twitter.com/SafexMarket" className="link-dark" target="_blank" rel="noreferrer">Safex Market</a></p>
              <p className="mb-0"><a href="https://twitter.com/SafexNinja" className="link-dark" target="_blank" rel="noreferrer">Safex Ninja</a></p>
              <p className="mb-0"><a href="https://twitter.com/SafexTutor" className="link-dark" target="_blank" rel="noreferrer">Safex Tutor</a></p>
              <p className="mb-0"><a href="https://twitter.com/officialdabek" className="link-dark" target="_blank" rel="noreferrer">Daniel Dabek</a></p>
              <p><a href="https://twitter.com/aharonbernal" className="link-dark" target="_blank" rel="noreferrer">Aharon Bernal</a></p>

            </div>
            <div className="col-md-6">
              <div className="community-link-right">
                <h1>SUPPORT</h1>
                <h2 className="mb-0">Official accounts:</h2>
                <p className="mb-0"><a href="https://safex.org/" className="link-dark" target="_blank" rel="noreferrer">Safex.org</a> - You are here</p>
                <p className="mb-0"><Link to="/safex-knowledge-base" className="link-dark" target="_blank" rel="noreferrer">Safex Knowledge Base</Link></p>
                <p className="mb-0"><Link to="/contact-us" className="link-dark" target="_blank" rel="noreferrer">Contact us</Link></p>
                <p className="mb-0"><a href="https://safex.market/" className="link-dark" target="_blank" rel="noreferrer">Safex Market</a> - TWM Inc website</p>                <p className="mb-0"><a href="https://forum.safex.org/" className="link-dark" target="_blank" rel="noreferrer">Safex Forum</a></p>
                <p className="mb-0"><a href="https://www.youtube.com/c/SafexMarketplace" className="link-dark" target="_blank" rel="noreferrer">Safex Youtube</a></p>
                <p><a href="https://twitter.com/safex" className="link-dark" target="_blank" rel="noreferrer">Safex Twitter</a></p>

                <h2 className="mb-0">Wallet support:</h2>
                <p className="mb-0"><Link to="/wallet" className="link-dark" target="_blank" rel="noreferrer">Download Safex TWM Wallet</Link></p>
                <p className="mb-0"><a href="https://safex.org/blog/safex-wallet/" className="link-dark" target="_blank" rel="noreferrer">Safex TWM Wallet [Blog Guides]</a></p>
                <p className="mb-0"><a href="https://www.youtube.com/playlist?list=PLZhRGDmH0o-zQRXPp_0giF5EreVA6Lkkg" className="link-dark" target="_blank" rel="noreferrer">Safex TWM Wallet [Video Guides]</a></p>
                <p><a href="https://forum.safex.org/" className="link-dark" target="_blank" rel="noreferrer">Ask question on Forum</a></p>

                <h2 className="mb-0">Exchange support:</h2>
                <p className="mb-0"><Link to="/buy-safex-coins" className="link-dark" target="_blank" rel="noreferrer">Buy and Sell Safex Coins</Link></p>
                <p className="mb-0"><Link to="/buy-safex-cash" className="link-dark" target="_blank" rel="noreferrer">Buy Safex Cash (SFX)</Link></p>
                <p className="mb-0"><Link to="/buy-safex-token" className="link-dark" target="_blank" rel="noreferrer">Buy Safex Token (SFT)</Link></p>
                <p className="mb-0"><a href="https://safex.org/blog/buy-and-sell-safex-coins/" className="link-dark" target="_blank" rel="noreferrer">Buy and Sell [Blog Guides]</a></p>
                <p><a href="https://forum.safex.org/" className="link-dark" target="_blank" rel="noreferrer">Ask question on Forum</a></p>

                <h2 className="mb-0">Mining support:</h2>
                <p className="mb-0"><Link to="/mining-safex-cash" className="link-dark" target="_blank" rel="noreferrer">Mining Safex Cash</Link></p>
                <p className="mb-0"><a href="https://safex.org/safex-cash-mining/" className="link-dark" target="_blank" rel="noreferrer">Safex Cash Mining [Blog Guides]</a></p>
                <p><a href="https://forum.safex.org/c/mining/19" className="link-dark" target="_blank" rel="noreferrer">Ask question on Forum</a></p>

                <h2 className="mb-0">Buy on Safex Market:</h2>
                <p className="mb-0"><Link to="/shopping-on-safex-ecommerce-market" className="link-dark" target="_blank" rel="noreferrer">Shopping on Safex</Link></p>
                <p><a href="https://forum.safex.org" className="link-dark" target="_blank" rel="noreferrer">Ask question on Forum</a></p>

                <h2 className="mb-0">Selling on Safex:</h2>
                <p className="mb-0"><a href="https://safex.market/" className="link-dark" target="_blank" rel="noreferrer">Become a Merchant</a></p>
                <p className="mb-0"><a href="https://safex.market/contact" className="link-dark" target="_blank" rel="noreferrer">Safex.Market</a></p>
                <p><a href="https://forum.safex.org" className="link-dark" target="_blank" rel="noreferrer">Ask question on Forum</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="community-form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="community-form-section-image">
                <img src={JoinSafexCommunity} className="img-fluid" alt=""/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-wrap form-wrap-no-p">
                <form>
                  <h3 className="text-center"><span className="color-blue">We Want To Hear</span> From You</h3>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="name"
                      className="form-control"
                      id="name"
                      aria-describedby="name"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="email"
                      placeholder="example@yourdomain.com"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Your message</label>
                    <textarea class="form-control" id="message" rows="5" placeholder="Hi there..."></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary m-auto mt-3">
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
}

export default SafexCommunity;
