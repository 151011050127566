import '../App.css';
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';

import ogImg from "../images/thumbnails/Buy-Safex-Cash-SFX.png";
import BuySafexCashSFX from "../images/4. Buy Safex Cash/Buy-Safex-Cash-SFX.webp";
import BuySafexCashSFXCargo from "../images/4. Buy Safex Cash/Buy-Safex-Cash-SFX-Cargo.webp";
import xcalibra from "../images/xcalibra.svg";
import bitrue from "../images/bitrue.svg";
import arrowUp from "../images/arrow-up.svg";
import Arrow from "../images/arrow.svg";


function BuySafexCash() {

  window.scrollTo(0, 0);

  const [sfx_price, setSfxPrice] = useState("0.00");
  const get_sfx = async () => {
    return axios({
      method: 'get',
      url: 'https://iapi.safex.org/prices/'
    }).then((res) => {
      return res.data.sfx;
    })
  }

  useEffect(async () => {
    try {
      let the_sfx = await get_sfx();
      setSfxPrice(the_sfx.toString());
    } catch(err) {
      console.log(err);
      setSfxPrice(0.00.toString());
    }
    setInterval(async () => {
      try {
        let the_sfx = await get_sfx();
        setSfxPrice(the_sfx.toString());
      } catch(err) {
        setSfxPrice(0.00);
      }
    }, 60000 * 1)
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buy Safex Cash (SFX) | safex.org</title>
        <meta name="description" content="Get Safex Cash (SFX) on crypto exchanges and use it for
         buying products and services on Safex Market." />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/buy-safex-cash" />
      </Helmet>
      <section className="hero-2-cols buy-safex-cash-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Buy Safex Cash (SFX) <span>On Cryptocurrency Exchanges</span></h1>
                <p>Buy Safex Cash (SFX) on these leading crypto exchanges: <br/> Xcalibra.com or Bitrue.com crypto exchange.</p>
                <p>Xcalibra.com is the first choice for purchasing Safex Coins. Here is the list of states, and 150+ countries where Xcalibra is available:</p>
                <a href="https://xcalibra.com/countries-list" target="_blank" rel="noreferrer" className="btn-arrow">
                  Xcalibra.com List
                  <img src={Arrow} alt=""/>
                </a>
                <p>Bitrue.com is also available in many countries has many crypto pairs listed which can convert into Safex Cash (SFX).</p>
                <a href="https://www.bitrue.com/exchange-web/article/faq/bitrue_service.html" target="_blank" rel="noreferrer" className="btn-arrow">
                  Bitrue.com
                  <img src={Arrow} alt=""/>
                </a>
                <div className="price-card green">
                  <p className="price-text">SAFEX CASH CURRENT PRICE</p>
                  <p className="current-price">${sfx_price}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={BuySafexCashSFX} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section step-section-first">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">1. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 1</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">INSTALL SAFEX WALLET</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Get prepared by installing Safex TWM Wallet. <br/> Get the Wallet and follow the <a href="https://www.youtube.com/watch?v=GFLY7K84ii4" target="_blank" rel="noreferrer">video guide.</a></p>
              <Link to="/wallet" target="_blank" rel="noreferrer" className="btn-arrow">
                Download the Wallet
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">2. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 2</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">BUY SAFEX CASH ON XCALIBRA.COM or BITRUE.COM</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <h2 className="fw-normal">How to buy Safex Cash on Xcalibra exchange?</h2>
              <ol>
                <li>Make an account on Xcalibra or log in</li>
                <li>Buy Bitcoin with a card (if needed)</li>
                <li>Exchange Bitcoin for Safex Cash</li>
                <li>Withdraw Safex Cash to your Safex TWM Wallet</li>
              </ol>
              <a href="https://trade.xcalibra.com/exchange/SFX_BTC" target="_blank" rel="noreferrer" className="btn btn-primary">
                <img src={xcalibra} alt=""/>
                Visit Xcalibra
              </a>
              <iframe className="video" src="https://www.youtube.com/embed/6PN9IIinyJ8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              <h2 className="fw-normal">How to buy Safex Cash on Bitrue exchange?</h2>
              <ol>
                <li>Make an account on Bitrue or log in</li>
                <li>Buy BTC with card and then exchange it for USDT (if neded)</li>
                <li>Exchange USDT for Safex Cash</li>
                <li>Withdraw Safex Cash to your Safex TWM Wallet</li>
              </ol>
              <a href="https://www.bitrue.com/" target="_blank" rel="noreferrer" className="btn btn-primary">
                <img src={bitrue} alt=""/>
                Visit Bitrue
              </a>
              <iframe className="video" src="https://www.youtube.com/embed/wPQERyFy99s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">3. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 3</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">BROWSE PRODUCTS ON SAFEX MARKET</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Now, when you have Safex Cash, you can make a purchase on Safex Market. <br /> Download the TWM Wallet, if you haven’t, and follow the shopping guide.</p>
              <Link to="/shopping-on-safex-ecommerce-market" className="btn-arrow">
                Shopping on Safex
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">4. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 4</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">SHARE YOUR EXPERIENCE WITH FRIENDS</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>Share your experience on your social media accounts or forums. <br /> Make a video of your purchase, and we will be pleased to reshare it. <br /> Thank you for being part of the Safex Ecosystem!</p>
              <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="btn-arrow">
                Twitter
                <img src={Arrow} alt=""/>
              </a>
              <div className="step-image image-mb-full">
                <img src={BuySafexCashSFXCargo} className="img-fluid" alt=""/>
              </div>
              <div className="row step-end choose-exchange-section">
                <h2>Choose an exchange for you based on where you are from.</h2>
                <div className="col-md-6">
                  <div className="step-end-text-left">
                    <p>Xcalibra is available in 150+ countries and territories and supports 15 national currencies. See if your country is on the list and trade at Xcalibra exchange.</p>
                    <p>National currency list: <br/>United States dollar, Euro, British pound, Danish krone, Czech koruna, Hungarian forint, Russian ruble, Australian dollar, Japanese yen, Norwegian krone, Polish złoty, New Zealand dollar,  Canadian dollar, Swiss franc, Swedish krona, Turkish lira, South African rand, Israeli new shekel.</p>
                    <a href="https://xcalibra.com/countries-list" target="_blank" rel="noreferrer" className="btn-arrow">
                      Find a full list here
                      <img src={Arrow} alt=""/>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <p>‘’In accordance with applicable laws, regulations, rules, and policy requirements, Bitrue does not provide services for residents of the People's Republic of China or residents of the states of Texas or New York in the United States of America.</p>
                  <p>By accessing and using the Services of Bitrue, you represent and warrant that you are not from the countries or regions which are on any trade or economic sanctions Lists, such as the UN Security Council Sanctions List, you not designated as a “Specially Designated National” by OFAC (Office of Foreign Assets Control of the U.S. Treasury Department) or placed on the U.S. Commerce Department’s “Denied Persons List”. Bitrue maintains the right to select its markets and jurisdictions to operate and may restrict or deny the services in certain countries and regions at its discretion.’’</p>
                  <a href="https://www.bitrue.com/exchange-web/article/faq/bitrue_service.html" target="_blank" rel="noreferrer" className="btn-arrow">
                    From: Bitrue website
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray have-questions-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">If you have any questions, find us on our <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Official Safex Forum.</a> <br/>Enjoy entering the Safex Ecosystem.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BuySafexCash;