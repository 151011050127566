import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Ecosystem.png";
import SafexEcosystemImage from "../images/11. Safex Ecosystem/Safex-Ecosystem.webp";
import WhatisSafexEcosystem from "../images/11. Safex Ecosystem/What-is-Safex.webp";
import InstallSafexEcosystemWallet from "../images/11. Safex Ecosystem/Safex-Wallet.webp";
import BuySafexCryptoCoinsonExchanges from "../images/11. Safex Ecosystem/Buy-Safex-Coins.webp";
import MineandEarnSafexCashCryptocurrency from "../images/11. Safex Ecosystem/Safex-Cash-Mining.webp";
import ShoppingonSafexeCommerceMarketplace from "../images/11. Safex Ecosystem/Shopping-on-Safex-Marketplace.webp";
import CryptocurrencyPassiveIncome from "../images/11. Safex Ecosystem/Safex-Token-Staking.webp";
import HowSafeEcosystemWorks from "../images/11. Safex Ecosystem/How-Safex-Works.webp";
import SellitemsonSafexMarketplace from "../images/11. Safex Ecosystem/Become-a-Merchant.webp";
import RunSafexNode from "../images/11. Safex Ecosystem/Run-Safex-Node.webp";
import SafexEcosystemKnowledgeBase from "../images/11. Safex Ecosystem/Safex-Knowledge-Base.webp";
import SafexCommunity from "../images/11. Safex Ecosystem/Safex-Community.webp";
import ContributetoSafexEcosystem from "../images/11. Safex Ecosystem/Contribute-to-Safex.webp";
import Arrow from "../images/arrow.svg";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function SafexEcosystem() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Find Your Place in the Safex Ecosystem | safex.org</title>
        <meta name="description" content="Find your place in Safex Ecosystem: earn by selling products, mining and trading coins, running nodes, developing your own apps, and participating in online discussions." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-ecosystem"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Safex Ecosystem <span>Changing the World</span></h1>
                <p>Safex is an engine for eCommerce. <br/> Like Amazon, except it's decentralized. <br/> Like Bitcoin, except it's private. <br/> Solved online commerce and blockchain.</p>
                <a href="#find-your-place" className="btn-arrow">
                  Dive in!
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexEcosystemImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray">
        <div className="container container-smaller">
          <div className="row">
            <div className="text">
              <h1 className="fw-normal">Find your place.</h1>
              <p>Join the Wolf Pack, earn by selling products, mining and trading coins on the exchanges, support the community by running your own node, develop your own apps, and participate in online discussions. <br/> Find your place in our ecosystem. </p>
            </div>
          </div>
        </div>
      </section>
      <section id="find-your-place" className="eco-card-with-image-section">
        <div className="container">
          <div className="row">
            <div className="card-with-image-wrap">
              <Link to="/what-is-safex" className="card-with-image">
                <div className="card-top">
                  <img src={WhatisSafexEcosystem} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>WHAT IS <span>SAFEX?<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex is a decentralized open-source protocol, it is a blockchain that establishes a network that enables eCommerce to take place using privacy enhanced cryptocurrencies.</p>
                </div>
              </Link>
              <Link to="/wallet" className="card-with-image">
                <div className="card-top">
                  <img src={InstallSafexEcosystemWallet} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>INSTALL THE <span>WALLET<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex Wallet is a comprehensive software application to store and send Safex Tokens and Safex Cash, stake/unstake Safex Tokens, and buy and sell goods and services.</p>
                </div>
              </Link>
              <Link to="/buy-safex-coins" className="card-with-image PurchaseSafexCryptoCoins">
                <div className="card-top">
                  <img src={BuySafexCryptoCoinsonExchanges} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>GET SAFEX <span>COINS<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Find all the details about buying and selling Safex Tokens (SFT) and Safex Cash (SFX) on crypto exchanges. Get some Safex Coins and participate in Crypto-Commerce.</p>
                </div>
              </Link>
    
              <Link to="/mining-safex-cash" className="card-with-image">
                <div className="card-top">
                  <img src={MineandEarnSafexCashCryptocurrency} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>START MINING SAFEX <span>CASH<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Start mining Safex Cash (SFX), start earning and use coins to shop on Safex Market or trade it for other currencies on exchanges.</p>
                </div>
              </Link>
              <Link to="/shopping-on-safex-ecommerce-market" className="card-with-image">
                <div className="card-top BuyProductsonSafexMarket">
                  <img src={ShoppingonSafexeCommerceMarketplace} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>SHOPPING ON SAFEX <span>MARKET<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Learn how to shop on the Safex Crypto-Commerce Market by following 3 simple steps. Explore, Order, and Receive the product.</p>
                </div>
              </Link>
              <Link to="/staking-safex-tokens" className="card-with-image">
                <div className="card-top">
                  <img src={CryptocurrencyPassiveIncome} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>Enjoy Passive <span>Income<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Stake Safex Tokens (SFT) in just a few clicks on the Safex Wallet and start passively earning from every sale on Safex Market.</p>
                </div>
              </Link>
           
              <Link to="/how-safex-works" className="card-with-image HowSafeEcosystemWorks">
                <div className="card-top">
                  <img src={HowSafeEcosystemWorks} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>HOW SAFEX <span>WORKS<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Find educational information and explanations about the Safex Ecosystem, Blockchain, and Coins: how to use them, and how to benefit from them.</p>
                </div>
              </Link>
              <a href="https://safex.market/" target="_blank" rel="noreferrer" className="card-with-image SellitemsonSafexMarketplace">
                <div className="card-top">
                  <img src={SellitemsonSafexMarketplace} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>SELLING ON SAFEX <span>MARKET<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Learn how to become a Merchant on Safex Market. More is coming soon, read our updates and stay tuned.</p>
                </div>
              </a>
              <Link to="/run-safex-node" className="card-with-image RunSafexNode">
                <div className="card-top">
                  <img src={RunSafexNode} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>RUN YOUR OWN <span>NODE<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Become a supporter of the Safex Network by running your own node on Windows, Linux or Mac.</p>
                </div>
              </Link>
           
              <Link to="/safex-knowledge-base" className="card-with-image">
                <div className="card-top">
                  <img src={SafexEcosystemKnowledgeBase} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>VISIT OUR <span>BLOG<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Visit our blog to learn in depth about many topics related to ecommerce, development, blockchains, and much more related to crypto.</p>
                </div>
              </Link>
              <Link to="/safex-community" className="card-with-image SafexCommunity">
                <div className="card-top">
                  <img src={SafexCommunity} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>Join the <span>community<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Join Safex community: find us on Safex Forum, Twitter, and Discord, support our network and earn on Safex Market.</p>
                </div>
              </Link>
              <Link to="/promote-safex" className="card-with-image ContributetoSafexEcosystem">
                <div className="card-top">
                  <img src={ContributetoSafexEcosystem} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p><span>Contribute<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Join us in revolutionising eCommerce together: Contribute to the development of a free open source software project.</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="form-wrap">
              <form>
                <h3 className="text-center"><span className="color-blue">We Want To Hear</span> From You</h3>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="name"
                    className="form-control"
                    id="name"
                    aria-describedby="name"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="email"
                    placeholder="example@yourdomain.com"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Your message</label>
                  <textarea class="form-control" id="message" rows="5" placeholder="Hi there..."></textarea>
                </div>
                <button type="submit" className="btn btn-primary m-auto mt-3">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SafexEcosystem;
