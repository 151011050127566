import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Arrow from "../images/arrow.svg";
import ogImg from "../images/thumbnails/Contact-Safex-Org.png";


function CookiePolicy() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cookie Policy | safex.org</title>
        <meta name="description" content="Safex.org cookie policy" />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/cookie-policy" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-text">
                <h1>Cookie Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p className="mb-5">A cookie consists of information sent by a web server to a web browser, and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser. We may use both “session” cookies and “persistent” cookies on this website. We will use the session cookies to: keep track of you whilst you navigate the website; and keep you logged in. We will use the persistent cookies to: enable our website to recognise you when you visit; remember your preferred language; store the city you were last in. Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date.</p>
              <p>We use Google Analytics to analyse the use of this website. Google Analytics generates
                statistical and other information about website use by means of cookies, which are stored on
                users’ computers. The information generated relating to our website is used to create reports
                about the use of the website. Google will store this information. Google’s privacy policy is
                available <a href="https://policies.google.com/privacy" target="_blank">here</a>.
                Our payment services providers may also send you cookies. Most browsers allow you to refuse
                to accept cookies. This will, however, have a negative impact upon the usability of many websites.</p>
              <Link to="/" className="btn-arrow mt-5">
                Go Home!
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CookiePolicy;