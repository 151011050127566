import '../App.css';
import { Helmet } from "react-helmet";

import Chart2 from "../charts/Chart2";

import ogImg from "../images/thumbnails/Mining-Safex-Cash.png";
import MiningSafexCashSFX from "../images/9. Stake/Stake-Safex-Tokens-SFT.webp";
import Arrow from "../images/arrow.svg";
import { Link } from 'react-router-dom';


function StakingSafexTokens() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Crypto Staking Safex Tokens SFT | safex.org</title>
        <meta name="description" content="Stake Safex Tokens (SFT) in just a few clicks on the Safex Wallet and start passively earning from every sale on the Safex Market." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/staking-safex-tokens"/>
      </Helmet>
      <section className="hero-2-cols staking-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-5 order-2 order-md-1">
              <div className="hero-text">
                <h1>Stake Safex Tokens (SFT) <span>Receive Passive Income</span></h1>
                <p>Stake Safex Tokens (SFT) in just a few clicks of the Safex Wallet and start passively earning from every sale on Safex Market. Collect enough Safex Cash (SFX) to spend on shopping in the Safex Market, or alternatively, trade it or hold onto it.</p>
                <Link to="/wallet" className="btn-arrow">
                  Download Safex TWM Wallet
                  <img src={Arrow} alt=""/>
                </Link>
              </div>
            </div>
            <div className="col-md-7 order-1 order-md-2">
              <div className="hero-image">
                <img src={MiningSafexCashSFX} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray what-is-staking-section m-no-bg">
        <div className="container">
          <div className="row">
            <h1 className="fw-500">WHAT IS SAFEX STAKING?</h1>
          </div>
          <div className="row">
            <div className="col-md-6">
              <p>Staking is a process whereby you temporarily give up access to an amount of your Safex Tokens. In return for giving up access, those staked Tokens gain the ability to earn passive income, but only while they are in a Staked state. Staking is done entirely within your Wallet, effortlessly, using the built-in commands in the Safex Wallet.</p>
              <p>With staked Safex Tokens you will earn from every sale on Safex Market. <br/> 5% of every blockchain purchase transaction goes into the Safex Commission Pool and gets distributed to eligible staked Safex Tokens.</p>
            </div>
            <div className="col-md-6">
              <div className="staking-chart">
                <Chart2 />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what-you-need-staking">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="fw-normal">What do you need?</h2>
              <p className="mb-0">To start staking, you need to have:</p>
              <ul>
                <li>The latest Safex TWM Wallet</li>
                <li>25,000 Tokens minimum</li>
                <li>A small amount of Safex Cash, to cover each Staking transaction fee.</li>
              </ul>
            </div>
            <div className="what-you-need-staking-steps-section">
              <h2 className="fw-normal">Steps:</h2>
              <ul>
                <li>Download the Safex Wallet</li>
                <li>Make sure you have at least 25,000 Safex Tokens in your Wallet available balance</li>
                <li>Go to the Token Tab</li>
                <li>Stake by following a few simple steps - you can watch the video below or checkout the <a href="https://safex.org/how-to-stake-safex-tokens/" target="_blank" rel="noreferrer"> text guide.</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="staking-video-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="fw-normal">It’s very easy, follow the video guide:</h2>
              <iframe className="video" src="https://www.youtube.com/embed/YFdr7lLbmpQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="additional-section">
        <div className="container">
          <div className="col-md-9">
            <h2 className="fw-normal">Additional information:</h2>
            <ul>
              <li>For new Stakings, they become eligible for passive income earning (aka Revenue [or Rev] Share) at the end of the 1000-block interval in which they were staked.</li>
              <li>Share calculations are completed at the end of each 1000-block interval.</li>
              <li>Only those SFT that were staked for the entire 1000-block interval can qualify for Rev Share.</li>
              <li>Rev Share is a pro rata share of the accumulated Commission Pool for all marketplace purchases made during that 1000-block interval.</li>
              <li>Minimum Stake Time: 8,000 blocks (~10 days), during which time the newly Staked amount cannot be Unstaked.</li>
              <li>You can stake and unstake unlimited times, subject to the 25k minimum size.</li>
              <li>While the Tokens are staked, you cannot transfer them, trade them, nor use them to create Merchant Accounts.</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gray claiming-section m-no-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h2 className="fw-normal">Claiming Safex Cash (SFX) Revenue Share:</h2>
              <ul>
                <li>To claim your accumulated SFX Rev Share, you need to unstake the Safex Tokens.</li>
                <li>Unstaking is performed in the Token page of the Safex Wallet, and is as simple to complete as the Staking transaction.</li>
                <li>After performing the unstaking transaction you need to wait for 10 blocks before the unstaked Tokens and associated claimed SFX rev share appear in your available balance. They will show as Pending during the 10 blocks.</li>
                <li>After the 10 blocks, both the Tokens and Cash are available for use.</li>
                <li>The Maximum Rev Share eligibility period for an individual Staking event is 500,000 blocks. The Tokens must be Unstaked and the accumulated Rev Share claimed into your wallet, after which the Tokens can again be Staked, to continue earning more Rev Share. If you simply leave them staked past 500k blocks, no further rev share will be credited against them, as they are no longer eligible.</li>
              </ul>
              <Link to="/wallet" rel="noreferrer" className="btn-arrow">
                Download Safex TWM Wallet
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
    
   
    </div>
  );
}

export default StakingSafexTokens;
