import '../App.css';
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from "react-helmet-async";

import ogImg from "../images/thumbnails/What-is-Safex-Technology.png";
import WhatisSafexTechnology from "../images/2. What Is Safex/What-is-Safex-Technology.webp";
import SafexBlockchainTechnology from "../images/2. What Is Safex/How-Safex-Works-Technical.webp";
import InstallSafexCryptocurrencyWallet from "../images/2. What Is Safex/Safex-Wallet.webp";
import PurchaseSafexCryptoCoins from "../images/2. What Is Safex/Buy-Safex-Coins.webp";
import WhatisSafexeCommerceUniverse from "../images/2. What Is Safex/What-is-Safex-eCommerce-Universe.webp";
import Arrow from "../images/arrow.svg";


function WhatIsSafex() {

    window.scrollTo(0, 0);

    return (
        <div className="page-content">
            <HelmetProvider>
                <Helmet>
                    <title>What is the Safex Ecosystem | safex.org</title>
                    <meta name="description"
                          content="Safex is an open-source blockchain software that establishes a global network that powers an eCommerce platform that allows decentralized shopping for cryptocurrency."/>
                    <meta property="og:image" content={ogImg}/>
                    <meta charSet="utf-8"/>
                    <link rel="canonical" href="http://safex.org/what-is-safex"/>
                </Helmet>
            </HelmetProvider>
            <section className="hero-2-cols">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="hero-text">
                                <h1>What is Safex? <span>A Blockchain and a Network</span></h1>
                                <p>As open source blockchain software, Safex establishes a global network that the
                                    community drives through mining, much like Bitcoin mining.</p>
                                <p>Additionally, it exposes an application layer that enabled an eCommerce platform,
                                    which hosts a globally accessible catalog of items for sale, in exchange for
                                    cryptocurrency on the decentralized network.</p>
                                <p>Users can mine, stake, buy and sell goods...decentralized!</p>
                                <Link to="/wallet" className="btn-arrow">
                                    Get Started With a Wallet
                                    <img src={Arrow} alt=""/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="hero-image">
                                <img src={WhatisSafexTechnology} className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray m-no-bg safex-elements">
                <div className="container">
                    <div className="row d-none d-md-block">
                        <div className="desktop-text-center">
                            <h1 className="fw-500 mb-1">Safex Elements</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card-wrap">
                            <div className="card">
                                <div>
                                    <h4>SAFEX BLOCKCHAIN</h4>
                                    <p>Safex Blockchain is the foundation of the Safex ecosystem. It’s decentralized,
                                        privacy oriented, made and maintained by the community.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h4>2 COIN SYSTEM</h4>
                                    <p>Safex Cash (SFX) is the mineable cryptocurrency of the Safex Ecosystem. Safex
                                        Token (SFT) is a utility token that can also be used for passive income.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h4>PRIVACY</h4>
                                    <p>Safex establishes a unified catalog of items from all over the world. This means
                                        you can shop deals and interact with sellers using cryptocurrency.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h4>EQUITABLE MINING</h4>
                                    <p>Safex Cash (SFX) is efficiently mined using average home computers. This means
                                        that anyone can join and earn coins through the tradition of cryptocurrency
                                        mining.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h4>STAKING: REVENUE SHARING</h4>
                                    <p>Safex Tokens (SFT) enable passive income from every sale on Safex. 5% is charged
                                        on every sale, and that gets shared among all staked Token holders.</p>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h4>DECENTRALIZED MARKETPLACE</h4>
                                    <p>The main application on Safex is its Marketplace. This means you enjoy a
                                        decentralized platform to sell your goods or services, where you control your
                                        own destiny.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="card-with-image-wrap-section">
                <div className="container">
                    <div className="row">
                        <div className="card-with-image-wrap card-with-image-wrap-margin">
                            <Link to="/how-safex-works" className="card-with-image">
                                <div className="card-top">
                                    <img src={SafexBlockchainTechnology} className="img-fluid" alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="btn-arrow">
                                        <p>Technical Safex <span>Works<img src={Arrow} alt=""/></span></p>
                                    </div>
                                    <p className="desc">Find educational information and explanations about the Safex
                                        Ecosystem, Blockchain, and Coins: how to use them, and how to benefit from
                                        them.</p>
                                </div>
                            </Link>
                            <Link to="/wallet" className="card-with-image">
                                <div className="card-top">
                                    <img src={InstallSafexCryptocurrencyWallet} className="img-fluid" alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="btn-arrow">
                                        <p>INSTALL THE <span>WALLET<img src={Arrow} alt=""/></span></p>
                                    </div>
                                    <p className="desc">Safex TWM Wallet is a multifunctional crypto wallet for storing,
                                        sending coins, buying, selling goods, and staking Tokens. You mine Safex Cash
                                        directly to this Wallet.</p>
                                </div>
                            </Link>
                            <Link to="/buy-safex-coins" className="card-with-image PurchaseSafexCryptoCoins">
                                <div className="card-top">
                                    <img src={PurchaseSafexCryptoCoins} className="img-fluid" alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="btn-arrow">
                                        <p>GET SAFEX <span>COINS<img src={Arrow} alt=""/></span></p>
                                    </div>
                                    <p className="desc">Acquiring and getting started in Safex is simple by following
                                        these tailor made instructions. Learn to buy and trade the Safex coins on
                                        exchanges here.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-image-fluid-section">
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="text-center">
                                <h1 className="color-blue">WELCOME TO THE SAFEX ECOSYSTEM</h1>
                            </div>
                        </div>
                    </div>
                    <div className="bg-image-fluid">
                        <img src={WhatisSafexeCommerceUniverse} className="img-fluid" alt=""/>
                    </div>
                </div>
            </section>
            <section className="card-wrap-section">
                <div className="container">
                    <div className="row">
                        <div className="card-wrap card-2-col">
                            <div className="card">
                                <div>
                                    <h3>FOLLOW ALONG ON OUR BLOG</h3>
                                    <p>We have developed a comprehensive blog that outlines and covers many themes on
                                        Safex. Visit our blog to learn in depth about many topics related to eCommerce,
                                        development, blockchains, and much more related to crypto.</p>
                                    <Link to="/safex-knowledge-base" className="btn-arrow fw-normal mt-3">
                                        Explore more
                                        <img src={Arrow} alt=""/>
                                    </Link>
                                </div>
                            </div>
                            <div className="card">
                                <div>
                                    <h3>COMMUNITY-DRIVEN PROJECT</h3>
                                    <p>Safex is a community-driven project made by and for the community. People in our
                                        community are merchants, shoppers, crypto enthusiasts, miners, traders, and
                                        developers from all over the world. </p>
                                    <a href="https://twitter.com/safex" target="_blank" rel="noreferrer"
                                       className="btn-arrow fw-normal mt-3">
                                        Find us on Twitter
                                        <img src={Arrow} alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray-text bg-gray what-is-safex-bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="text text-center">
                            <h2 className="fw-normal">Find all the details about what Safex is and how it works.</h2>
                            <Link to="/wallet" className="btn-arrow fw-normal font-25 mt-3">
                                Go to Downloads
                                <img src={Arrow} alt=""/>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default WhatIsSafex;