import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import '@popperjs/core';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';

import Nav from './components/Nav';
import Footer from './components/Footer';

import Home from './pages/Home';
import WhatIsSafex from './pages/What-is-safex';
import Wallet from './pages/Wallet';
import BuySafexCash from './pages/Buy-safex-cash';
import BuySafexTokens from './pages/Buy-safex-tokens';
import BuySafexCoins from './pages/Buy-safex-coins';
import ShoppingOnSafex from './pages/Shopping-on-safex';
import HowSafexWorks from './pages/How-safex-works';
import MiningSafexCash from './pages/Mining-safex-cash';
import StakingSafexTokens from './pages/Staking-safex-tokens';
import PrivacyBlockchain from './pages/Privacy-blockchain';
import SafexEcosystem from './pages/Safex-ecosystem';
import MissionAndVision from './pages/Mission-and-vision';
import SafexFoundation from './pages/Safex-foundation';
import SafexCommunity from './pages/Safex-community';
import PromoteSafex from './pages/Promote-safex';
import ContactUs from './pages/Contact-us';
import ThankYou from './pages/Thank-you';
import NoMatch from './pages/404';
import Termsofuse from './pages/Terms-of-use';
import PrivacyPolicy from './pages/Privacy-policy';
import CookiePolicy from './pages/Cookie-Policy';
import SafexTrademarkPolicy from './pages/Safex-trademark-policy';
import CodingonSafex from './pages/Coding-on-safex';
import Donation from './pages/Donation';
import SafexAssets from './pages/Safex-assets';
import SafexKnowledgeBase from './pages/Safex-knowledge-base';
import CryptoSafetyTips from './pages/Crypto-safety-tips';
import RunSafexNode from './pages/Run-safex-node';

//import LinkScrollToTop from './components/LinkScrollToTop';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <Nav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/what-is-safex" component={WhatIsSafex} />
        <Route path="/wallet" component={Wallet} />
        <Route path="/buy-safex-cash" component={BuySafexCash} />
        <Route path="/buy-safex-token" component={BuySafexTokens} />
        <Route path="/buy-safex-coins" component={BuySafexCoins} />
        <Route path="/shopping-on-safex-ecommerce-market" component={ShoppingOnSafex} />
        <Route path="/how-safex-works" component={HowSafexWorks} />
        <Route path="/mining-safex-cash" component={MiningSafexCash} />
        <Route path="/staking-safex-tokens" component={StakingSafexTokens} />
        <Route path="/safex-privacy-blockchain" component={PrivacyBlockchain} />
        <Route path="/safex-ecosystem" component={SafexEcosystem} />
        <Route path="/mission-and-vision" component={MissionAndVision} />
        <Route path="/safex-foundation" component={SafexFoundation} />
        <Route path="/safex-community" component={SafexCommunity} />
        <Route path="/promote-safex" component={PromoteSafex} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/terms-of-use" component={Termsofuse} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/cookie" component={CookiePolicy} />
        {/*<Route path="/safex-trademark-policy" component={SafexTrademarkPolicy} />*/}
        <Route path="/coding-on-safex" component={CodingonSafex} />
        <Route path="/donation" component={Donation} />
        <Route path="/safex-assets" component={SafexAssets} />
        <Route path="/safex-knowledge-base" component={SafexKnowledgeBase} />
        <Route path="/crypto-safety-tips" component={CryptoSafetyTips} />
        <Route path="/run-safex-node" component={RunSafexNode} />
        <Route path="*" component={NoMatch} />
      </Switch>
      <ScrollToTop />
      <Footer />
    </Router>
  );
}

export default App;