import '../App.css';
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Knowledge-Base.png";
import SafexKnowledgeBaseImage from "../images/24. Safex Knowledg Base/Safex-Knowledge-Base.webp";



function SafexKnowledgeBase() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Safex Knowledge Base - Learn, Explore, Earn | safex.org</title>
        <meta name="description" content="Find the educational information, guides, and explanations about Safex Marketplace, Safex Blockchain, the Safex Coins, how to use them and to benefit from them." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-knowledge-base"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Safex Knowledge Base <span>Learn, Explore, Earn</span></h1>
                <p>Putting it simply, Safex is a decentralized eCommerce platform powered by a privacy blockchain. Below you'll find comprehensive educational information, guides, and explanations about Safex Marketplace, Safex Blockchain, the Safex Coins, how to use them, and how to benefit from them.</p>
                <p>The Safex Knowledge Base is made both for beginners and tech gurus, so let's dive in!</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexKnowledgeBaseImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="knowledge-base-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">

              <h2 className="fw-normal mb-4 font-27"><a href="https://safex.org/blog/getting-started/" className="link-dark" target="_blank" rel="noreferrer">Get Started: If you are a beginner - Start from here</a></h2>
              <ul>
                <li><a href="https://safex.org/blog/first-steps/" className="link-dark" target="_blank" rel="noreferrer">First Steps</a></li>
                <li><a href="https://safex.org/blog/safex-basics/" className="link-dark" target="_blank" rel="noreferrer">Safex Basics</a></li>
                <li><a href="https://safex.org/blog/safex-advanced/" className="link-dark" target="_blank" rel="noreferrer">Safex Advanced</a></li>
                <li><a href="https://safex.org/blog/safex-faq/" className="link-dark" target="_blank" rel="noreferrer">Safex FAQ</a></li>
                <li><a href="https://safex.org/blog/safex-news-and-updates/" className="link-dark" target="_blank" rel="noreferrer">Safex News & Updates</a></li>
                <li><a href="https://safex.org/blog/important-links/" className="link-dark" target="_blank" rel="noreferrer">Important Links</a></li>
              </ul>
              
              <h2 className="fw-normal mb-4 font-27"><a href="https://safex.org/blog/tech-guidebook/" className="link-dark" target="_blank" rel="noreferrer">Tech guide: Explore concepts, ideas and software</a></h2>
              <ul>
                <li><a href="https://safex.org/blog/safex-wallet/" className="link-dark" target="_blank" rel="noreferrer">Safex Wallet [Guides]</a></li>
                <li><a href="https://safex.org/blog/buy-and-sell-safex-coins/" className="link-dark" target="_blank" rel="noreferrer">Buy, sell, handle Safex Coins on the exchanges [Guides]</a></li>
                <li><a href="https://safex.org/blog//safex-economics/" className="link-dark" target="_blank" rel="noreferrer">Safex Economics [Explanations]</a></li>
                <li><a href="https://safex.org/blog/safex-cash-mining/" className="link-dark" target="_blank" rel="noreferrer">Safex Cash Mining [Guides]</a></li>
                <li><a href="https://safex.org/blog/safex-nodes/" className="link-dark" target="_blank" rel="noreferrer">Safex Nodes [Guides]</a></li>
              </ul>

              
              <h2 className="fw-normal mb-4 font-27"><a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" className="link-dark" target="_blank" rel="noreferrer">Video guides:</a></h2>
              <ul>
                <li><a href="https://www.youtube.com/watch?v=6PN9IIinyJ8" className="link-dark" target="_blank" rel="noreferrer">Buy Safex Cash on Xcalibra</a></li>
                <li><a href="https://www.youtube.com/watch?v=wPQERyFy99s" className="link-dark" target="_blank" rel="noreferrer">Buy Safex Cash on Bitrue</a></li>
                <li><a href="https://www.youtube.com/watch?v=HpjvdBg7bMI" className="link-dark" target="_blank" rel="noreferrer">Buy Safex Tokens on Xcalibra</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLZhRGDmH0o-yvYk2j-Ql-_lAFZBMJU_ye" className="link-dark" target="_blank" rel="noreferrer">Dev Updates  and News</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLZhRGDmH0o-zQRXPp_0giF5EreVA6Lkkg" className="link-dark" target="_blank" rel="noreferrer">Safex TWM Wallet</a></li>
              </ul>

            </div>
            <div className="col-md-6">

              <h2 className="fw-normal mb-4 font-27"><a href="https://safex.org/blog/ecommerce/" className="link-dark" target="_blank" rel="noreferrer">Ecommerce Articles: Tips & tricks, marketing, ideas</a></h2>
              <ul>
                <li><a href="https://safex.org/blog/ecommerce-basics/" className="link-dark" target="_blank" rel="noreferrer">Ecommerce Basics</a></li>
                <li><a href="https://safex.org/blog/ecommerce-tips-tricks/" className="link-dark" target="_blank" rel="noreferrer">Tips & Tricks</a></li>
                <li><a href="https://safex.org/blog/ecommerce-cryptocurrency/" className="link-dark" target="_blank" rel="noreferrer">Ecommerce & Cryptocurrency</a></li>
              </ul>
            
              <h2 className="fw-normal mb-4 font-27"><a href="https://safex.org/blog/privacy/" className="link-dark" target="_blank" rel="noreferrer">Privacy: Stay safe in online world</a></h2>
              <ul>
                <li><a href="https://safex.org/blog/privacy-blockchain/" className="link-dark" target="_blank" rel="noreferrer">Privacy Blockchain</a></li>
                <li><a href="https://safex.org/blog/crypto-security/" className="link-dark" target="_blank" rel="noreferrer">Crypto Security</a></li>
              </ul>

              <h2 className="fw-normal font-27 mb-0"><a href="https://safex.org/blog/blockchain-and-cryptocurrency-basics/" className="link-dark" target="_blank" rel="noreferrer">Blockchain & Crypto [Basics]</a></h2>
              <h2 className="fw-normal font-27 mb-0"><a href="https://safex.org/blog/safex-infographics/" className="link-dark" target="_blank" rel="noreferrer">Safex Infographics</a></h2>
              <h2 className="fw-normal font-27 mb-0"><a href="https://safex.org/blog/" className="link-dark" target="_blank" rel="noreferrer">Safex Blog</a></h2>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SafexKnowledgeBase;
