import '../App.css';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from 'react';

import ogImg from "../images/thumbnails/Shopping-on-Safex-eCommerce-Marketplace.png";
import ShoppingonSafexeCommerceMarket from "../images/6. Shopping on Safex eCommerce Marketplace/Shopping-on-Safex-eCommerce-Market.webp";
import PurchasesonSafexeCommerceMerket from "../images/6. Shopping on Safex eCommerce Marketplace/Purchases-on-Safex-eCommerce-Merket.webp";
import SellerAccountsonSafexeCommerceMarketplace from "../images/6. Shopping on Safex eCommerce Marketplace/Seller-Accounts-on-Safex-eCommerce-Marketplace.webp";
import SpentSafexCash from "../images/6. Shopping on Safex eCommerce Marketplace/Spent-Safex-Cash.webp";
import USDValueofListedItemsonSafexMarket from "../images/6. Shopping on Safex eCommerce Marketplace/USD-Value-of-Listed-Items-on-Safex-Market.webp";
import SafexMarketplaceecommerceUniverse from "../images/6. Shopping on Safex eCommerce Marketplace/Safex-Marketplace-ecommerce-Universe.webp";
import Arrow from "../images/arrow.svg";


function ShoppingOnSafex() {

  window.scrollTo(0, 0);

  const [sftStaked, setStaked] = useState(0);
  const [sfxSpent, setSpent] = useState(0);
  const [nPurch, setPurch] = useState(0);
  const [nAccs, setAccs] = useState(0);
  const [totalUSD, setTotal] = useState(0);

  useEffect(async () => {
    try {
      let stats = await get_stats();
      setStaked(stats.sft_staked);
      setSpent(stats.sfx_spent.toLocaleString('en-US', {minimumFractionDigits: 2}));
      setPurch(stats.purchase_txn);
      setAccs(stats.account_creation_txn);
      setTotal(stats.total_product_usd.toLocaleString('en-US', {minimumFractionDigits: 2}));
    } catch(err) {

    }
  })


  const get_stats = async () => {
    return axios({
      method: 'get',
      url: 'https://api.safex.market/bc_stats'
    }).then(resp => {
      return resp.data;
    })
  }

  return (
    <div className="page-content">
      <Helmet>
        <title>Shopping on the Safex e-commerce Market | safex.org</title>
        <meta name="description" content="Explore a global product catalog and Shop on Safex Market in a decentralized, private and secure manner." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/shopping-on-safex"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Shopping on Safex <span>Empowering eCommerce Business</span></h1>
                <p>Shop on Safex Market with crypto by following 3 simple steps: Explore, Order, Receive.</p>
                <div>
                  Why you should try Safex Market:
                  <ul>
                    <li>Communicate directly with the seller</li>
                    <li>Democratized eCommerce Platform</li>
                    <li>Global Product Catalog</li>
                    <li>Your data is encrypted</li>
                    <li>Control your own data*</li>
                    <li>Secure way to shop online with cryptocurrency</li>
                    <li>Protect your balance when spending</li>
                  </ul>
                </div>
                <p className="small-text">* when shopping with Safex the vendor only receives your coins for the product, doesn't know your balance UNLIKE other blockchains like Bitcoin, Ethereum, Binance.</p>
                <a href="/wallet" target="_blank" rel="noreferrer" class="btn btn-primary">
                  Safex Market Wallet
                </a>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={ShoppingonSafexeCommerceMarket} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="safex-info-wrap-section d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="safex-info-wrap">
              <div className="center">
                <div className="safex-info">
                  <div className="safex-info-image">
                    <img src={PurchasesonSafexeCommerceMerket} className="img-fluid" alt=""/>
                  </div>
                  <p>{nPurch.toLocaleString()}<br/> Purchases</p>
                </div>
                <div className="safex-info">
                  <div className="safex-info-image">
                    <img src={SellerAccountsonSafexeCommerceMarketplace} className="img-fluid" alt=""/>
                  </div>
                  <p>{nAccs.toLocaleString()} <br/> Seller Accounts</p>
                </div>
                <div className="safex-info">
                  <div className="safex-info-image">
                    <img src={SpentSafexCash} className="img-fluid" alt=""/>
                  </div>
                  <p>{sfxSpent.toLocaleString()} <br/> SFX Spent</p>
                </div>
                <div className="safex-info">
                  <div className="safex-info-image">
                    <img src={USDValueofListedItemsonSafexMarket} className="img-fluid" alt=""/>
                  </div>
                  <p>{totalUSD.toLocaleString()} <br/> USD value of listed items</p>
                </div>
              </div>
              <div className="source">
                <p>Source: Safex.Market</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section step-section-first shopping-step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">1. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 1</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">EXPLORE SAFEX MARKET</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <p>After exploring the Safex <a href="https://safex.ninja/">Market Catalog</a>, download and install the Safex TWM Wallet.</p>
              <Link to="/wallet" className="btn-arrow">
                <span className="d-none d-md-inline">Download the Wallet from this page</span>
                <span className="d-md-none">Download the Wallet</span>
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">2. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 2</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">ORDER A PRODUCT FROM SAFEX MARKET</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <h2 className="fw-normal">Choose the product you like and buy it!</h2>
              <p>Read the product information. <br/> Fill in your address and other required information so the merchant can send you the products. <br/>Keep in mind: Only the merchant will receive this information and no one else. <br/>Click on the Buy button. <br/> <b className="fw-500">That’s it. Congrats!</b></p>
              <iframe className="video" src="https://www.youtube.com/embed/6PN9IIinyJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h2 className="fw-normal">What if you don’t have enough Safex Cash?</h2>
              <p>If you don’t have enough Safex Cash for shopping, visit the exchange and get it! <br/> Learn more where to do it:</p>
              <Link to="/buy-safex-cash" className="btn-arrow">
                Buy Safex Cash
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="step-section">
        <div className="step">
          <div className="left">
            <div className="step-number">
              <p className="d-md-none">3. <span>STEP</span></p>
              <p className="d-none d-md-block">STEP 3</p>
            </div>
          </div>
          <div className="center">
            <h2 className="fw-normal">RECEIVE THE PRODUCT</h2>
          </div>
          <div className="right"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="step-body">
              <h2 className="fw-normal">Wait for the delivery.</h2>
              <p>After ordering, the merchant will send you the product to your nominated delivery address. <br/>Remember: all the transactions on Safex Market are private due to the privacy-oriented Safex Blockchain. <br/><strong>Enjoy Safex Shopping.</strong></p>
            </div>
          </div>
        </div>
        <section className="safex-info-wrap-section d-md-none bg-gray">
          <div className="container">
            <div className="row">
              <div className="safex-info-wrap">
                <div className="center">
                  <div className="safex-info">
                    <div className="safex-info-image">
                      <img src={PurchasesonSafexeCommerceMerket} className="img-fluid" alt=""/>
                    </div>
                    <p>155 <br/> Purchases</p>
                  </div>
                  <div className="safex-info">
                    <div className="safex-info-image">
                      <img src={SellerAccountsonSafexeCommerceMarketplace} className="img-fluid" alt=""/>
                    </div>
                    <p>1,139 <br/> Seller Accounts</p>
                  </div>
                  <div className="safex-info">
                    <div className="safex-info-image">
                      <img src={SpentSafexCash} className="img-fluid" alt=""/>
                    </div>
                    <p>1,600,568.725 <br/> SFX Spent</p>
                  </div>
                  <div className="safex-info">
                    <div className="safex-info-image">
                      <img src={USDValueofListedItemsonSafexMarket} className="img-fluid" alt=""/>
                    </div>
                    <p>8,539.38 <br/> USD value of listed items</p>
                  </div>
                </div>
                <div className="source">
                  <p>Source: Safex.Market</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="bg-gray reliable-section m-no-bg">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="fw-normal">Safex Market is reliable and easy to use.</h2>
              <Link to="/wallet" className="btn-arrow fw-500">
                Download the Safex TWM Wallet
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="d-none d-md-block">
        <div>
          <div className="bg-image-fluid">
            <img src={SafexMarketplaceecommerceUniverse} className="img-fluid" alt=""/>
          </div>
        </div>
      </section>
      <section className="how-card-section">
        <div className="container">
          <div className="row">
            <div className="card-wrap card-2-col">
              <div className="card">
                <h3>HOW SAFEX WORKS?</h3>
                <p>Find educational information and explanations about the Safex Ecosystem, Blockchain, and Coins: how to use them, and how to benefit from them.</p>
                <Link to="/how-safex-works" className="btn-arrow">
                  Explore more
                  <img src={Arrow} alt=""/>
                </Link>
              </div>
              <div className="card">
                <h3>COMMUNITY-DRIVEN PROJECT</h3>
                <p>Safex is a community-driven project made by and for the community. People in our community are merchants, shoppers, crypto enthusiasts, miners, traders, and developers from all over the world. </p>
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="btn-arrow">
                  Find us on Twitter
                  <img src={Arrow} alt=""/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ShoppingOnSafex;
