import '../App.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Chart1 from "../charts/Chart1";
import Chart2 from "../charts/Chart2";
import Chart3 from "../charts/Chart3";
import Chart4 from "../charts/Chart4";

import ogImg from "../images/thumbnails/Safex.png";
import WelcometoSafex from "../images/1. Home/Welcome-to-Safex.webp";
import InstallSafexCryptoWallet from "../images/1. Home/Safex-Wallet.webp";
import GetSafexCash from "../images/1. Home/Buy-Safex-Cash.webp";
import BuyProductsonSafexMarket from "../images/1. Home/Shopping-on-Safex-Marketplace.webp";
import ExploreSafex from "../images/1. Home/Explore-Safex.webp";
import DiveIntoSafex from "../images/1. Home/Dive-Into-Safex.webp";
import MiningSafexCashCryptocurrency from "../images/1. Home/Mining-Safex-Cash-Cryptocurrency.webp";
import DevelopingonSafex from "../images/1. Home/Developing-on-Safex.webp";
import SellProductsonSafexMarket from "../images/1. Home/Become-a-Merchant.webp";
import Arrow from "../images/arrow.svg";
import ArrowDown from "../images/arrow-down.svg";
import ArrowUp from "../images/arrow-up-3.svg";

function top(e) {
  e.preventDefault();
  var chartPos = document.getElementById("chart-wrap-section");
  window.scroll({
    top: chartPos.offsetTop,
    behavior: 'smooth'
  });
}


function Home() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home SFX SFT | safex.org</title>
        <meta name="description" content="Safex is a community-driven technology platform that powers Safex Cash cryptocurrency mining, Safex Token and a decentralized eCommerce catalog" />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/" />
      </Helmet>
      <section>
        <div className="container">
          <div className="row">
            <div className="hero-image-fluid">
              <img src={WelcometoSafex} className="img-fluid" alt=""/>
            </div>
          </div>
          <div className="row">
            <div className="hero-text-fluid">
              <h1>Welcome to Safex</h1>
              <div className="text">
                <p>Safex is a community driven technology platform that powers cryptocurrency mining of Safex Cash (SFX), the crypto community token Safex Token (SFT) and the decentralized marketplace application, a global catalog of user driven online stores.</p>
                <p>If the internet was a country, Safex powers its <strong>real</strong> economy.</p>
              </div>
              <a href="#first-step" className="btn-arrow">
                Get Started
               <img src={Arrow} alt=""/>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="first-step-section" id="first-step">
        <div className="container">
          <div className="row">
            <div className="desktop-text-center">
              <h1>First Steps</h1>
              <p className="mb-0">Safex Market is the first application on Safex. It’s like Amazon or Ebay connected to the Blockchain.</p>
              <p>Download Safex TWM Wallet to store and use Safex Coins, from the exchanges or from mining. You can buy and sell products too.</p>
            </div>
          </div>
          <div className="row">
            <div className="card-with-image-wrap">
              <Link to="/wallet" className="card-with-image">
                <div className="card-top InstallSafexCryptoWallet">
                  <img src={InstallSafexCryptoWallet} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>1. INSTALL THE <span>WALLET<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex Wallet is a multifunctional crypto wallet for storing, sending coins, buying, selling goods, and staking tokens. You mine Safex Cash directly to this wallet.</p>
                </div>
              </Link>
              <Link to="/buy-safex-cash" className="card-with-image">
                <div className="card-top GetSafexCash">
                  <img src={GetSafexCash} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>2. GET SAFEX <span>CASH<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Interacting with the eCommerce platform on the Safex Blockchain requires Safex Cash (SFX). You can buy SFX on leading cryptocurrency exchanges to get started.</p>
                </div>
              </Link>
              <Link to="/shopping-on-safex-ecommerce-market" className="card-with-image">
                <div className="card-top BuyProductsonSafexMarket">
                  <img src={BuyProductsonSafexMarket} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>3. SHOP ON  <span>SAFEX<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex establishes a unified catalog of items from all over the world. This means you can shop deals and interact with sellers using cryptocurrency.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card-fluid green-linear ExploreSafex">
                <div className="card-fluid-body">
                  <h2>What is Safex?</h2>
                  <p className="card-fluid-subtitle">A BLOCKCHAIN AND A NETWORK</p>
                  <p className="card-fluid-main-text">Safex is a decentralized open-source protocol, it is a blockchain that establishes a network that enables eCommerce to take place using privacy enhanced cryptocurrencies. This network allows new ways to earn cryptocurrency in exchange for real world goods globally.</p>
                  <Link to="/what-is-safex" className="btn-arrow">
                    Explore Safex
                    <img src={Arrow} alt=""/>
                  </Link>
                </div>
                <div className="card-fluid-image">
                  <img src={ExploreSafex} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid blue-linear DiveIntoSafex">
                <div className="card-fluid-body">
                  <h2>Ready? Dive into Safex.</h2>
                  <p className="card-fluid-subtitle">A REAL WAY TO EXPERIENCE CRYPTO</p>
                  <p className="card-fluid-main-text">A practical way to engage in the digital economy and cryptocurrencies. You download the Wallet and can begin engaging in a global network of cryptocurrency users. Sell real world goods and earn crypto in the process.</p>
                  <a href="https://safex.market/" target="_blank" rel="noreferrer" className="btn-arrow">
                    <span className="d-block d-md-inline">Get informed.</span> Find new opportunities
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
                <div className="card-fluid-image">
                  <img src={DiveIntoSafex} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card-fluid gray-linear MiningSafexCashCryptocurrency">
                <div className="card-fluid-body">
                  <h2>Mining Safex Cash</h2>
                  <p className="card-fluid-subtitle">EARN CRYPTO FAIRLY BY MINING</p>
                  <p className="card-fluid-main-text">Join in securing the Safex blockchain network, and earning a reward for those efforts by mining Safex Cash. Safex miners guarantee the integrity of the history of the Safex blockchain. Unlike Bitcoin, Safex offers a new way to participate that is fair to miners of all sizes.</p>
                  <Link to="/mining-safex-cash" className="btn-arrow">
                    Learn more
                    <img src={Arrow} alt=""/>
                  </Link>
                </div>
                <div className="card-fluid-image">
                  <img src={MiningSafexCashCryptocurrency} className="img-fluid" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray home-gray-section">
        <div className="container">
          <div className="row">
            <div className="bg-gray-text">
              <h2 className="fw-normal mb-0">Find all the details about what Safex is and how it works.</h2>
              <p className="font-20">We gather merchants, shoppers, crypto enthusiasts, miners, traders from all over the world.</p>
              <Link to="/safex-ecosystem" className="btn-arrow m-auto fw-normal">
                Find your place in our ecosystem
                <img src={Arrow} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="card-with-image-section">
        <div className="container">
          <div className="row">
            <div className="card-with-image-wrap space-around">
              <Link to="/coding-on-safex" className="card-with-image">
                <div className="card-top DevelopingonSafex">
                  <img src={DevelopingonSafex} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>DEVELOPING ON <span>SAFEX<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Safex Market is the first app on Safex. Explore the possibilities of developing further on Safex.</p>
                </div>
              </Link>
              <a href="https://safex.market/" target="_blank" rel="noreferrer" className="card-with-image SellProductsonSafexMarket">
                <div className="card-top">
                  <img src={SellProductsonSafexMarket} className="img-fluid" alt=""/>
                </div>
                <div className="card-body">
                  <div className="btn-arrow">
                    <p>SELLING ON <span>SAFEX<img src={Arrow} alt=""/></span></p>
                  </div>
                  <p className="desc">Learn about earning cryptocurrency, Safex Cash by selling products on Safex Market.</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray m-0" id="chart-wrap-section">
        <div className="container-fluid">
          <div className="row">
            <div className="collapse-btn collapsed chart-collapse-btn" type="button" data-bs-toggle="collapse"
                 data-bs-target="#chartCollapse" aria-expanded="false" aria-controls="chartCollapse">
              <h3>A Live feed from the Safex Blockchain</h3>
              <img src={ArrowDown} alt=""/>
            </div>
            <div className="collapse" id="chartCollapse">
              <div className="chart-wrap">
                <div className="chart">
                  <Chart1/>
                </div>
                <div className="chart">
                  <Chart2/>
                </div>
                <div className="chart">
                  <Chart3/>
                </div>
                <div className="chart">
                  <Chart4/>
                </div>
              </div>
              <div onClick={top} className="cart-collapse-bottom" type="button" data-bs-toggle="collapse"
                   data-bs-target="#chartCollapse" aria-expanded="false" aria-controls="chartCollapse">
                <img src={ArrowUp} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
