import '../App.css';
import { Helmet } from "react-helmet";

import ogImg from "../images/thumbnails/Safex-Assets.png";
import SafexAssetsImage from "../images/23. Safex Assets/Safex-Assets.webp";
import DownloadSafexCashLogoVector from "../images/23. Safex Assets/Download-Safex-Cash-Logo-Vector.webp";
import DownloadSafexTokenLogoVector from "../images/23. Safex Assets/Download-Safex-Token-Logo-Vector.webp";
import DownloadWrappedSafexCashLogoVector from "../images/23. Safex Assets/Download-Wrapped-Safex-Cash-Logo-Vector.webp";

import DownloadSafexCashLogoPNG from "../images/download assets/Download-Safex-Cash-Logo.png";
import DownloadSafexCashLogoVectorSVG from "../images/download assets/Download Safex Cash Logo Vector.svg";

import DownloadSafexTokenLogoPNG from "../images/download assets/Download-Safex-Token-Logo.png";
import DownloadSafexTokenLogoVectorSVG from "../images/download assets/Download Safex Token Logo Vector.svg";


import DownloadWrappedSafexCashLogoPNG from "../images/download assets/Download-Wrapped-Safex-Cash-Logo.png";
import DownloadWrappedSafexCashLogoVectorSVG from "../images/download assets/Download Wrapped Safex Cash Logo Vector.svg";

import Arrow from "../images/arrow.svg";


function SafexAssets() {

  window.scrollTo(0, 0);

  return (
    <div className="page-content">
      <Helmet>
        <title>Safex Assets - Download official versions | safex.org</title>
        <meta name="description" content="Find the official versions of the Safex coin assets and download .png or .svg files." />
        <meta property="og:image" content={ogImg} />
        <meta charSet="utf-8"/>
        <link rel="canonical" href="http://safex.org/safex-assets"/>
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text">
                <h1>Safex.org Assets <span>Download Official Versions</span></h1>
                <p>Find the official versions of the Safex coin assets.</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={SafexAssetsImage} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0">
        <div className="container">
          <div className="row">
            <h3 className="mb-4">Safex Coin Logo</h3>
            <div className="safex-coin-box-wrap">
              <div className="safex-coin-box">
                <h4 className="mt-2">Safex Cash (SFX)</h4>
                <div className="coin-logo-wrap">
                  <img src={DownloadSafexCashLogoVector} className="img-fluid mt-4 mb-5" alt=""/>
                  <a href={DownloadSafexCashLogoPNG} download="Download-Safex-Cash-Logo.png" className="btn-arrow fw-normal font-18 mb-2">
                    Download .png
                    <img src={Arrow} alt=""/>
                  </a>
                  <a href={DownloadSafexCashLogoVectorSVG} download="Download Safex Cash Logo Vector.svg" className="btn-arrow fw-normal font-18 mb-4">
                    Download .svg
                    <img src={Arrow} alt=""/>
                  </a>
                </div>
              </div>
              <div className="safex-coin-box">
                <h4 className="mt-2">Safex Token (SFT)</h4>
                <div className="coin-logo-wrap">
                  <img src={DownloadSafexTokenLogoVector} className="img-fluid mt-4 mb-5" alt=""/>
                  <div className="safex-coin-box-link-center">
                    <a href={DownloadSafexTokenLogoPNG} download="Download-Safex-Token-Logo.png" className="btn-arrow fw-normal font-18 mb-2">
                      Download .png
                      <img src={Arrow} alt=""/>
                    </a>
                    <a href={DownloadSafexTokenLogoVectorSVG} download="Download Safex Token Logo Vector.svg" className="btn-arrow fw-normal font-18 mb-4">
                      Download .svg
                      <img src={Arrow} alt=""/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray visual-content-section">
        <div className="container">
          <div className="row">
            <div className="text">
              <h2 className="mb-0 fw-normal">To get more visual content visit <a href="https://forum.safex.org/c/community-marketing/14" target="_blank" rel="noreferrer">Safex Forum</a>.</h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SafexAssets;