import '../App.css';
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from "react-helmet-async";

import SafexTWMWallet from "../images/3. Safex TWM Wallet/Safex-TWM-Wallet.webp";
import SendandReceiveSafexCoinstoTWMWallet
    from "../images/3. Safex TWM Wallet/Send-and-Receive-Safex-Coins-to-TWM-Wallet.webp";
import ExploretheSafexMarketWalletApp from "../images/3. Safex TWM Wallet/Explore-the-Safex-Market-Wallet-App.webp";
import StakeSafexTokensinTWMWallet from "../images/3. Safex TWM Wallet/Stake-Safex-Tokens-in-TWM-Wallet.webp";
import SellProductsandServicesonSafexeCommerceMarket from "../images/3. Safex TWM Wallet/Become-a-Merchant.webp";
import MiningSafexCashtoSafexTWMWallet from "../images/3. Safex TWM Wallet/Safex-Cash-Mining.webp";
import BuySafexTokensandSafexCash from "../images/3. Safex TWM Wallet/Buy-Safex-Tokens-and-Safex-Cash.webp";
import Arrow from "../images/arrow.svg";
import Linux from "../images/linux.svg";
import Mac from "../images/mac.svg";
import Windows from "../images/windows.svg";


function Wallet() {

    window.scrollTo(0, 0);

    return (
        <div className="page-content">
            <HelmetProvider>
                <Helmet>
                    <title>Safex Wallet SFT SFX | safex.org</title>
                    <meta name="description"
                          content="The Safex Wallet enables sending and receiving of Safex Cash (SFX) and Safex Tokens (SFT), buying and selling products on Safex Market, staking Tokens."/>
                    <meta charSet="utf-8"/>
                    <link rel="canonical" href="http://safex.org/wallet"/>
                </Helmet>
            </HelmetProvider>
            <section className="hero-2-cols">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="hero-text">
                                <h1>Safex TWM Wallet <span>Version 1.6.0</span></h1>
                                <p>The Safex TWM Wallet is everything you need to interact with the Safex ecosystem. It
                                    enables receiving, storing, and sending of Safex Cash (SFX) and Safex Tokens (SFT).
                                    With it you can Stake Tokens. You can buy and sell products over the blockchain, and
                                    even mine directly to your public address in this Wallet. Updated May 17, 2022</p>
                                <a href="#download-wallet" className="btn-arrow d-none d-md-block">
                                    Get Started, Download The Wallet
                                    <img src={Arrow} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="hero-image">
                                <img src={SafexTWMWallet} className="img-fluid" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray popover-section" id="download-wallet">
                <div className="container container-smaller">
                    <div className="row">
                        <div className="text-center">
                            <h1>Download Safex TWM 1.6.0 Wallet</h1>
                            <p>Download Safex TWM Wallet for your operating system:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="popover-btn-wrap">
                            <div className="popover-wrap">
                                <div className="popover-item">
                                    <p>sha256sum</p>
                                    <small>3f7905a363f25094d6b2fef978461762fa5419a6938d17c050e351b45ed9c7e4</small>
                                </div>
                                <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.ubuntu.1.6.0.deb"
                                   class="btn btn-primary">
                                    <img src={Linux} alt=""/>
                                    Linux
                                </a>
                            </div>
                            <div className="popover-wrap">
                                <div className="popover-item">
                                    <p>sha256sum</p>
                                    <small>73320251f823ba807c57773d5193498de897cb5cff92fe890c629479cfb3d5ac</small>
                                </div>
                                <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.macosx.1.6.0.dmg"
                                   class="btn btn-primary">
                                    <img src={Mac} alt=""/>
                                    Mac OSX
                                </a>
                            </div>
                            <div className="popover-wrap">
                                <div className="popover-item">
                                    <p>sha256sum</p>
                                    <small>d837a813338de27be0dc4a8b32d52ac4a9ed8eef03a7a05c81a20431b2448eff</small>
                                </div>
                                <a href="https://github.com/twminc/twm/releases/download/1.6.0-release/twmwallet.windows.1.6.0.exe"
                                   class="btn btn-primary">
                                    <img src={Windows} alt=""/>
                                    Windows
                                </a>
                            </div>
                            <a href="https://github.com/twminc/twm/releases/tag/1.6.0-release" target="_blank"
                               rel="noreferrer" class="btn btn-primary">
                                Source
                            </a>
                        </div>
                        <div className="d-md-none">
                            <p className="text-center mb-0 mt-3">*desktop version only</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="install-vallet-section">
                <div className="container">
                    <div className="row">
                        <h1 className="fw-normal text-center">Follow this video to help with installing the Wallet:</h1>
                        <iframe className="video" src="https://www.youtube.com/embed/GFLY7K84ii4"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>
            </section>
            <section className="bg-gray what-to-know-wallet">
                <div className="container container-small">
                    <div className="row text-section">
                        <div className="col-md-6">
                            <div className="text-mx-w">
                                <h4>WHAT YOU SHOULD KNOW ABOUT THE SAFEX TWM WALLET?</h4>
                                <p>Safex Wallet is straightforward to use, it’s made for beginners in crypto and
                                    eCommerce. <br/> It has all functions in the one software program: holding and
                                    sending Safex Coins, buying and selling products and services, staking and receiving
                                    revenue.</p>
                                <p>If you want to participate in Safex Market, this Wallet is the software you will
                                    need.</p>
                                <p>
                                    You can have countless number of Safex Wallets on one device. <br/>
                                    You can make private transactions, stay safe and protected. <br/>
                                    You can buy products any time, and get them delivered to your doorstep. <br/>
                                    You can passively earn by staking Safex Tokens. <br/>
                                    You can offer for sale your products and services.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-card">
                                <h4 className="fw-normal">Let’s step back for a moment...</h4>
                                <ul>
                                    <li>Safex TWM Wallet is software that helps you manage your funds.</li>
                                    <li>Each Wallet is password-protected.</li>
                                    <li>Every Wallet has an address, a crypto account where you can receive Safex
                                        Coins.
                                    </li>
                                    <li>Each address corresponds with a pair of private keys, which acts as a PIN code
                                        and allows you to restore access, when required.
                                    </li>
                                    <li>Products on Safex Market can only be purchased with the Safex Wallet, using
                                        Safex Cash.
                                    </li>
                                </ul>
                                <a href="https://safex.org/blog/safex-wallet/" target="_blank" rel="noreferrer"
                                   className="btn-arrow fw-normal">
                                    Find detailed guides about <div className="d-none d-md-inline">Safex TWM
                                    Wallet</div>
                                    <img src={Arrow} alt=""/><br/>
                                    <div className="d-md-none">Safex TWM Wallet</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet-card-fluid">
                <div className="container">
                    <div className="row">
                        <div className="d-md-none">
                            <h3 className="text-center">Safex TWM Wallet Features</h3>
                        </div>
                        <div className="col-md-12">
                            <div className="card-fluid gray-linear">
                                <div className="card-fluid-body">
                                    <h2>Send or Receive Safex Coins</h2>
                                    <p className="card-fluid-subtitle">SAFEX CASH AND TOKEN TRANSACTIONS</p>
                                    <p className="card-fluid-main-text">In your Safex TWM Wallet, go to the Home page if
                                        you want to send Cash, or to the Token page to send Tokens. In the suitable
                                        field, paste the address where you want to send Safex Coins. Type how much you
                                        want to send; you can adjust the mixin (level of privacy) and click on the Send
                                        button. Follow the subsequent notifications about the amount, recipient, fee,
                                        and confirm if you agree. <br/> Note: Inside the Safex Wallet, Tokens and Cash
                                        can be received at the same address; however, on exchanges, they have different
                                        addresses, so always check to be sure when using exchanges.</p>
                                    <a href="https://safex.org/blog/how-to-send-safex-coins-to-the-exchange/"
                                       target="_blank" rel="noreferrer" className="btn-arrow">
                                        Use a detailed guide if you <div className="d-none d-md-inline">need more
                                        help</div>
                                        <img src={Arrow} alt=""/><br/>
                                        <div className="d-md-none">need more help</div>
                                    </a>
                                </div>
                                <div className="card-fluid-image">
                                    <img src={SendandReceiveSafexCoinstoTWMWallet} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card-fluid green-linear ExploretheSafexMarketWalletApp">
                                <div className="card-fluid-body">
                                    <h2>Explore the Safex Market</h2>
                                    <p className="card-fluid-subtitle">SHOPPING ON SAFEX</p>
                                    <p className="card-fluid-main-text">Look at all the offers on the Safex Market by
                                        clicking on the Show Products button of the Market tab in the Safex Wallet. To
                                        see detailed information about the product, click on the View Listing button. If
                                        you want to look at the web version of the product list, visit the <a
                                            href="https://safex.market/" target="_blank" rel="noreferrer">Safex Market
                                            Website.</a></p>                  <a href="https://safex.ninja/"
                                                                                 target="_blank" rel="noreferrer"
                                                                                 className="btn-arrow">
                                    Explore Safex Market the <div className="d-none d-md-inline">world’s catalog</div>
                                    <img src={Arrow} alt=""/><br/>
                                    <div className="d-md-none">world’s catalog</div>
                                </a>
                                </div>
                                <div className="card-fluid-image">
                                    <img src={ExploretheSafexMarketWalletApp} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card-fluid blue-linear">
                                <div className="card-fluid-body">
                                    <h2>Stake Safex Tokens</h2>
                                    <p className="card-fluid-subtitle">COLLECT YOUR PASSIVE INCOME</p>
                                    <p className="card-fluid-main-text">With Safex TWM Wallet, you can stake Safex
                                        Tokens. By doing this, you will get revenue and passively earn from every sale
                                        on Safex Market. You will receive a Safex Cash reward in proportion to your
                                        staked Safex Tokens. To stake your Tokens, go on the Tokens page. Type in how
                                        much you want to stake (25k minimum) and click on the Stake button. Overtime as
                                        sales happen you can redeem your share of the Safex Cash reward.</p>
                                    <Link to="/staking-safex-tokens" className="btn-arrow">
                                        Find more details about <div className="d-none d-md-inline">Staking Safex
                                        Tokens</div>
                                        <img src={Arrow} alt=""/><br/>
                                        <div className="d-md-none">Staking Safex Tokens</div>
                                    </Link>
                                </div>
                                <div className="card-fluid-image">
                                    <img src={StakeSafexTokensinTWMWallet} className="img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet-card-with-image-wrap">
                <div className="container">
                    <div className="row">
                        <div className="card-with-image-wrap space-around">
                            <a href="https://safex.market/" target="_blank" rel="noreferrer"
                               className="card-with-image SellProductsonSafexMarket">
                                <div className="card-top">
                                    <img src={SellProductsandServicesonSafexeCommerceMarket} className="img-fluid"
                                         alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="btn-arrow">
                                        <p>SELLING ON SAFEX <span>MARKET<img src={Arrow} alt=""/></span></p>
                                    </div>
                                    <p>Learn how to become a Merchant on Safex Market. More is coming soon, read our
                                        updates and stay tuned.</p>
                                </div>
                            </a>
                            <Link to="/mining-safex-cash" className="card-with-image">
                                <div className="card-top MiningSafexCashtoSafexTWMWallet">
                                    <img src={MiningSafexCashtoSafexTWMWallet} className="img-fluid" alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="btn-arrow">
                                        <p>MINE SFX TO THE <span>WALLET<img src={Arrow} alt=""/></span></p>
                                    </div>
                                    <p>Support the Safex blockchain by mining and earn Safex Cash (SFX) passively from
                                        your home computer. </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-gray wallet-advice">
                <div className="container container-smaller">
                    <div className="row text-section">
                        <h2 className="fw-normal">Safex TWM Wallet ADVICE:</h2>
                    </div>
                    <div className="row text-section">
                        <div className="col-md-6">
                            <div className="text-wrap">
                                <h3 className="fw-normal">Keep your Safex Coins safe</h3>
                                <p className="subtitle">Responsibility is the price of freedom </p>
                                <ul>
                                    <li>Save your private keys and seed phrases</li>
                                    <li>Use a reliable, solid and unique password</li>
                                    <li>Never share private keys or seed phrases</li>
                                    <li>Backup</li>
                                    <li>Backup the backup</li>
                                    <li>Always download the Wallet from the official website</li>
                                    <li>Transfer your Safex coins from the exchange to the Wallet</li>
                                </ul>
                                <a href="#download-wallet" className="btn-arrow">
                                    Get Started, Download The Wallet
                                    <img src={Arrow} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-wrap">
                                <h3 className="fw-normal">Recover a Wallet</h3>
                                <p className="subtitle">PLAN B</p>
                                <p>Saving private keys and seed phrases for your Wallet address is of paramount
                                    importance. Saving means writing down the sensitive mnemonic phrase, and the private
                                    keys and securing them the way you protect a social security number, or your birth
                                    certificate. With this backup you can always recreate your wallet and access your
                                    funds anytime. Open the Wallet and choose the option to recover, either via keys or
                                    seed, and enter the information as requested. </p>
                                <Link to="/crypto-safety-tips" className="btn-arrow mb-no-m">
                                    Learn more about Safex Wallet safety
                                    <img src={Arrow} alt=""/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wallet-card-fluid2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to="/buy-safex-coins" className="card-fluid2">
                                <div className="card-fluid2-body">
                                    <div className="btn-arrow">
                                        BUY SAFEX COINS
                                        <img src={Arrow} alt=""/>
                                    </div>
                                    <p className="card-fluid2-subtitle">WHERE AND HOW TO BUY SAFEX COINS?</p>
                                    <p className="card-fluid2-main-text">Get Safex Cash (SFX) on Xcalibra.com or
                                        Bitrue.com exchange. <br/> Get Safex Token (SFT) on Xcalibra.com
                                        exchange. </p>
                                </div>
                                <div className="card-fluid2-image">
                                    <img src={BuySafexTokensandSafexCash} className="img-fluid" alt=""/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Wallet;
