import '../App.css';
import React, {useState} from 'react';

//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';

import ogImg from "../images/thumbnails/Contact-Safex-Org.png";
//import Arrow from "../images/arrow.svg";
import ContactSafexorg from "../images//16. Contact Us/Contact-Safex-org-.webp";
import Twitter from "../images/twitter-white.svg";
import Forum from "../images/forum-white.svg";
import Youtube from "../images/youtube-white.svg";
import Reddit from "../images/reddit-white.svg";
import Github from "../images/github-white.svg";
import Discord from "../images/discord-white.svg";


function ContactUs() {
  window.scrollTo(0, 0);
  const [msg, setMsg] = useState('');

  const send_mail = async (name, subject, from, message) => {
    return axios({
      method: 'post',
      url: 'https://iapi.safex.org/contact/send',
      data: {
        name: name,
        subject: subject,
        from: from,
        message: message
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      return res.data;
    }).catch((err) => {
      return err.response.data;
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let the_msg = await send_mail(e.target.name.value, e.target.subject.value, e.target.email.value, e.target.message.value);
    console.log(the_msg);

    if (the_msg.message) {
      setMsg(the_msg.message);
    } else if (the_msg.error) {
      setMsg(the_msg.error);
    }
  }

  return (
    <div className="page-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact us | safex.org</title>
        <meta name="description" content="Do you have a proposal, idea, or thought you would like to share with us? Send us a message. Let’s keep in touch!" />
        <meta property="og:image" content={ogImg} />
        <link rel="canonical" href="http://safex.org/contact-us" />
      </Helmet>
      <section className="hero-2-cols">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <div className="hero-text full-hero-text">
                <h1>Contact us <span>We Want to Hear from You</span></h1>
                <p>You have a proposal, idea, or thought you would like to share with us? <br/> Send us a message!</p>
                <p>Keep in mind: you can also contact us via the <a href="https://forum.safex.org/" target="_blank" rel="noreferrer">Forum</a> or on <a href="https://twitter.com/safex" target="_blank" rel="noreferrer">Twitter</a>.</p>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="hero-image">
                <img src={ContactSafexorg} className="img-fluid" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="form-wrap">
              <form onSubmit={handleSubmit}>
                <h3 className="text-center"><span className="color-blue">We Want To Hear</span> From You</h3>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="name"
                    className="form-control"
                    id="name"
                    aria-describedby="name"
                    placeholder=""
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    aria-describedby="email"
                    placeholder="example@yourdomain.com"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Subject</label>
                  <input
                      type="subject"
                      className="form-control"
                      id="subject"
                      aria-describedby="subject"
                      placeholder="subject"
                      required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Your message</label>
                  <textarea maxlength="3000" class="form-control" id="message" rows="5" placeholder="Hi there..." required></textarea>
                </div>
                <button type="submit" className="btn btn-primary m-auto mt-3">
                  Send
                </button>
                {msg}
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-text bg-gray d-none d-md-block">
        <div className="container container-smaller">
          <div className="row">
            <div>
              <h1 className="fw-normal text-center"><span className="color-blue">Keep In Touch on</span> Official Safex Channels</h1>
              <div className="keep-in-touch">
                <a href="https://twitter.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Twitter} alt=""/>
                  </div>
                  <p>Twitter</p>
                </a>
                <a href="https://forum.safex.org/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Forum} alt=""/>
                  </div>
                  <p>Safex Forum</p>
                </a>
                <a href="https://www.youtube.com/channel/UCKnUPw_PI1lhMPfrztUqnoQ" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Youtube} alt=""/>
                  </div>
                  <p>YouTube</p>
                </a>
                <a href="https://github.com/safex" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Github} alt=""/>
                  </div>
                  <p>Github</p>
                </a>
                <a href="https://t.co/gbVqdl2hst" target="_blank" rel="noreferrer" className="social-media-icon">
                   <div>
                    <img src={Discord} alt=""/>
                  </div>
                  <p>Discord</p>
                </a>
                <a href="https://www.reddit.com/r/safex/" target="_blank" rel="noreferrer" className="social-media-icon">
                  <div>
                    <img src={Reddit} alt=""/>
                  </div>
                  <p>Reddit</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;